import { TitleCasePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DropdownSingleSelectModule } from '@backbase/ui-ang/dropdown-single-select';
import { TabModule } from '@backbase/ui-ang/tab';
import { DesignTokenService, DesignTokens } from '../../../services/design-token.service';
import { DesignTokenListTableComponent } from '../design-token-list-table/design-token-list-table.component';

@Component({
  selector: 'bb-design-token-list',
  templateUrl: './design-token-list.component.html',
  imports: [DesignTokenListTableComponent, DropdownSingleSelectModule, ReactiveFormsModule, TabModule, TitleCasePipe],
  providers: [DesignTokenService],
})
export class DesignTokenListComponent implements OnInit, AfterViewInit {
  @Input() description = '';

  platforms = ['Web'];

  scrollTableEnd = false;

  designTokens: DesignTokens[] = this.designTokenService.getTokens().map((group) => {
    if (group.type === 'color') {
      group.tokens.sort(this.sortColorGroup);
    }

    return group;
  });

  designTokensFilter = this.formBuilder.group({
    platforms: [this.platforms[0]],
  });

  currentTab = 0;

  constructor(
    private designTokenService: DesignTokenService,
    private el: ElementRef,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    // use the hash in the url to navigate to the correct tab
    const { fragment } = this.route.snapshot;
    const index = this.designTokens.findIndex((token) => token.type === fragment);

    if (index !== -1) {
      this.currentTab = index;
    }
  }

  ngAfterViewInit(): void {
    // scroll to the selected tab if the route selects an off-screen tab
    this.el.nativeElement
      .querySelector('[role="tab"][aria-selected="true"]')
      .scrollIntoView({ block: 'end', inline: 'center' });
  }

  onTabSelect(index: number) {
    this.currentTab = index;
  }

  humaniseString(value: string) {
    return value.replace('-', ' ');
  }

  onScrollTableList(event: Event) {
    const target = event.target as HTMLDivElement;

    if (target.scrollLeft >= target.scrollWidth - target.offsetWidth) {
      this.scrollTableEnd = true;
    } else {
      this.scrollTableEnd = false;
    }
  }

  private sortColorGroup(tokenA: any, tokenB: any) {
    const isChartColor = (token: any) => token.token.includes('chart');
    if (isChartColor(tokenA) && !isChartColor(tokenB)) {
      return 1;
    }

    if (!isChartColor(tokenA) && isChartColor(tokenB)) {
      return -1;
    }

    return tokenA.token === tokenB.token ? 0 : tokenA.token > tokenB.token ? 1 : -1;
  }
}
