import { Component } from '@angular/core';

@Component({
  selector: 'bb-progressbar-example-ui',
  templateUrl: './progressbar.example.component.html',
  standalone: false,
})
export class ProgessbarExampleComponent {
  value = 0.42;
}
