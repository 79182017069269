export const SELECTORS = [
  'account-number', //v
  'account-number-pipe', //v
  'account-selector', //v v
  'action-status', //v
  'alert', //v v
  'amount', //v v
  'amount-input', //v v
  'avatar', //v v
  'badge', //v v
  'badge-counter', //v
  'button', //v v
  'card-vendor', //v
  'char-counter', //v v
  'checkbox-group', //v v
  'collapsible', //v
  'collapsible-accordion', //v
  'collapsible-card', //v
  'common-error-state', //v v
  'currency-input', //v v
  'date-pipe', //v
  'dropdown-menu', //v v
  'dropdown-multi-select', //v v
  'dropdown-panel', // v v? small popup window
  'dropdown-single-select', //v v
  'ellipsis', //v
  'empty-state', //v v
  'fieldset', //v v
  'file-attachment', //v v
  'control-error-handler', //v v
  'highlight', //v
  'iban-pipe', //v
  'icon', //v
  'illustration',
  'infinite-scroll', //v
  'input-checkbox', //v v
  'input-datepicker', //v v
  'input-email', //v v
  'input-file', //v v
  'input-inline-edit', //v v
  'input-number', //v v
  'input-password', //v v
  'input-phone', //v v
  'input-radio-group', //v v
  'input-range', //v v
  'input-text', //v v
  'input-timepicker', //v v
  'input-validation-message', //v v
  'item-log', //v v
  'keyboard-click', //v v
  'list-multiple-select', //v v
  'load-button', //v v
  'loading-indicator', //v v
  'loading-state', //v
  'locale-selector',
  'logo', //v v
  'modal', //v
  'mode-header',
  'notification', //v v
  'page-header', //v v
  'pagination', //v v
  'payment-card', //v v
  'payment-card-number-pipe', //v v
  'period-selector', //v
  'phone-number-pipe', //v
  'product-item-basic-account', //v v
  'product-item-credit-card', //v v
  'product-item-current-account', //v v
  'product-item-debit-card', //v v
  'product-item-investment-account', //v v
  'product-item-loan', //v v
  'product-item-savings-account', //v v
  'product-item-term-deposit', //v v
  'product-selector', //v v
  'progressbar', //v
  'progress-tracker', //v v
  'rich-text-editor', //v v
  'search-box', //v v
  'select-list', //v v
  'stepper', // v v
  'switch', //v v
  'tab', //v
  'table', //v
  'textarea', //v v
  'value-diff', //v v
  'tooltip', //v v
];
