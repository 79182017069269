import { Component } from '@angular/core';
import { NotificationService } from '@backbase/ui-ang/notification';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-alert-ui-showcase',
  templateUrl: 'alert-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class AlertShowcaseComponent {
  colorVariants = [
    {
      title: 'Information! Use this alert to introduce information only',
      modifier: 'info',
      primaryActionButtonText: 'Primary action',
      primaryActionButtonClick: () => {
        this.onSupportiveLinkClick();
      },
      supportiveLinkText: 'Secondary link',
      supportiveLinkClick: () => {
        this.onSupportiveLinkClick();
      },
    },
    {
      title: 'Warning! Use this alert to show warn only',
      modifier: 'warning',
      primaryActionButtonText: 'Primary action',
      primaryActionButtonClick: () => {
        this.onSupportiveLinkClick();
      },
    },
    {
      title: 'Success! Use this alert to show success only',
      modifier: 'success',
      supportiveLinkText: 'Secondary link',
      supportiveLinkClick: () => {
        this.onSupportiveLinkClick();
      },
    },
    {
      title: 'Error! Use this alert to show error only',
      modifier: 'error',
      supportiveLinkText: 'Secondary link',
      supportiveLinkClick: () => {
        this.onSupportiveLinkClick();
      },
    },
  ];

  dismissibleAlerts = [
    {
      dismissed: false,
    },
    {
      dismissed: false,
    },
  ];

  dynamicAlertDismissible = false;
  dynamicAlertDismissibleWithFocus = false;
  dynamicAlertNonDismissibleWithButton = false;

  constructor(private readonly notificationService: NotificationService) {}

  onSupportiveLinkClick() {
    this.notificationService.showNotification({
      header: 'Info notification',
      message: 'Supportive link was clicked.',
      dismissible: true,
      ttl: 5000,
    });
  }
}
