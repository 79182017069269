import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Optional } from '@angular/core';
import { NotificationService } from '@backbase/ui-ang/notification';

declare let Prism: any;

@Component({
  selector: 'bb-showcase-example-template',
  templateUrl: './showcase-example-template.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ShowcaseExampleTemplateComponent {
  _language = 'html';
  @Input()
  set language(val: string) {
    this._language = val;
    this.cd.detectChanges();
  }
  _exampleTemplate = '';
  showMore = true;
  exampleTemplateRaw = '';
  @Input()
  set exampleTemplate(val: string) {
    this.exampleTemplateRaw = val;
    val = Prism.highlight(val, Prism.languages.html);
    this._exampleTemplate = val;
  }

  constructor(
    @Optional() private notificationService: NotificationService,
    private cd: ChangeDetectorRef,
  ) {}

  copyToClipboard(input: HTMLInputElement) {
    this.showNotification();
    input.value = this.exampleTemplateRaw;
    input.select();
    navigator.clipboard.writeText(input.value);
  }

  showNotification() {
    if (this.notificationService) {
      this.notificationService.showNotification({
        header: 'Successfully copied code example to the clipboard',
        message: '',
        dismissible: true,
        ttl: 5000,
        modifier: 'success',
      });
    } else {
      console.log('Need a notification service, there`s one in ui-ang!');
    }
  }
}
