import { Component } from '@angular/core';
// Please replace relative path imports e.g. '../*' with '@backbase/ui-ang'.
import { PaymentCardNumberFormat } from '@backbase/ui-ang/payment-card-number-pipe';
import { ProductItem } from '@backbase/ui-ang/product-selector';
import products from './products';

@Component({
  selector: 'bb-product-selector-example-ui',
  templateUrl: './product-selector.example.component.html',
  standalone: false,
})
export class ProductSelectorExampleComponent {
  products: ProductItem[] = products;
  selected: ProductItem | undefined;
  productNumberFormat: PaymentCardNumberFormat = {
    length: 18,
    maskRange: [0, 12],
    segments: 4,
  };

  selectProduct(product: ProductItem) {
    this.selected = product;
  }
}
