import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';
import { TypeaheadOptions } from '@backbase/ui-ang/search-box';

const startsWithCaseInsensitive = (haystack: string, needle: string): boolean =>
  haystack.toLowerCase().startsWith(needle.toLowerCase());

interface Person {
  fullName: string;
  legalEntityName: string;
}

@Component({
  selector: 'bb-search-box-ui-showcase',
  templateUrl: './search-box-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class SearchBoxShowcaseComponent {
  @ViewChild('rt', { static: true }) resultTemplate: TemplateRef<any> | undefined;

  form = new UntypedFormGroup({
    name: new UntypedFormControl('John Doe'),
  });

  search(text: Observable<string>): Observable<string[]> {
    const searchItems = ['John', 'Jane', 'James', 'Joey'];

    return text.pipe(map((term) => searchItems.filter((item) => startsWithCaseInsensitive(item, term))));
  }

  readonly typeaheadOptions = {
    ngbTypeahead: this.search,
  };

  customSearch(text: Observable<string>): Observable<Person[]> {
    const searchItems: Person[] = [
      {
        fullName: 'John',
        legalEntityName: 'Legal Entity 1',
      },
      {
        fullName: 'Jane',
        legalEntityName: 'Legal Entity 2',
      },
      {
        fullName: 'James',
        legalEntityName: 'Legal Entity 3',
      },
      {
        fullName: 'Joey',
        legalEntityName: 'Legal Entity 4',
      },
    ];

    return text.pipe(map((term) => searchItems.filter((item) => startsWithCaseInsensitive(item.fullName, term))));
  }

  customInputFormatter(person: Person): string {
    return person.fullName;
  }

  get typeaheadOptionsWithTemplate(): TypeaheadOptions<Person> {
    return {
      ngbTypeahead: this.customSearch,
      resultTemplate: this.resultTemplate,
      inputFormatter: this.customInputFormatter,
    };
  }
}
