import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'bb-switch-example-ui',
  templateUrl: './switch.example.component.html',
  standalone: false,
})
export class SwitchExampleComponent {
  vForm: UntypedFormGroup = this.formBuilder.group({
    notifications: true,
  });

  constructor(private formBuilder: UntypedFormBuilder) {}
}
