import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Host,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  Self,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { EMPTY, merge, Observable, Subject } from 'rxjs';
import { debounceTime, first, takeUntil } from 'rxjs/operators';
import {
  BB_DYNAMIC_VALIDATION_ERROR_TMPL,
  BB_VALIDATION_ERRORS,
  ValidationErrorComponentModel,
  PlainObject,
} from '../control-error-handler.const';
import { FormSubmitDirective } from './form-submit.directive';
import { idListAttr } from '@backbase/ui-ang/util';
import { ControlErrorContainerDirective } from './control-error-container.directive';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[bbFormControl]',
  standalone: false,
})
export class ValidationErrorsDirective implements OnInit, OnDestroy {
  private readonly onBlur = new Subject<void>();
  private readonly destroy = new Subject<void>();
  private readonly submit: Observable<Event>;
  private readonly ariaAttributeName = 'aria-describedby';

  private ref: ComponentRef<any> | undefined;

  /**
   * Custom error labels object.
   *
   * Use only if you need to set custom error labels for specific control.
   * To specify custom error labels for entire form use BB_VALIDATION_ERRORS InjectionToken.
   *
   * @default `BB_VALIDATION_ERRORS`.
   */
  @Input() errorLabels: PlainObject<string> = {};

  /**
   * Custom function to specify when errors should be shown.
   * By default will be shown when control is invalid.
   */
  @Input() showError = this.showErrorDefault;

  /**
   * Selector to indicate the control in which `aria-describedby` should be set.
   */
  @Input() inputSelector = '.form-control';

  /**
   * Input label
   */
  @Input() label: string | null = null;

  /**
   * Custom component for error message.
   *
   * Use only if you need to set custom component for specific control.
   * To specify custom component for all form errors use BB_DYNAMIC_ERROR_TMPL InjectionToken.
   *
   * @default `BB_DYNAMIC_ERROR_TMPL`.
   */
  @Input() errorComponent = this.errorTmpl;

  @HostListener('blur') onElBlur() {
    this.onBlur.next();
  }

  constructor(
    @Optional() @Host() private readonly form: FormSubmitDirective,
    @Optional() private readonly controlErrorContainer: ControlErrorContainerDirective,
    @Self() private readonly control: NgControl,
    @Inject(BB_VALIDATION_ERRORS) private readonly errors: PlainObject<string>,
    @Inject(BB_DYNAMIC_VALIDATION_ERROR_TMPL) private readonly errorTmpl: Type<ValidationErrorComponentModel>,
    private readonly resolver: ComponentFactoryResolver,
    private readonly vcr: ViewContainerRef,
    private readonly hostElem: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
  ) {
    this.submit = this.form ? this.form.submit : EMPTY;

    if (!this.control) {
      throw Error('bbFormControl must contain a NgControl.');
    }
  }

  ngOnInit() {
    const controlChanges = this.control.valueChanges ? this.control.valueChanges : EMPTY;

    merge(
      controlChanges,
      this.submit,
      this.onBlur.pipe(first()), // to detect first invalid state if no control changes happened.
    )
      .pipe(debounceTime(100), takeUntil(this.destroy))
      .subscribe({ next: () => this.manageErrors() });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  private manageErrors() {
    const controlErrors = this.control.errors;

    if (controlErrors && this.showError()) {
      const errorList = { ...this.errors, ...this.errorLabels };
      const firstKey = Object.keys(controlErrors)[0];
      const getError = errorList[firstKey] || errorList.invalid;
      const text = getError(controlErrors[firstKey]);

      // TODO: add aria-invalid?
      if (this.ref?.instance.text !== text) {
        this.setError(text);
        this.setDescribedById(this.ref?.instance.errorId);
      }
    } else if (this.ref) {
      this.setError('');
      this.removeErrorId(this.ref?.instance.errorId);
    }
  }

  private setDescribedById(id: string | undefined) {
    const targetEl = this.hostElem.nativeElement.querySelector(this.inputSelector);

    if (targetEl) {
      const existingIds = targetEl.getAttribute(this.ariaAttributeName);
      const attributeVal = idListAttr(id, existingIds);

      if (attributeVal) {
        this.renderer.setAttribute(targetEl, this.ariaAttributeName, attributeVal);
      }
    }
  }

  private removeErrorId(id: string | undefined) {
    const targetEl = this.hostElem.nativeElement.querySelector(this.inputSelector);

    if (targetEl) {
      const existingIds = targetEl.getAttribute(this.ariaAttributeName) || '';
      const cleanIds = (id ? existingIds.replace(id, '') : existingIds).trim();

      if (cleanIds) {
        this.renderer.setAttribute(targetEl, this.ariaAttributeName, cleanIds);
      } else {
        this.renderer.removeAttribute(targetEl, this.ariaAttributeName);
      }
    }
  }

  private showErrorDefault(): boolean {
    return Boolean(this.control.invalid);
  }

  private setError(text: string) {
    if (!this.ref) {
      const container = this.controlErrorContainer?.vcr || this.vcr;
      const factory = this.resolver.resolveComponentFactory<ValidationErrorComponentModel>(this.errorComponent);

      this.ref = container.createComponent(factory);
    }

    this.ref.instance.text = text;

    // Setup this properties in case of custom validation error component to give possibility to customise behavior.
    this.ref.instance.control = this.control;
    this.ref.instance.errorList = { ...this.errors, ...this.errorLabels };
    this.ref.instance.label = this.label;

    this.ref.changeDetectorRef.detectChanges();
  }
}
