import { Directive, ElementRef, HostBinding, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { ButtonColor, ButtonType } from './types';

/**
 * @name ButtonDirective
 *
 * @description
 * Directive for styling button.
 *
 * ### Color background mode
 * In case the button is located inside a parent HTML element with a darker background, consider using an `On Color` palette.
 * For the reference see the `On Color` example above in the `Interactive demo` section.
 * To enable the `On Color` palette one has to add a `.btn-on-color` class to the parent HTML element like this:
 * ```
 * <div class="btn-on-color">
 *   <button
 *       bbButton
 *       [color]="button.color"
 *       buttonSize="md"
 *     >
 *     ...
 *     </button>
 * </div>
 * ```
 *
 * The class does not have to be applied to a direct parent of a button component: all buttons under this container,
 * no matter how deep, will be displayed in the `On Color` palette.
 * `On Color` is supported only for `primary`, `secondary` and `link` buttons.
 *
 * @a11y
 * The `bbButton` directive doesn't provide any specific properties for accessibility.
 * In case of necessity add `aria-*` attributes to the `button` element.
 */
@Directive({
  selector: 'button[bbButton]',
  standalone: false,
})
export class ButtonDirective implements OnInit, OnChanges {
  /**
   * Button's type atrribute with default value.
   */
  @HostBinding('type')
  @Input()
  type: ButtonType = 'button';

  /**
   * The color for the button. Defaults to 'primary'.
   * If a custom string used, then a css class is going to be added. The pattern is as follows:
   * `btn-customColor`
   */
  @Input() color: ButtonColor | string = 'primary';

  /**
   * Size of the button.
   */
  @Input() buttonSize: 'sm' | 'md' = 'md';

  /**
   * The flag to indicate whether the button should fill the container that it is in. Defaults to 'false'.
   */
  @HostBinding('class.btn-block')
  @Input()
  block = false;
  /**
   * The flag to indicate whether the button should be in a circular shape. Defaults to 'false'.
   * This will only work when there's only an icon inside the button without a text.
   */
  @HostBinding('class.btn-circle')
  @Input()
  circle = false;

  constructor(
    private readonly renderer: Renderer2,
    private readonly hostElement: ElementRef,
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.hostElement.nativeElement, 'btn');
    this.renderer.addClass(this.hostElement.nativeElement, `btn-${this.remapButtonColor(this.color)}`);
    this.renderer.addClass(this.hostElement.nativeElement, `btn-${this.buttonSize}`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.color) {
      if (changes.color.previousValue) {
        this.renderer.removeClass(
          this.hostElement.nativeElement,
          `btn-${this.remapButtonColor(changes.color.previousValue)}`,
        );
      }

      if (changes.color.currentValue) {
        this.renderer.addClass(
          this.hostElement.nativeElement,
          `btn-${this.remapButtonColor(changes.color.currentValue)}`,
        );
      }
    }

    if (changes.buttonSize) {
      if (changes.buttonSize.previousValue) {
        this.renderer.removeClass(this.hostElement.nativeElement, `btn-${changes.buttonSize.previousValue}`);
      }

      if (changes.buttonSize.currentValue) {
        this.renderer.addClass(this.hostElement.nativeElement, `btn-${changes.buttonSize.currentValue}`);
      }
    }
  }

  private remapButtonColor(color: string): string {
    if (color === 'link') {
      return 'tertiary';
    }

    if (color === 'link-dark') {
      return 'dark';
    }

    return color;
  }
}
