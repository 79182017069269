import { Component, computed, signal, WritableSignal } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-infinite-scroll-ui-showcase',
  templateUrl: './infinite-scroll-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class InfiniteScrollShowcaseComponent {
  page = 0;
  complete: WritableSignal<boolean> = signal(false);
  loading: WritableSignal<boolean> = signal(false);
  disableScrollEnd = computed(() => this.complete() || this.loading());
  sum = 10;
  fixedText: string =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.';
  items: string[] = Array(this.sum).fill(this.fixedText);

  loadMore() {
    if (!this.loading()) {
      this.loading.set(true);
      this.page++;

      window.setTimeout(() => {
        this.loading.set(false);
        this.addItems();
        this.complete.set(this.page >= 15);
      }, 1000);
    }
  }

  addItems() {
    for (let i = 0; i < this.sum; ++i) {
      this.items.push(this.fixedText);
    }
  }
}
