import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'bb-input-email-example-ui',
  templateUrl: './input-email.example.component.html',
  standalone: false,
})
export class InputEmailExampleComponent {
  vForm: UntypedFormGroup = this.formBuilder.group({
    email: ['info@example.com', Validators.required],
  });

  constructor(private formBuilder: UntypedFormBuilder) {}
}
