import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-icon-ui-showcase',
  templateUrl: './icon-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class IconShowcaseComponent {}
