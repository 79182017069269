<bb-tabs></bb-tabs>
<bb-heading-container></bb-heading-container>
<div class="example-nav">
  <ul class="nav nav-tabs title-padding">
    @if (tabsShown.includes('androidXML')) {
      <li class="nav-item">
        <button class="nav-link" routerLinkActive="active" routerLink="./androidXML">Android XML</button>
      </li>
    }
    @if (tabsShown.includes('androidCompose')) {
      <li class="nav-item">
        <button class="nav-link" routerLinkActive="active" routerLink="./androidCompose">Android Compose</button>
      </li>
    }
    @if (tabsShown.includes('iosUIKit')) {
      <li class="nav-item">
        <button class="nav-link" routerLinkActive="active" routerLink="./iosUIKit">iOS UIKit</button>
      </li>
    }
    @if (tabsShown.includes('iosSwiftUI')) {
      <li class="nav-item">
        <button class="nav-link" routerLinkActive="active" routerLink="./iosSwiftUI">iOS SwiftUI</button>
      </li>
    }
    @if (tabsShown.includes('design')) {
      <li class="nav-item">
        <button class="nav-link" routerLinkActive="active" routerLink="./design">Design Guidelines</button>
      </li>
    }
  </ul>
</div>
<router-outlet></router-outlet>
