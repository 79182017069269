import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';
import { ProductItemBaseComponent, AccountStatus } from '@backbase/ui-ang/base-classes';
import { MoneyProtectionStatusEnum } from '@backbase/ui-ang/money-protection';

@Directive({
  selector: 'ng-template[bbCustomProductItemBasicAccountType]',
  standalone: false,
})
export class CustomProductItemBasicAccountTypeDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: 'ng-template[bbCustomProductItemBasicAccountTitle]',
  standalone: false,
})
export class CustomProductItemBasicAccountTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: 'ng-template[bbCustomProductItemBasicAccountDetailsFormatter]',
  standalone: false,
})
export class CustomProductItemBasicAccountDetailsFormatterDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * @name ProductItemBasicAccountComponent
 *
 * @description
 * A component that renders a styled-container or card for basic account.
 */
@Component({
  selector: 'bb-product-item-basic-account-ui',
  templateUrl: './product-item-basic-account.component.html',
  standalone: false,
})
export class ProductItemBasicAccountComponent extends ProductItemBaseComponent {
  @ContentChild(CustomProductItemBasicAccountTypeDirective, { static: true })
  customProductItemBasicAccountType?: CustomProductItemBasicAccountTypeDirective;

  @ContentChild(CustomProductItemBasicAccountTitleDirective, { static: true })
  customProductItemBasicAccountTitle?: CustomProductItemBasicAccountTitleDirective;

  @ContentChild(CustomProductItemBasicAccountDetailsFormatterDirective, { static: true })
  customProductItemBasicAccountDetailsFormatter?: CustomProductItemBasicAccountDetailsFormatterDirective;

  readonly hostRef: ProductItemBasicAccountComponent = this;
  /**
   * The card currency.
   */
  @Input() currency?: string;
  /**
   * Whether the item should show the currency symbol. Defaults to true.
   */
  @Input() showCurrencySymbol = true;
  /**
   * The card amount value.
   */
  @Input() amount?: number;
  /**
   * The card secondary amount value.(e.g. Available amount is primary and booked balance is secondary).
   */
  @Input() secondaryAmount?: number;
  /**
   * Whether the card is selected.
   * This removes any check mark on the item when `active=true`.
   * Defaults to false.
   */
  @Input() selected = false;
  /**
   * The type of icon to be displayed when `selected=true`.
   */
  @Input() type?: string;
  /**
   * The current account status. Accepts `closed`, `inactive` or `active`.
   */
  @Input() status?: AccountStatus;
  /**
   * The text to be placed above the amount.
   */
  @Input() balanceLabel?: string;
  /**
   * The text to be placed above the amount.
   */
  @Input() balanceLabelForSecondaryAmount?: string;
  /**
   * Branch code of a bank.
   */
  @Input() bankBranchCode?: string;
  /**
   * MoneyProtectionStatus can be Yes, No or NA
   */
  @Input() moneyProtectionStatus?: MoneyProtectionStatusEnum;
  /**
   * Allows to add classes to status of money protection
   */
  @Input() moneyProtectionStatusClasses?: string;
}
