import { Component, ContentChild, TemplateRef, Directive } from '@angular/core';
import { DomAttributesService } from '@backbase/ui-ang/services';

@Directive({
  selector: 'ng-template[bbCustomModalBody]',
  standalone: false,
})
export class CustomModalBodyDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'bb-modal-body-ui',
  templateUrl: './modal-body.component.html',
  standalone: false,
})
export class ModalBodyComponent {
  @ContentChild(CustomModalBodyDirective, { static: true }) customModalBody: CustomModalBodyDirective | undefined;

  readonly hostRef: ModalBodyComponent = this;

  public readonly bodyId = this.domAttrService.generateId();

  private _scrollable: boolean | undefined;

  setScrollable(scrollable: boolean | undefined): void {
    this._scrollable = scrollable;
  }

  get scrollable(): boolean | undefined {
    return this._scrollable;
  }

  constructor(private readonly domAttrService: DomAttributesService) {}
}
