import { Component } from '@angular/core';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';

@Component({
  selector: 'bb-locale-selector-ui-showcase',
  templateUrl: './locale-selector.showcase.component.html',
  standalone: false,
})
export class LocaleSelectorShowcaseComponent {
  selectedLanguage!: string;
  dropdownPosition: PlacementArray = ['left-top', 'bottom-end', 'bottom-start', 'top-end', 'top-start', 'right-bottom'];

  onSelectLocale(localeCode: string) {
    // Do something with locale(event) code.
    this.selectedLanguage = localeCode;
  }
}
