import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'bb-showcase-ios',
  templateUrl: './showcase-ios.component.html',
  standalone: false,
})
export class ShowcaseIOSComponent {
  path$: Observable<string | undefined> = this.route.paramMap.pipe(
    map((params: ParamMap) => {
      const componentId = params.get('id');
      const platform = this.route.snapshot.url[0]?.path;

      if (platform === 'iosSwiftUI') {
        return `assets/docs/html/ios-swiftui-${componentId}-page.component.html`;
      } else {
        return `assets/docs/html/ios-${componentId}-page.component.html`;
      }
    }),
  );
  constructor(private route: ActivatedRoute) {}
}
