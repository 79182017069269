import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'bb-currency-input-example-ui',
  templateUrl: './currency-input.example.component.html',
  standalone: false,
})
export class CurrencyInputExampleComponent {
  public amount = new UntypedFormControl({
    amount: '123.32',
    currency: 'EUR',
  });

  public currencies: string[] = ['USD', 'EUR'];
}
