import { Component } from '@angular/core';
// Please replace relative path imports e.g. '../*' with '@backbase/ui-ang'.
import { ListMultipleSelectItems } from '@backbase/ui-ang/list-multiple-select';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'bb-list-multiple-select-example-ui',
  templateUrl: './list-multiple-select-example.component.html',
  standalone: false,
})
export class ListMultipleSelectExampleComponent {
  listMultipleSelectItemsConfig: ListMultipleSelectItems = {
    i2: {
      icon: 'warning',
      iconColor: 'warning',
    },
    i3: {
      icon: 'warning',
      iconColor: 'secondary',
      items: {
        q2: {
          icon: 'warning',
        },
      },
    },
  };

  vForm: UntypedFormGroup | undefined;

  constructor(private readonly formBuilder: UntypedFormBuilder) {
    this.vForm = this.formBuilder.group({
      listMultipleSelect: this.formBuilder.group({
        i1: true,
        i2: true,
        i3: this.formBuilder.group({
          q1: false,
          q2: true,
          q3: this.formBuilder.group({
            w1: { value: true, disabled: true },
            w2: { value: false, disabled: true },
          }),
        }),
      }),
    });
  }

  onListMultipleSelectClearAll($event: any) {
    $event.preventDefault();
    if (this.vForm) {
      this.vForm.controls['listMultipleSelect'].patchValue({
        i1: false,
        i2: false,
        i3: {
          q1: false,
          q2: false,
        },
      });
    }
  }
}
