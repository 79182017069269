import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { INPUT_VALIDATION_MESSAGE_CONFIG } from './constants';

/**
 * @name InputValidationMessageComponent
 *
 * @description
 * Component that displays an input validation message or helper.
 *
 * ### Global configuration token
 * `INPUT_VALIDATION_MESSAGE_CONFIG` enables you to globally set the same configuration for all instances of `InputValidationMessageComponent` in your project.
 *
 * *Note:* The token overwrites the default value only. If you have provided a value as a property on a specific component, the token is not be able to overwrite it.
 *
 * The following properties can be overwritten using the token:
 *  - `showIcon`
 *
 * #### Usage notes
 * The following is an example of how to use the token:
 *
 * ```typescript
 * import { INPUT_VALIDATION_MESSAGE_CONFIG } from '@backbase/ui-ang/input-validation-message';
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 * import { AppModule } from './app/app.module';
 *
 * const inputValidationMessageShowIcon = { showIcon: false };
 *
 * platformBrowserDynamic().bootstrapModule(AppModule, {
 *   providers: [{ provide: INPUT_VALIDATION_MESSAGE_CONFIG, useValue: inputValidationMessageShowIcon }]
 * });
 * ```
 *
 */
@Component({
  selector: 'bb-input-validation-message-ui',
  templateUrl: './input-validation-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InputValidationMessageComponent {
  private readonly config = inject(INPUT_VALIDATION_MESSAGE_CONFIG, { optional: true });
  private _showIcon = this.config?.showIcon;

  /**
   * Whether the errors template should be shown
   *
   * This property is used to show or hide the error template for the input element. When set to true, the error template
   * will be displayed.
   */
  @Input() showErrors = false;

  /**
   * Optional reference to bind parent input with this component
   *
   * This property is used to bind the parent input element with this component. It is optional and can be used to associate
   * a validation message with a specific input element using the input element's ID or name.
   */
  @Input() messageId?: string;

  /**
   * Whether the error template should display "info" icon before the error message. Default is true.
   */
  @Input() set showIcon(value: boolean) {
    this._showIcon = value ?? this.config?.showIcon;
  }

  get showIcon() {
    return this._showIcon ?? true;
  }

  /**
   * Sets the role attribute on the validation message.
   * If multiple validation messages may be present on the screen then select `'status'`.
   * Default is `'alert'`.
   */
  @Input() role: 'status' | 'alert' = 'alert';
}
