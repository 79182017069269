import { Component, ViewChild, TemplateRef, Input } from '@angular/core';

/**
 * TabComponent represents Tab item
 * that should be used inside tab group component
 */
@Component({
  selector: 'bb-tab-ui',
  templateUrl: './tab.component.html',
  standalone: false,
})
export class TabComponent {
  /**
   * Represents a container where View can be attached
   */
  @ViewChild('tabItemTemplate', { static: true }) defaultTemplate: TemplateRef<any> | undefined;
  /**
   * Flag to disable a tab. Defaults to 'false'.
   */
  @Input() disabled = false;

  /**
   * Getter for the template attached to this component
   */
  get template() {
    return this.defaultTemplate;
  }
}
