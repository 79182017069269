import { Component } from '@angular/core';

@Component({
  selector: 'bb-mode-header-example-ui',
  templateUrl: './mode-header.example.component.html',
  standalone: false,
})
export class ModeHeaderExampleComponent {
  onCloseModeHeaderClick() {
    console.log('Mode header closed');
  }

  onActionOneClick() {
    console.log('Action one clicked');
  }

  onActionTwoClick() {
    console.log('Action two clicked');
  }
}
