import { Component } from '@angular/core';

@Component({
  selector: 'bb-input-datepicker-example-ui',
  templateUrl: './input-datepicker.example.component.html',
  standalone: false,
})
export class InputDatepickerExampleComponent {
  transferDate = '2024-12-25T00:00:00.000Z';
}
