import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  Renderer2,
  ViewChild,
  Inject,
  Optional,
} from '@angular/core';
import { defaultSize, InputBaseComponent } from '@backbase/ui-ang/base-classes';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { DomAttributesService } from '@backbase/ui-ang/services';
import { InputEmailConfig, INPUT_EMAIL_CONFIG_TOKEN } from './input-email.token-config';

/**
 * @name InputEmailComponent
 *
 * @description
 * Component that displays an email input.
 *
 * ### Global configuration token
 * `INPUT_EMAIL_CONFIG_TOKEN` enables you to globally set the same configuration for all instances of `InputEmailComponent` instances in your project.
 *
 * *Note:* The token overwrites the default value only. If you have provided a value as a property on a specific component, the token is not be able to overwrite it.
 *
 * The following properties can be overwritten using the token:
 *  - `autocomplete`
 *  - `maxLength`
 *  - `minLength`
 *  - `placeholder`
 *  - `size`
 *  - `pattern`
 *
 * #### Usage notes
 * The following is an example of how to use the token:
 *
 * ```typescript
 *  import { INPUT_EMAIL_CONFIG_TOKEN } from '@backbase/ui-ang/input-email';
 *  import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *  import { AppModule } from './app/app.module';
 *
 *  const inputEmailConfig = {
 *    autocomplete: 'email'
 *  }
 *
 *  platformBrowserDynamic().bootstrapModule(AppModule, {
 *    providers: [{ provide: INPUT_EMAIL_CONFIG_TOKEN, useValue: inputEmailConfig }]
 *  });
 * ```
 *
 * ### Accessibility
 * Current component provide option to pass needed accessibility
 * attributes. You need to take care of properties that are required in your case :
 *  - role
 *  - aria-activedescendant
 *  - aria-describedby
 *  - aria-expanded
 *  - aria-invalid
 *  - aria-label
 *  - aria-labelledby
 *  - aria-owns
 */
@Component({
  selector: 'bb-input-email-ui',
  templateUrl: './input-email.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputEmailComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputEmailComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class InputEmailComponent extends InputBaseComponent implements Validator, AfterViewInit {
  /**
   * The placeholder for the email input. Defaults to an empty string;
   *
   * This attribute can be overwritten via the global configuration token
   */
  @Input('placeholder')
  get placeholder(): string | undefined {
    return this._placeholder ?? this.overrideConfiguration?.placeholder ?? '';
  }

  set placeholder(value: InputEmailConfig['placeholder']) {
    this._placeholder = value;
  }

  private _placeholder: InputEmailConfig['placeholder'];

  /**
   * The size for the email input. Defaults to an empty string;
   *
   * This attribute can be overwritten via the global configuration token
   */
  @Input('size')
  get size(): number | string {
    return this._size ?? this.overrideConfiguration?.size ?? defaultSize;
  }

  /**
   * The maxLength for the email input.
   *
   * This attribute can be overwritten via the global configuration token
   */
  @Input('maxLength')
  get maxLength(): number | undefined {
    return this._maxLength ?? this.overrideConfiguration?.maxLength;
  }

  set maxLength(value: InputEmailConfig['maxLength']) {
    this._maxLength = value;
  }

  private _maxLength: InputEmailConfig['maxLength'];

  /**
   * The minLength for the email input.
   *
   * This attribute can be overwritten via the global configuration token
   */
  @Input('minLength')
  get minLength(): number | undefined {
    return this._minLength ?? this.overrideConfiguration?.minLength;
  }

  set minLength(value: InputEmailConfig['minLength']) {
    this._minLength = value;
  }

  private _minLength: InputEmailConfig['minLength'];
  /**
   * Whether the email input is readonly.
   */
  @Input() readonly = false;

  /**
   * The autocomplete value of enclosed input control.
   * If not set, 'email' is used.
   * This attribute can be overwritten via the global configuration token
   */
  @Input('autocomplete')
  get autocomplete(): 'email' | 'off' | 'on' {
    return this._autocomplete ?? this.overrideConfiguration?.autocomplete ?? 'email';
  }

  set autocomplete(value: 'email' | 'off' | 'on') {
    this._autocomplete = value;
  }

  private _autocomplete: InputEmailConfig['autocomplete'];

  /**
   * The validation pattern for the email input. Defaults to undefined, resulting
   * in the validation check using the built-in Angular validator, Validators.email(control).
   *
   * This attribute can be overwritten via the global configuration token
   */
  @Input('pattern')
  get pattern(): string | RegExp | undefined {
    return this._pattern ?? this.overrideConfiguration?.pattern;
  }

  set pattern(value: InputEmailConfig['pattern']) {
    this._pattern = value;
  }

  private _pattern: InputEmailConfig['pattern'];

  @ViewChild('inputEmail') inputEl: ElementRef | undefined;

  constructor(
    private readonly domAttrService: DomAttributesService,
    private readonly elem: ElementRef,
    private readonly renderer2: Renderer2,
    protected readonly cd: ChangeDetectorRef,
    @Optional()
    @Inject(INPUT_EMAIL_CONFIG_TOKEN)
    private readonly overrideConfiguration: InputEmailConfig,
  ) {
    super(cd);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.pattern ? Validators.pattern(this.pattern)(control) : Validators.email(control);
  }

  ngAfterViewInit(): void {
    if (!this.ariaLabel) {
      this.domAttrService.moveAriaAttributes(
        this.elem.nativeElement,
        this.inputEl && this.inputEl.nativeElement,
        this.renderer2,
      );
    }
  }
}
