{
  "name": "@backbase/ui-ang",
  "version": "13.1.17",
  "description": "Backbase UI Components",
  "peerDependencies": {
    "@angular/animations": "^19",
    "@angular/cdk": "^19",
    "@angular/core": "^19",
    "@angular/common": "^19",
    "@angular/forms": "^19",
    "@angular/localize": "^19",
    "@angular/platform-browser": "^19",
    "@angular/router": "^19",
    "@ng-bootstrap/ng-bootstrap": "^18",
    "@ng-select/ng-select": "^14",
    "bignumber.js": "^9.0.1",
    "bootstrap": "~5.3.2",
    "ngx-mask": "^19",
    "ngx-quill": "^27",
    "quill": "^2.0.0",
    "rxjs": ">=7.8.0",
    "zone.js": "^0.15.0"
  },
  "dependencies": {
    "@angular/compiler": "^19.1.5",
    "@material/animation": "^14.0.0",
    "@nx/devkit": "^20.4.2",
    "@phenomnomnominal/tsquery": "^6.1.3",
    "jsdom": "^21.1.1",
    "libphonenumber-js": "^1.10.48",
    "tslib": "^2.3.0"
  },
  "ng-update": {
    "migrations": "./migrations.json"
  },
  "exports": {
    "./scss/*": {
      "sass": "./scss/*.scss"
    },
    "./assets/*": {
      "default": "./assets/*"
    },
    "./migrations.json": "./migrations.json",
    "./package.json": {
      "default": "./package.json"
    },
    ".": {
      "types": "./index.d.ts",
      "default": "./fesm2022/backbase-ui-ang.mjs"
    },
    "./account-number": {
      "types": "./account-number/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-account-number.mjs"
    },
    "./account-number-pipe": {
      "types": "./account-number-pipe/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-account-number-pipe.mjs"
    },
    "./account-selector": {
      "types": "./account-selector/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-account-selector.mjs"
    },
    "./action-status": {
      "types": "./action-status/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-action-status.mjs"
    },
    "./alert": {
      "types": "./alert/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-alert.mjs"
    },
    "./amount": {
      "types": "./amount/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-amount.mjs"
    },
    "./amount-input": {
      "types": "./amount-input/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-amount-input.mjs"
    },
    "./animations": {
      "types": "./animations/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-animations.mjs"
    },
    "./avatar": {
      "types": "./avatar/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-avatar.mjs"
    },
    "./badge": {
      "types": "./badge/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-badge.mjs"
    },
    "./badge-counter": {
      "types": "./badge-counter/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-badge-counter.mjs"
    },
    "./base-classes": {
      "types": "./base-classes/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-base-classes.mjs"
    },
    "./button": {
      "types": "./button/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-button.mjs"
    },
    "./card-vendor": {
      "types": "./card-vendor/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-card-vendor.mjs"
    },
    "./char-counter": {
      "types": "./char-counter/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-char-counter.mjs"
    },
    "./checkbox-group": {
      "types": "./checkbox-group/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-checkbox-group.mjs"
    },
    "./chip": {
      "types": "./chip/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-chip.mjs"
    },
    "./collapsible": {
      "types": "./collapsible/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-collapsible.mjs"
    },
    "./collapsible-accordion": {
      "types": "./collapsible-accordion/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-collapsible-accordion.mjs"
    },
    "./collapsible-card": {
      "types": "./collapsible-card/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-collapsible-card.mjs"
    },
    "./common-error-state": {
      "types": "./common-error-state/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-common-error-state.mjs"
    },
    "./control-error-handler": {
      "types": "./control-error-handler/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-control-error-handler.mjs"
    },
    "./css-variables-lib": {
      "types": "./css-variables-lib/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-css-variables-lib.mjs"
    },
    "./currency-input": {
      "types": "./currency-input/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-currency-input.mjs"
    },
    "./date-pipe": {
      "types": "./date-pipe/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-date-pipe.mjs"
    },
    "./date-timezone-transformation": {
      "types": "./date-timezone-transformation/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-date-timezone-transformation.mjs"
    },
    "./dropdown-menu": {
      "types": "./dropdown-menu/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-dropdown-menu.mjs"
    },
    "./dropdown-multi-select": {
      "types": "./dropdown-multi-select/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-dropdown-multi-select.mjs"
    },
    "./dropdown-panel": {
      "types": "./dropdown-panel/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-dropdown-panel.mjs"
    },
    "./dropdown-single-select": {
      "types": "./dropdown-single-select/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-dropdown-single-select.mjs"
    },
    "./ellipsis": {
      "types": "./ellipsis/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-ellipsis.mjs"
    },
    "./empty-state": {
      "types": "./empty-state/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-empty-state.mjs"
    },
    "./fieldset": {
      "types": "./fieldset/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-fieldset.mjs"
    },
    "./file-attachment": {
      "types": "./file-attachment/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-file-attachment.mjs"
    },
    "./focus": {
      "types": "./focus/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-focus.mjs"
    },
    "./focus-invalid-input": {
      "types": "./focus-invalid-input/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-focus-invalid-input.mjs"
    },
    "./highlight": {
      "types": "./highlight/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-highlight.mjs"
    },
    "./iban-directive": {
      "types": "./iban-directive/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-iban-directive.mjs"
    },
    "./iban-lib": {
      "types": "./iban-lib/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-iban-lib.mjs"
    },
    "./iban-pipe": {
      "types": "./iban-pipe/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-iban-pipe.mjs"
    },
    "./icon": {
      "types": "./icon/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-icon.mjs"
    },
    "./illustration": {
      "types": "./illustration/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-illustration.mjs"
    },
    "./infinite-scroll": {
      "types": "./infinite-scroll/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-infinite-scroll.mjs"
    },
    "./input-checkbox": {
      "types": "./input-checkbox/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-input-checkbox.mjs"
    },
    "./input-datepicker": {
      "types": "./input-datepicker/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-input-datepicker.mjs"
    },
    "./input-email": {
      "types": "./input-email/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-input-email.mjs"
    },
    "./input-file": {
      "types": "./input-file/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-input-file.mjs"
    },
    "./input-inline-edit": {
      "types": "./input-inline-edit/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-input-inline-edit.mjs"
    },
    "./input-number": {
      "types": "./input-number/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-input-number.mjs"
    },
    "./input-password": {
      "types": "./input-password/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-input-password.mjs"
    },
    "./input-phone": {
      "types": "./input-phone/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-input-phone.mjs"
    },
    "./input-radio-group": {
      "types": "./input-radio-group/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-input-radio-group.mjs"
    },
    "./input-range": {
      "types": "./input-range/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-input-range.mjs"
    },
    "./input-text": {
      "types": "./input-text/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-input-text.mjs"
    },
    "./input-timepicker": {
      "types": "./input-timepicker/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-input-timepicker.mjs"
    },
    "./input-validation-message": {
      "types": "./input-validation-message/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-input-validation-message.mjs"
    },
    "./item-log": {
      "types": "./item-log/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-item-log.mjs"
    },
    "./keyboard-click-directive": {
      "types": "./keyboard-click-directive/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-keyboard-click-directive.mjs"
    },
    "./layout": {
      "types": "./layout/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-layout.mjs"
    },
    "./list-multiple-select": {
      "types": "./list-multiple-select/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-list-multiple-select.mjs"
    },
    "./load-button": {
      "types": "./load-button/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-load-button.mjs"
    },
    "./loading-indicator": {
      "types": "./loading-indicator/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-loading-indicator.mjs"
    },
    "./locale-selector": {
      "types": "./locale-selector/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-locale-selector.mjs"
    },
    "./logo": {
      "types": "./logo/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-logo.mjs"
    },
    "./media-query-lib": {
      "types": "./media-query-lib/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-media-query-lib.mjs"
    },
    "./modal": {
      "types": "./modal/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-modal.mjs"
    },
    "./mode-header": {
      "types": "./mode-header/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-mode-header.mjs"
    },
    "./money-protection": {
      "types": "./money-protection/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-money-protection.mjs"
    },
    "./notification": {
      "types": "./notification/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-notification.mjs"
    },
    "./option-list": {
      "types": "./option-list/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-option-list.mjs"
    },
    "./page-header": {
      "types": "./page-header/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-page-header.mjs"
    },
    "./pagination": {
      "types": "./pagination/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-pagination.mjs"
    },
    "./payment-card": {
      "types": "./payment-card/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-payment-card.mjs"
    },
    "./payment-card-number-pipe": {
      "types": "./payment-card-number-pipe/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-payment-card-number-pipe.mjs"
    },
    "./period-selector": {
      "types": "./period-selector/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-period-selector.mjs"
    },
    "./phone-number-format-pipe": {
      "types": "./phone-number-format-pipe/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-phone-number-format-pipe.mjs"
    },
    "./product-item-basic-account": {
      "types": "./product-item-basic-account/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-product-item-basic-account.mjs"
    },
    "./product-item-credit-card": {
      "types": "./product-item-credit-card/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-product-item-credit-card.mjs"
    },
    "./product-item-current-account": {
      "types": "./product-item-current-account/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-product-item-current-account.mjs"
    },
    "./product-item-debit-card": {
      "types": "./product-item-debit-card/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-product-item-debit-card.mjs"
    },
    "./product-item-investment-account": {
      "types": "./product-item-investment-account/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-product-item-investment-account.mjs"
    },
    "./product-item-loan": {
      "types": "./product-item-loan/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-product-item-loan.mjs"
    },
    "./product-item-savings-account": {
      "types": "./product-item-savings-account/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-product-item-savings-account.mjs"
    },
    "./product-item-term-deposit": {
      "types": "./product-item-term-deposit/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-product-item-term-deposit.mjs"
    },
    "./product-selector": {
      "types": "./product-selector/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-product-selector.mjs"
    },
    "./progress-tracker": {
      "types": "./progress-tracker/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-progress-tracker.mjs"
    },
    "./progressbar": {
      "types": "./progressbar/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-progressbar.mjs"
    },
    "./rich-text-editor": {
      "types": "./rich-text-editor/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-rich-text-editor.mjs"
    },
    "./search-box": {
      "types": "./search-box/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-search-box.mjs"
    },
    "./select-list": {
      "types": "./select-list/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-select-list.mjs"
    },
    "./services": {
      "types": "./services/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-services.mjs"
    },
    "./stepper": {
      "types": "./stepper/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-stepper.mjs"
    },
    "./switch": {
      "types": "./switch/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-switch.mjs"
    },
    "./tab": {
      "types": "./tab/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-tab.mjs"
    },
    "./table": {
      "types": "./table/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-table.mjs"
    },
    "./textarea": {
      "types": "./textarea/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-textarea.mjs"
    },
    "./tooltip-directive": {
      "types": "./tooltip-directive/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-tooltip-directive.mjs"
    },
    "./tracker": {
      "types": "./tracker/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-tracker.mjs"
    },
    "./universal-header": {
      "types": "./universal-header/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-universal-header.mjs"
    },
    "./util": {
      "types": "./util/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-util.mjs"
    },
    "./value-diff": {
      "types": "./value-diff/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-value-diff.mjs"
    },
    "./view-extensions": {
      "types": "./view-extensions/index.d.ts",
      "default": "./fesm2022/backbase-ui-ang-view-extensions.mjs"
    }
  },
  "backbase": {
    "design-system": "2024.10"
  },
  "module": "fesm2022/backbase-ui-ang.mjs",
  "typings": "index.d.ts",
  "sideEffects": false
}