import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'bb-showcase-android',
  templateUrl: './showcase-android.component.html',
  standalone: false,
})
export class ShowcaseAndroidComponent {
  path$: Observable<string | undefined> = this.route.paramMap.pipe(
    map((params: ParamMap) => {
      const componentId = params.get('id');
      const platform = this.route.snapshot.url[0]?.path;

      if (platform === 'androidCompose') {
        return `assets/docs/html/android-compose-${componentId}-page.component.html`;
      } else {
        return `assets/docs/html/android-${componentId}-page.component.html`;
      }
    }),
  );
  constructor(private route: ActivatedRoute) {}
}
