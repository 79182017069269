import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-pagination-ui-showcase',
  templateUrl: 'pagination-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class PaginationShowcaseComponent {
  get paginator() {
    return {
      boundaryLinks: true,
      directionLinks: true,
      itemsPerPage: 10,
      onPageChange(page: number) {
        console.log('onPageChange', page);
      },
    };
  }

  paginatorOne = {
    page: 0,
    totalItems: 30,
    maxNavPages: 3,
    type: 'paginator-small-untruncated',
  };

  paginatorTwo = {
    page: 7,
    totalItems: 100,
    maxNavPages: 5,
    type: 'paginator-small-left-truncated',
  };

  paginatorThree = {
    page: 0,
    totalItems: 90,
    maxNavPages: 4,
    type: 'paginator-small-right-truncated',
  };

  paginatorFour = {
    page: 1020 / 10,
    totalItems: 1020,
    maxNavPages: 5,
    type: 'paginator-big-left-truncated',
  };
}
