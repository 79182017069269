import { Component } from '@angular/core';

@Component({
    selector: 'bb-dropdowns-page-ui',
    templateUrl: './bb-dropdowns-page-ui.component.html',
    standalone: false,
})
export class DropdownsPageComponent {
  dropdownMenuOptions = [
    { name: 'google', url: 'https://google.com' },
    { name: 'twitter', url: 'https://twitter.com' },
    { name: 'facebook', url: 'https://www.facebook.com' },
  ];

  onButtonDropdownSelect(item: any) {
    console.log(item);
  }
}
