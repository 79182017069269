import {
  AfterViewInit,
  ContentChild,
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  Self,
  SimpleChanges,
} from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { merge, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: 'bb-input-number-ui[bbInputRangeMin]',
  standalone: false,
})
export class InputRangeMinDirective implements OnChanges {
  /**
   * Minimum possible value (optional);
   */
  @Input() bbInputRangeMin?: number;

  constructor(@Self() readonly control: NgControl) {}

  ngOnChanges({ bbInputRangeMin }: SimpleChanges): void {
    const c = this.control;
    if (!c.control) {
      return;
    }
    if (!bbInputRangeMin.firstChange) {
      c.control.clearValidators();
    }
    if (typeof this.bbInputRangeMin === 'number') {
      c.control.setValidators(Validators.min(this.bbInputRangeMin));
    }
    c.control.updateValueAndValidity();
  }
}

@Directive({
  selector: 'bb-input-number-ui[bbInputRangeMax]',
  standalone: false,
})
export class InputRangeMaxDirective implements OnChanges {
  /**
   * Maximum possible value (optional);
   */
  @Input() bbInputRangeMax?: number;

  constructor(@Self() readonly control: NgControl) {}

  ngOnChanges({ bbInputRangeMax }: SimpleChanges): void {
    const c = this.control;
    if (!c.control) {
      return;
    }
    if (!bbInputRangeMax.firstChange) {
      c.control.clearValidators();
    }
    if (typeof this.bbInputRangeMax === 'number') {
      c.control.setValidators(Validators.max(this.bbInputRangeMax));
    }
    c.control.updateValueAndValidity();
  }
}

@Directive({
  selector: '[bbInputRange]',
  standalone: false,
})
export class InputRangeDirective implements AfterViewInit, OnDestroy {
  @ContentChild(InputRangeMinDirective, { static: true }) min: InputRangeMinDirective | undefined;
  @ContentChild(InputRangeMaxDirective, { static: true }) max: InputRangeMaxDirective | undefined;
  private readonly destroy$$: Subject<void> = new Subject();

  ngAfterViewInit(): void {
    const cMin = this.min && this.min.control && this.min.control.control;
    const cMax = this.max && this.max.control && this.max.control.control;
    if (!(cMin && cMax)) {
      return;
    }
    merge(
      cMin.valueChanges.pipe(
        tap((min: number) => {
          const max = cMax.value;
          if (typeof max !== 'number' || min > max) {
            cMax.patchValue(min, { emitEvent: false });
          }
        }),
      ),
      cMax.valueChanges.pipe(
        tap((max: number) => {
          const min = cMin.value;
          const areNumbers = typeof min === 'number' && typeof max === 'number';
          if (areNumbers && max < min) {
            cMin.patchValue(max, { emitEvent: false });
          }
        }),
      ),
    )
      .pipe(takeUntil(this.destroy$$))
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.destroy$$) {
      this.destroy$$.next();
      this.destroy$$.complete();
    }
  }
}
