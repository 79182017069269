import { NgModule } from '@angular/core';
import { ButtonModule } from '@backbase/ui-ang/button';
import { IconModule } from '@backbase/ui-ang/icon';
import { ModeHeaderComponent } from './mode-header.component';

@NgModule({
  imports: [ButtonModule, IconModule],
  declarations: [ModeHeaderComponent],
  exports: [ModeHeaderComponent],
})
export class ModeHeaderModule {}
