import { Pipe, PipeTransform } from '@angular/core';
import type { NotificationType } from './notification.component';

@Pipe({
  name: 'notificationIcon',
  standalone: false,
})
export class NotificationIconPipe implements PipeTransform {
  transform(modifier: NotificationType) {
    switch (modifier) {
      case 'error':
        return 'error-outline';

      case 'warning':
        return 'warning-amber';

      default:
        return modifier;
    }
  }
}
