import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HtmlRenderService } from '../../../services/html-render.service';
import { BehaviorSubject, Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'bb-doc-showcase-pages',
  templateUrl: './doc-showcase-pages.component.html',
  styles: [],
  standalone: false,
})
export class DocShowcasePagesComponent implements OnInit {
  hideMiniMenu = false;

  contentHeadersElements$ = new BehaviorSubject([]);

  private destroy$ = new Subject<void>();

  constructor(
    private el: ElementRef,
    private router: Router,
    private htmlRender: HtmlRenderService,
  ) {}

  ngOnInit(): void {
    this.hideMiniMenu = Boolean(['/design-token-api'].find((page) => this.router.url.search(`${page}`) >= 0));

    this.htmlRender.rendering
      .pipe(
        filter((rendering) => !rendering),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.contentHeadersElements$.next(this.el.nativeElement.getElementsByTagName('h2'));
      });
  }
}
