import {
  ChangeDetectionStrategy,
  Component,
  Input,
  SimpleChanges,
  OnChanges,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateLocaleParserFormatter } from './input-datepicker-formatter';
import { DatePipe } from '@angular/common';
import { NgDateStructNullable } from './input-datepicker.model';
import { KEY_CODES } from '@backbase/ui-ang/util';
import { BrowserService, NAVIGATOR_TOKEN, navigatorFactory } from '@backbase/ui-ang/services';
import { DateSelectionModel } from './input-datepicker.model';

/* eslint-disable */
export enum Key {
  ArrowLeft = 37,
  ArrowUp = 38,
  ArrowRight = 39,
  ArrowDown = 40,
}

@Component({
  selector: 'bb-input-datepicker-single-day-template-ui',
  templateUrl: './input-datepicker-day-template-single.component.html',
  providers: [
    DatePipe,
    NgbDateLocaleParserFormatter,
    {
      provide: NgbDateParserFormatter,
      useExisting: NgbDateLocaleParserFormatter,
    },
    BrowserService,
    { provide: NAVIGATOR_TOKEN, useFactory: navigatorFactory },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InputDatepickerDayTemplateSingleComponent implements OnChanges {
  constructor(
    private readonly formatterHelper: NgbDateLocaleParserFormatter,
    private readonly browserService: BrowserService,
  ) {
    this.isIE = this.browserService.browserIsMSIE();
  }

  private static readonly hangledKeyCodes: string[] = [KEY_CODES.LEFT, KEY_CODES.UP, KEY_CODES.RIGHT, KEY_CODES.DOWN];

  /**
   * Date to render.
   */
  @Input() date!: NgbDate;

  /**
   * Indicates whether the date is selected
   */
  @Input() selected = false;

  /**
   * When true the date is rendered as disabled
   */
  @Input() disabled = false;

  /**
   * The current month on the datepicker window.
   */
  @Input() currentMonth!: string;

  /**
   * Hovered date by a user.
   */
  @Input() hoveredDate: NgDateStructNullable = null;

  /**
   * Event to trigger on date hover.
   */
  @Output() hovered = new EventEmitter<DateSelectionModel>();

  /**
   * Indicates if the date displayed as greyed out.
   */
  isTextMuted = false;

  /**
   * Indicates if the date is hovered the range
   */
  isHovered = false;

  /**
   * Indicates if the browser is IE
   */
  isIE: boolean;

  format = (date: NgbDateStruct | undefined | null) => this.formatterHelper.format(date);

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.selected || changes?.disabled || changes?.date || changes?.currentMonth) {
      this.isTextMuted = !this.selected && (this.disabled || this.date?.month !== +this.currentMonth);
    }
    this.isHovered = this.date?.equals(this.hoveredDate);
  }

  /**
   *
   * @description
   * Handle mouseover. Emit the hovered date with null.
   *
   */
  @HostListener('mouseout')
  onMouseOut() {
    this.hovered.emit({ date: null, isSelecting: false });
  }

  /**
   *
   * @description
   * Handle mouseover. Emit the hovered date with date.
   *
   */
  @HostListener('mouseover')
  onMouseOver() {
    this.hovered.emit({ date: this.date, isSelecting: true });
  }

  /**
   * @description
   * Keypress handler
   *
   * @param evt
   * @param curMonth
   * @param curDay
   * @param date
   */
  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    const day = (event.target as HTMLElement).textContent;
    const matchKey = this.isIE
      ? Object.values(Key).includes(event?.keyCode)
      : InputDatepickerDayTemplateSingleComponent.hangledKeyCodes.includes(event?.key);

    if (Number(day) === this.date.day && Number(this.currentMonth) === this.date.month && matchKey) {
      this.hovered.emit({ date: this.date, isSelecting: true });
    }
  }
}
