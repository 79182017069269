import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { NgbDropdown, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';

@Directive({
  selector: 'ng-template[bbDropdownMenu]',
  standalone: false,
})
export class DropdownMenuDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: 'ng-template[bbDropdownToggle]',
  standalone: false,
})
export class DropdownToggleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * @name DropdownPanelComponent
 *
 * @description
 * Component incapsulating logic for showing/hiding dropdown.
 */
@Component({
  selector: 'bb-dropdown-panel-ui',
  templateUrl: './dropdown-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgbDropdownConfig],
  standalone: false,
})
export class DropdownPanelComponent extends InputBaseComponent implements AfterContentInit, OnDestroy {
  /**
   * @deprecated Deprecated in ui-ang@12. To be removed in ui-ang@14. Replace with isDropdownOpen.
   */
  readonly isOpen = new Subject<boolean>();

  /**
   * Defines whether or not the dropdown menu is opened initially.
   * Defaults to `false`.
   */
  @Input()
  set isDropdownOpen(value) {
    this.isOpen.next(value);
    this._isOpen = value;
  }

  get isDropdownOpen() {
    return this._isOpen;
  }

  private _isOpen = false;

  /**
   * Class for the dropdown toggle
   */
  @Input() buttonClass = '';

  /**
   * The position of the dropdown, position will be picked in order of feasibility.
   */
  @Input() position: PlacementArray = ['bottom-end', 'bottom-start', 'top-end', 'top-start'];

  /**
   * Specifies which element the dropdown should be appended to.
   */
  @Input() container: '' | 'body' = '';

  /**
   * Event emitted when the dropdown toggle loses focus
   */
  @Output() focusout = new EventEmitter<FocusEvent | void>();

  /**
   * Event emitted when dropdown open/close.
   */
  @Output()
  readonly open: Observable<boolean> = this.isOpen.asObservable();
  /**
   * Represents dropdown panel menu template
   */
  @ContentChild(DropdownMenuDirective, { read: TemplateRef, static: true })
  customDropdownContent: TemplateRef<any> | undefined;

  /**
   * Represents dropdown panel toggle template
   */
  @ContentChild(DropdownToggleDirective, { read: TemplateRef, static: true })
  customDropdownToggle: TemplateRef<any> | undefined;

  @ViewChild(NgbDropdown, { static: true })
  private readonly dropdown: NgbDropdown | undefined;
  private readonly destroy$ = new Subject<void>();

  constructor(
    protected readonly cd: ChangeDetectorRef,
    config: NgbDropdownConfig,
  ) {
    super(cd);

    config.autoClose = 'outside';
  }

  changeState(state: boolean) {
    this.isOpen.next(state);
  }

  onFocusOut($event: FocusEvent) {
    this.focusout.emit($event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterContentInit(): void {
    if (!this.customDropdownToggle) {
      throw new Error('BB Dropdown ui. Toggle content is required.');
    }

    if (!this.customDropdownContent) {
      throw new Error('BB Dropdown ui. Dropdown content is required.');
    }
  }

  closeMenu() {
    if (this.dropdown) {
      this.dropdown.close();
    }
  }
}
