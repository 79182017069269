import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titlecaseExcept',
  standalone: false,
})
export class TitleCaseExceptPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value; // safeguard

    const exclude = ['iOS'];
    const excludeMiddle = [...exclude, 'of', 'by', 'with', 'the', 'in'];

    const words = value.split(/\s/);
    const newVal = words
      .map((word) => {
        if (!excludeMiddle.includes(word)) {
          return word.replace(/^\w/, (w) => w.charAt(0).toUpperCase() + w.substr(1).toLowerCase());
        }

        return word;
      })
      .join(' ');

    // always capitalize the first character of newVal
    return exclude.includes(words[0]) ? newVal : newVal.charAt(0).toUpperCase() + newVal.substr(1);
  }
}
