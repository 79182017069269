import {
  Component,
  Input,
  Output,
  EventEmitter,
  ContentChildren,
  QueryList,
  HostListener,
  ElementRef,
  Renderer2,
} from '@angular/core';

import { TabComponent } from './tab.component';

/**
 * TabGroupComponent represents component that is used
 * as a wrapper for tab item components. It tracks user interaction and
 * notifies parent upon selection change
 */
@Component({
  selector: 'bb-tab-group-ui',
  templateUrl: './tab-group.component.html',
  standalone: false,
})
export class TabGroupComponent {
  constructor(
    private el: ElementRef,
    private renderer2: Renderer2,
  ) {}
  /**
   * Setter (Input) for initial tab item selection
   *
   * @param initialSelection Index of initially selected tab item
   */
  @Input()
  set initialSelection(initSelection: number | undefined) {
    this.activeIndexValue = initSelection || 0;
  }
  /**
   * Event (Output) that emits new value once tab item selection has changed.
   * As a parameter it provides index of newly selected item
   */
  @Output() select = new EventEmitter<number>();
  /**
   * Represents a list of tab items component inside this wrapper
   */
  @ContentChildren(TabComponent) items: QueryList<TabComponent> | undefined;

  /**
   * Keeps track of currently active tab item index
   */
  private activeIndexValue = 0;

  /**
   * Getter for index of currently active tab item
   */
  get activeIndex() {
    return this.activeIndexValue;
  }

  onKeyDown(event: KeyboardEvent, index: number) {
    if (event.key !== 'ArrowRight' && event.key !== 'ArrowLeft') {
      return;
    }
    const tabList = this.el.nativeElement.querySelector('[role="tablist"]');
    let tabItemIndex = index;
    const numberOfChildren = tabList.children.length;
    if (event.key === 'ArrowRight') {
      tabItemIndex++;
      tabItemIndex = this.getIndexOfNextTab(tabItemIndex, numberOfChildren);
    }
    if (event.key === 'ArrowLeft') {
      tabItemIndex--;
      tabItemIndex = this.getIndexOfPreviousTab(tabItemIndex, numberOfChildren);
    }

    const nextTabEl = this.el.nativeElement.querySelectorAll('[role="tab"]')[tabItemIndex];
    const currentTabEl = this.el.nativeElement.querySelector('[aria-selected="true"]');
    //remove focus from current element
    currentTabEl.blur();
    //set focus to the next/previous element
    nextTabEl.focus();
  }

  private getIndexOfNextTab(tabItemIndex: number, numberOfChildren: number) {
    //if next tab is disabled
    if (this.items?.get(tabItemIndex)?.disabled) {
      //then check all the next tab until the tab is not disabled and return the index
      for (let i = tabItemIndex + 1; i <= numberOfChildren; i++) {
        //last tab is disabled then start checking from starting until find the next enabled tab
        if (i >= numberOfChildren) i = 0;
        if (!this.items?.get(i)?.disabled) {
          tabItemIndex = i;
          break;
        }
      }
    }
    //if index is greater than length then assign the index of the first tab
    if (tabItemIndex >= numberOfChildren) tabItemIndex = 0;

    return tabItemIndex;
  }

  private getIndexOfPreviousTab(tabItemIndex: number, numberOfChildren: number) {
    //if index is less than 0 then assign the index of the last tab
    if (tabItemIndex < 0) tabItemIndex = numberOfChildren - 1;
    //if previous tab is disabled
    if (this.items?.get(tabItemIndex)?.disabled) {
      //then check all the previous tab until the tab is not disabled and return the index
      for (let i = tabItemIndex - 1; i >= -1; i--) {
        //if 1st tab is disabled then start checking from last tab until fint the next enabled tab
        if (i < 0) i = numberOfChildren - 1;
        if (!this.items?.get(i)?.disabled) {
          tabItemIndex = i;
          break;
        }
      }
    }

    return tabItemIndex;
  }
  /**
   * Internal handler for clicks on tab items
   *
   * @param index Index of element that has been clicked
   */
  onClick(event: MouseEvent, index: number) {
    event.preventDefault();

    if (this.activeIndex === index) {
      return;
    }

    this.activeIndexValue = index;
    this.select.emit(index);
  }
}
