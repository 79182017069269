import { Component } from '@angular/core';
import { ValueDiffPosition } from '@backbase/ui-ang/value-diff';

@Component({
  selector: 'bb-value-diff-ui-showcase',
  templateUrl: './value-diff-showcase.component.html',
  standalone: false,
})
export class ValueDiffShowcaseComponent {
  oldValue = 'A value';
  newValue = 'A      value';

  oldValueAfter = 'Old Value';
  newValueAfter = 'New Value';

  positionAfter = ValueDiffPosition.AFTER;

  reset() {
    this.oldValue = 'Equal    Value';
    this.newValue = 'Equal    Value';
  }

  changeData() {
    this.oldValue = ValueDiffShowcaseComponent.makeId();
    this.newValue = ValueDiffShowcaseComponent.makeId();
  }

  private static makeId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result = '';

    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
}
