import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, Observable } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'bb-menu-item',
  templateUrl: './menu-item.component.html',
  standalone: false,
})
export class MenuItemComponent {
  path$: Observable<string | undefined> = this.route.url.pipe(
    map(() => {
      const parsedPath = this.location.path().substring(1).replaceAll('/', '-');

      return `assets/docs/html/${parsedPath}-page.component.html`;
    }),
  );

  constructor(
    private route: ActivatedRoute,
    private location: Location,
  ) {}
}
