import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  ChangeDetectorRef,
  AfterContentChecked,
  OnDestroy,
} from '@angular/core';
import { ControlContainer, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { filter, takeUntil } from 'rxjs/operators';
import { InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';

/**
 * @name CheckboxGroupComponent
 *
 * @description
 * Stores a state, determines it and displays a parent checkbox for a checkboxes group.
 * Required module(s): FormsModule
 */
@Component({
  selector: 'bb-checkbox-group-ui',
  templateUrl: './checkbox-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxGroupComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class CheckboxGroupComponent extends InputBaseComponent implements AfterContentChecked, OnDestroy {
  /**
   * Sets the indeterminate state of the parent checkbox.
   */
  indeterminate = false;
  private control?: UntypedFormGroup;
  private readonly skip$ = new BehaviorSubject<boolean>(false);
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    protected readonly cd: ChangeDetectorRef,
    private readonly controlContainer: ControlContainer,
  ) {
    super(cd);
  }

  /**
   * Called after every change detection cycle.
   *
   * @description
   *  - Updates the control variable with the current form group.
   *  - Subscribes to changes in the form group's value and updates the state accordingly.
   */
  ngAfterContentChecked() {
    if (this.controlContainer.control && this.control !== this.controlContainer.control) {
      this.control = this.controlContainer.control as UntypedFormGroup;
      this.checkState(this.control.value);
      combineLatest(this.control.valueChanges, this.skip$)
        .pipe(
          filter(([state, skip]) => !skip),
          takeUntil(this.unsubscribe$),
        )
        .subscribe({ next: ([state]) => this.checkState(state) });
    }
  }

  /**
   * Called when the component is about to be destroyed.
   *
   * @description
   *  - Unsubscribes from all subscriptions to avoid memory leaks.
   */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Called when the value of the component changes.
   *
   * @description
   *  - Updates the value of the control and sets the skip$ behavior subject to avoid infinite loops.
   */
  onValueChange() {
    const setValue = (control: UntypedFormGroup) => {
      Object.keys(control.value).forEach((key) =>
        control.controls[key] instanceof UntypedFormGroup
          ? setValue(control.controls[key] as UntypedFormGroup)
          : control.controls[key].setValue(this.value),
      );
    };

    if (this.control) {
      this.skip$.next(true);
      setValue(this.control);
      this.skip$.next(false);
    }
    super.onValueChange();
  }

  /**
   * Returns a boolean indicating whether the component is disabled.
   *
   * @returns A boolean indicating whether the component is disabled.
   */
  isDisabled(): boolean {
    return (
      !!this.control &&
      Object.keys(this.control.controls).every(
        (key) =>
          (this.control &&
            this.control.controls &&
            this.control.controls[key] &&
            this.control.controls[key].disabled) ||
          false,
      )
    );
  }

  private checkState(state: { [s: string]: boolean | undefined }) {
    /**
     * @param arrayHandler - a function to iterate over a tree
     * @description iterates over a tree using an arrayHandler function
     * @return a function which recursively calls arrayHandler over a tree
     */
    const checkGroup = (
      arrayHandler: (callbackfn: (value: boolean | undefined) => boolean, thisArg?: any) => boolean,
    ) => {
      /**
       * @param value - primitive or tree data structure
       * @return
       * if input value is a primitive - returns value, casted to boolean,
       * if input value is a tree - returns result of recursively calling arrayHandler function over a tree
       */
      const checkValue = (value?: { [s: string]: boolean | undefined } | boolean): boolean =>
        typeof value === 'object' && value !== null
          ? arrayHandler.call(
              Object.keys(value).map((key) => value[key]),
              checkValue,
            )
          : !!value;

      return (value: { [s: string]: boolean | undefined }) =>
        arrayHandler.call(
          Object.keys(value).map((key) => value[key]),
          checkValue,
        );
    };

    const isAllChecked = checkGroup(Array.prototype.every)(state);
    const isSomeChecked = checkGroup(Array.prototype.some)(state);
    this.indeterminate = isSomeChecked && !isAllChecked;
    this.writeValue(isAllChecked);
  }
}
