import { Component, OnInit } from '@angular/core';
import { ActionState } from '@backbase/ui-ang/action-status';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-action-status-ui-showcase',
  templateUrl: './action-status-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class ActionStatusShowcaseComponent implements OnInit {
  successExample: ActionState = 'success';
  errorExample: ActionState = 'error';
  warningExample: ActionState = 'warning';
  infoExample: ActionState = 'info';

  ngOnInit(): void {
    this.setStatesToLoading();
  }

  private setStatesToLoading() {
    setTimeout(() => {
      this.successExample = 'loading';
      this.errorExample = 'loading';
      this.warningExample = 'loading';
      this.infoExample = 'loading';
      this.setStatesToDone();
    }, 2000);
  }

  private setStatesToDone() {
    setTimeout(() => {
      this.successExample = 'success';
      this.errorExample = 'error';
      this.warningExample = 'warning';
      this.infoExample = 'info';
      this.setStatesToLoading();
    }, 2000);
  }
}
