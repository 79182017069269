import { Component } from '@angular/core';
import { Observable, of } from 'rxjs';
// Please replace relative path imports e.g. '../*' with '@backbase/ui-ang'.
import { SortEvent } from '@backbase/ui-ang/table';
import { CountryData } from './table.example.model';
import { countryData } from './countries';

const sortInteger: { [key: string]: number } = {
  asc: 1,
  desc: -1,
};

@Component({
  selector: 'bb-table-example-ui',
  templateUrl: './table.example.component.html',
  standalone: false,
})
export class TableExampleComponent {
  countries$?: Observable<CountryData[]>;

  sortDirection = 0;
  sortKey = '';

  pathFlagAssets = 'https://upload.wikimedia.org/wikipedia/commons/';

  pageSize = 10;
  currentPage = 0;

  constructor() {
    this.countries$ = of(this.getItems(0, this.pageSize));
  }

  onRowClick(country: CountryData) {
    alert(`You clicked on ${country.name}`);
  }

  onSort({ column, direction }: SortEvent) {
    this.sortDirection = direction ? sortInteger[direction] : 0;
    this.sortKey = column;

    this.countries$ = of(
      this.sort(countryData, this.sortKey, this.sortDirection),
    );
  }

  sort(
    countries: CountryData[],
    column: string,
    direction: number,
  ): CountryData[] {
    return [...countries].sort(
      (a, b) => direction * this.compare(a[column], b[column]),
    );
  }

  compare(v1: number | string, v2: number | string) {
    return v1 === v2 ? 0 : v1 < v2 ? -1 : 1;
  }

  getItems(start: number, end: number): CountryData[] {
    return countryData.slice(start, end);
  }

  getAriaSort(key: string): string | null {
    if (this.sortKey === key) {
      if (this.sortDirection < 0) {
        return 'descending';
      } else if (this.sortDirection > 0) {
        return 'ascending';
      }
    }

    return null;
  }

  get paginator() {
    return {
      page: this.currentPage,
      boundaryLinks: false,
      directionLinks: true,
      totalItems: countryData.length,
      itemsPerPage: this.pageSize,
      maxNavPages: 3,
      onPageChange: (page: number) => {
        this.countries$ = of(
          this.getItems(
            page * this.pageSize,
            page * this.pageSize + this.pageSize,
          ),
        );
      },
    };
  }
}
