import { Component } from '@angular/core';

@Component({
    selector: 'bb-item-log-page-ui',
    templateUrl: './bb-item-log-page-ui.component.html',
    styles: [],
    standalone: false,
})
export class BbItemLogPageUiComponent {
  constructor() {}
}
