import { Component } from '@angular/core';

@Component({
  selector: 'bb-phone-number-pipe-example-ui',
  templateUrl: './phone-number-pipe.example.component.html',
  standalone: false,
})
export class PhoneNumberPipeExampleComponent {
  workPhone = '4151234567';
}
