import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BB_DYNAMIC_VALIDATION_ERROR_TMPL, BB_VALIDATION_ERRORS } from '@backbase/ui-ang/control-error-handler';
import { customErrorMessagesShowcase, CustomErrorShowcaseComponent } from './custom-error-showcase.component';

@Component({
  selector: 'bb-input-with-custom-error-showcase',
  template: `
    <div>
      <bb-input-text-ui
        bbFormControl
        label="Input with custom error component using InjectionToken"
        [formControl]="inputField"
        placeholder="Please enter a value, at most 35 chars..."
      >
      </bb-input-text-ui>
    </div>
  `,
  providers: [
    {
      provide: BB_VALIDATION_ERRORS,
      useValue: customErrorMessagesShowcase,
    },
    {
      provide: BB_DYNAMIC_VALIDATION_ERROR_TMPL,
      useValue: CustomErrorShowcaseComponent,
    },
  ],
  standalone: false,
})
export class InputWithCustomErrorComponent {
  inputField = new UntypedFormControl(null, [Validators.required]);
}
