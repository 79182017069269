import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-input-file-ui-showcase',
  templateUrl: './input-file-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class InputFileShowcaseComponent {
  vForm: UntypedFormGroup | undefined;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.vForm = this.formBuilder.group({
      fileInput1: undefined,
      fileInput2: undefined,
      fileInput3: undefined,
      fileInput4: undefined,
      fileInput5: undefined,
      fileInput6: undefined,
      fileInput7: undefined,
    });
  }

  onUpload(event: Event) {
    console.log(event);
    console.log(this.vForm);
  }
}
