import { Directive, forwardRef, Input, OnDestroy } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { INVALID_DATE_FORMAT_KEY } from '../../input-datepicker.model';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'bb-input-datepicker-ui[maxDate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => MaxDateValidatorDirective),
    },
  ],
  standalone: false,
})
export class MaxDateValidatorDirective implements Validator, OnDestroy {
  private _maxDate: number = Number.MAX_VALUE;

  onValidatorChange: () => void = () => {};

  constructor() {}

  @Input()
  set maxDate(date: string | NgbDateStruct | undefined) {
    if (!date) {
      this._maxDate = Number.MAX_VALUE;
    } else {
      const maxDate = typeof date === 'string' ? new Date(date) : new Date(date.year, date.month - 1, date.day);
      this._maxDate = maxDate.getTime();
    }

    this.onValidatorChange();
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value && control.value.to ? control.value.to : control.value;

    if (control.errors && control.errors.hasOwnProperty('invalidDateFormat')) {
      return null;
    }

    if (!value || Number.isNaN(new Date(value).getTime())) {
      return null;
    }

    return new Date(value).getTime() <= this._maxDate
      ? // eslint-disable-next-line no-null/no-null
        null
      : { dateGreaterThanMaxDate: true };
  }

  ngOnDestroy(): void {
    this.maxDate = undefined;
  }
}
