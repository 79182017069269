import { Component } from '@angular/core';
// Please replace relative path imports e.g. '../*' with '@backbase/ui-ang'.
import { PaymentCardNumberFormat } from '@backbase/ui-ang/payment-card-number-pipe';
import { ProductItem } from '@backbase/ui-ang/product-selector';

@Component({
  selector: 'bb-product-item-credit-card-example-ui',
  templateUrl: './product-item-credit-card.example.component.html',
  standalone: false,
})
export class ProductItemCreditCardExampleComponent {
  product: ProductItem = {
    id: 'b387bfa5-75fd-410e-bb86-7f764f8ef69c',
    name: 'Ricardo Norton’s Credit Card',
    currency: 'USD',
    amount: '1425.99',
    productNumber: 'CH6629039MLXHC9VOO7M9',
  };

  interest: ProductItem = {
    id: 'd2d85314-e60c-44af-ba98-8f704dad5970',
    name: 'Available Balance',
    currency: 'USD',
    amount: '880.54',
    productNumber: 'CH6629039MLXHC9VOO7M9',
  };

  credit: ProductItem = {
    id: 'e9af649e-e338-4b3e-808b-62e9feae0865',
    name: 'Credit Limit',
    currency: 'USD',
    amount: '7280.86',
  };

  productDetails: { [key: string]: string } = {
    owner: 'Richard Norton',
  };

  productNumberFormat: PaymentCardNumberFormat = {
    length: 18,
    maskRange: [0, 0],
    segments: 4,
  };
}
