import { Component } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BackbaseDocs } from '@backbase/ui-ang/util';

interface Attachment {
  name: string;
  size: number;
  loading: boolean;
  deletable: boolean;
  disabled: boolean;
  fileContent: Observable<ArrayBuffer>;
}

const attachment: Attachment = {
  name: 'examplePDF.pdf',
  deletable: false,
  disabled: false,
  size: 10000,
  loading: false,
  fileContent: of(new ArrayBuffer(2000000)),
};

@Component({
  selector: 'bb-file-attachment-ui-showcase',
  templateUrl: 'file-attachment-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class FileAttachmentShowcaseComponent {
  defaultFileAttachmentsList: Attachment[] = [
    attachment,
    { ...attachment, name: 'exampleXLS.xls' },
    { ...attachment, name: 'exampleCSV.csv' },
    { ...attachment, name: 'examplePNG.png' },
    { ...attachment, name: 'exampleUnknown' },
  ];

  deletableFileAttachmentsList = this.defaultFileAttachmentsList.map((defaultAttachment: Attachment) => ({
    ...defaultAttachment,
    deletable: true,
    disabled: true,
  }));

  deleteAttachment(filesList: Attachment[], index: number) {
    filesList[index].loading = true;
    setTimeout(() => {
      filesList[index].loading = false;
      filesList.splice(index, 1);
    }, 1000);
  }
}
