import { Component, ContentChild, Directive, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { DomAttributesService } from '@backbase/ui-ang/services';

@Directive({
  selector: 'ng-template[bbCustomModalHeader]',
  standalone: false,
})
export class CustomModalHeaderDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'bb-modal-header-ui',
  templateUrl: './modal-header.component.html',
  standalone: false,
})
export class ModalHeaderComponent {
  @ContentChild(CustomModalHeaderDirective, { static: true }) customModalHeader: CustomModalHeaderDirective | undefined;

  readonly hostRef: ModalHeaderComponent = this;
  /**
   * Title text for the modal header. Defaults to empty string.
   */
  @Input() title = '';

  /**
   * Aria label for a "close" button
   */
  @Input() closeButtonAriaLabel = $localize`:@@bb-modal-header-ui.close-button.aria-label:Close modal window`;

  /**
   * Whether to display the close button.
   * Defaults to true.
   */
  @Input() showClose = true;

  /**
   * The event emitted when the modal header close button is clicked.
   */
  @Output() close = new EventEmitter();

  public readonly headerId = this.domAttrService.generateId();

  constructor(private readonly domAttrService: DomAttributesService) {}

  onClose() {
    this.close.emit();
  }
}
