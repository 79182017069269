import { Component } from '@angular/core';
import { PaymentCardNumberFormat } from '@backbase/ui-ang/payment-card-number-pipe';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-payment-card-number-pipe-ui-showcase',
  templateUrl: './payment-card-number-pipe-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class PaymentCardNumberPipeShowcaseComponent {
  get paymentCard() {
    return {
      name: 'Ricardo Peterson',
      number: '1234567891234567',
      vendor: 'mastercard',
      expirationDate: new Date(2021, 2),
    };
  }

  maskStart = 0;
  maskEnd = 12;
  _paymentCardNumberFormat = {};
  set paymentCardNumberFormat(val: PaymentCardNumberFormat) {
    this._paymentCardNumberFormat = val;
  }

  get paymentCardNumberFormat() {
    return {
      length: 16,
      maskRange: [this.maskStart, this.maskEnd],
      segments: 4,
    };
  }
}
