import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-mode-header-ui-showcase',
  templateUrl: './mode-header-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class ModeHeaderShowcaseComponent {
  private defaultModeHeader = {
    title: 'Close Button Displayed',
    secondaryTitle: undefined,
    showCloseButton: true,
    closeButtonAriaLabel: 'Close the mode header',
    reverseTitleOrder: true,
    displayIcon: false,
    displayStatus: false,
    displayUtilityArea: false,
    multiButtonUtilityArea: false,
  };

  modeHeaders = [
    {
      ...this.defaultModeHeader,
    },
    {
      ...this.defaultModeHeader,
      title: 'Close Button Hidden',
      showCloseButton: false,
      closeButtonAriaLabel: undefined,
    },
    {
      ...this.defaultModeHeader,
      title: 'Secondary title displayed',
      secondaryTitle: 'This is the secondary title',
    },
    {
      ...this.defaultModeHeader,
      title: 'Title order not reversed',
      secondaryTitle: 'This is the secondary title',
      reverseTitleOrder: false,
    },
    {
      ...this.defaultModeHeader,
      title: 'Icon displayed',
      displayIcon: true,
    },
    {
      ...this.defaultModeHeader,
      title: 'Status displayed',
      displayStatus: true,
    },
    {
      ...this.defaultModeHeader,
      title: 'Utility Area displayed',
      displayUtilityArea: true,
    },
    {
      ...this.defaultModeHeader,
      title: 'Utility Area with multiple buttons',
      multiButtonUtilityArea: true,
    },
  ];
}
