import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

export abstract class DropdownMenuOptionToken {
  active!: boolean;
  bbDropdownMenuOption!: string;
  selectionChange!: EventEmitter<DropdownMenuOptionDirective>;
}

@Directive({
  selector: '[bbDropdownMenuOption]',
  providers: [
    {
      provide: DropdownMenuOptionToken,
      useExisting: DropdownMenuOptionDirective,
    },
  ],
  standalone: false,
})
export class DropdownMenuOptionDirective extends DropdownMenuOptionToken {
  /**
   * value of the dropdown menuitem
   */
  @Input() bbDropdownMenuOption!: string;

  /**
   * Boolean value to add/remove the active class on menuitem
   *
   * @default false
   */
  @HostBinding('class.active') @Input() active = false;

  /**
   * Event that is emitted when user clicks on menuitem
   */
  @Output() selectionChange: EventEmitter<DropdownMenuOptionDirective> = new EventEmitter();

  @HostListener('click')
  @HostListener('keydown.enter')
  @HostListener('keydown.space')
  onSelect() {
    this.selectionChange.emit(this);
  }
}
