import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Directive,
  TemplateRef,
  ContentChild,
  AfterContentInit,
  Input,
  Self,
  Optional,
} from '@angular/core';
import { ControlContainer, UntypedFormGroup, NgControl } from '@angular/forms';

import { InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { ListMultipleSelectItems } from './list-multiple-select.model';

@Directive({
  selector: 'ng-template[bbListMultipleSelectAction]',
  standalone: false,
})
export class ListMultipleSelectActionDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: 'ng-template[bbListMultipleSelectItem]',
  standalone: false,
})
export class ListMultipleSelectItemDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * @name ListMultipleSelectComponent
 *
 * @description
 * Component that displays list with checkboxes.
 *
 * @deprecated Component is deprecated as of ui-ang v12. It will be removed in ui-ang v14. No replacement of this component as there is 0 usage of this component.
 */
@Component({
  selector: 'bb-list-multiple-select-ui',
  templateUrl: './list-multiple-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ListMultipleSelectComponent extends InputBaseComponent implements AfterContentInit {
  /**
   * Directive for adding custom action buttons to the multiple select list.
   *
   * @type {ListMultipleSelectActionDirective}
   * @memberof ListMultipleSelectComponent
   */
  @ContentChild(ListMultipleSelectActionDirective, { static: true })
  listMultipleSelectAction?: ListMultipleSelectActionDirective;

  /**
   * Directive for customizing the rendering of individual items in the multiple select list.
   *
   * @type {ListMultipleSelectItemDirective}
   * @memberof ListMultipleSelectComponent
   */
  @ContentChild(ListMultipleSelectItemDirective, { static: true })
  listMultipleSelectItem?: ListMultipleSelectItemDirective;

  /**
   * Configuration object for describing the icons to be used for each item in the multiple select list.
   *
   * @type {ListMultipleSelectItems}
   * @memberof ListMultipleSelectComponent
   */
  @Input() config?: ListMultipleSelectItems;

  /**
   * The FormGroup instance that the multiple select list is bound to.
   *
   * @type {UntypedFormGroup}
   * @memberof ListMultipleSelectComponent
   */
  control?: UntypedFormGroup;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    private readonly controlContainer: ControlContainer,
    @Self() @Optional() public parentFormControl: NgControl,
  ) {
    super(cd);
    if (this.parentFormControl) {
      this.parentFormControl.valueAccessor = this;
    }
  }

  ngAfterContentInit() {
    this.control = this.controlContainer.control as UntypedFormGroup;
  }

  // TODO: use keyvalue pipe instead when angular will be upgraded to v6.1
  getKeys(object: object): string[] {
    return Object.keys(object);
  }
}
