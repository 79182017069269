import { Component } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'bb-common-error-state-example-ui',
  templateUrl: './common-error-state.example.component.html',
  standalone: false,
})
export class CommonErrorStateExampleComponent {
  pageNotFound = new HttpErrorResponse({ status: 404 });
}
