import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { SortEvent, TableSortableDirective } from './table-sortable.directive';

/**
 * @name TableDirective
 *
 * @description
 * Directive that displays a table. Can be used with [CdkTable](https://material.angular.io/cdk/table/overview) to provide a powerful data-table solution.
 *
 * #### Sorting
 *
 * Sorting of table can be enabled by adding `[bbSortable]="'columnName'"` to column header. Additional you will need to implement handling of `sort` output.
 * Here is small example of how it may look like in template:
 *
 * ```typescript
 * <table [bbTable]="data" (sort)="onSort($event)">
 *  <thead>
 *    <tr>
 *      <th [bbSortable]="'columnName'">
 *        <span class="th-content">columnName</span>
 *      </th>
 *    </tr>
 *  </thead>
 * </table>
 * ```
 *
 * #### Focusable and clickable rows
 *
 * In order to achieve keyboard navigation over a table and add support for clickable rows you will need to add `bbTableFocus` to root table element and `[bbRow]="rowData"` to row elements.
 * Additionally `selectRow` and `rowClick` can be used to handle appropriate events.
 *
 * ```typescript
 * <table [bbTable]="data" bbTableFocus>
 *  <tbody>
 *    <tr [bbRow]="rowData">
 *      <td></td>
 *    </tr>
 *  </tbody>
 * </table>
 * ```
 *
 * #### a11y
 * The table directive doesn't provide any specific properties for accessibility.
 * The `aria-sort` tag can be set on the sortable header cells with ascending and descending values.
 *
 * #### Angular CDK Table
 *
 * The `CdkTable` provides an unopinionated, highly customizable data-table solution with a flexible templating API,
 * dynamic column management, and an accessible DOM structure. It can be seamlessly used alongside the `bbTable` directive
 * to combine CDK's powerful features with bbTable's functionality.
 * In order to add sorting to the table with `CdkTable`, you can use the `bbSortable` directive and follow steps in the _Sorting_ section.
 *
 * Key advantages of using Angular CDK tables:
 * - Dynamic column management: Add, remove, or replace columns through configuration
 * - Flexible templating system for custom cell rendering
 * - Built-in accessibility features
 * - Seamless integration with Angular's change detection
 *
 * Lets have a look at how to use `CdkTable` alongside `bbTable` directive:
 *
 * ```typescript
 * @Component({
 *   selector: 'bb-cdk-table-example-ui',
 *   imports: [
 *     TableModule,
 *     CdkTableModule,
 *     NgComponentOutlet,
 *     AsyncPipe,
 *     DecimalPipe,
 *   ],
 *   template: `
 *     <table
 *       cdk-table
 *       [bbTable]="dataSource | async"
 *       class="table table-hover"
 *       [dataSource]="dataSource"
 *     >
 *       <ng-container cdkColumnDef="name">
 *         <th cdk-header-cell *cdkHeaderCellDef>Country</th>
 *         <td cdk-cell *cdkCellDef="let element">
 *           {{ element.name }}
 *         </td>
 *       </ng-container>
 *
 *       <ng-container cdkColumnDef="area">
 *         <th cdk-header-cell *cdkHeaderCellDef>Area</th>
 *         <td cdk-cell *cdkCellDef="let element">
 *           {{ element.area | number }}
 *         </td>
 *       </ng-container>
 *
 *       <ng-container cdkColumnDef="population">
 *         <th cdk-header-cell *cdkHeaderCellDef>Population</th>
 *         <td cdk-cell *cdkCellDef="let element">
 *           {{ element.population | number }}
 *         </td>
 *       </ng-container>
 *
 *       <ng-container cdkColumnDef="flag">
 *         <th cdk-header-cell *cdkHeaderCellDef></th>
 *         <td cdk-cell *cdkCellDef="let element">
 *           <img [alt]="'Flag of ' + element.name" [src]="pathFlagAssets + element.flag" width="32" />
 *         </td>
 *       </ng-container>
 *
 *       <tr cdk-header-row *cdkHeaderRowDef="displayColumns"></tr>
 *       <tr cdk-row *cdkRowDef="let row; columns: displayColumns;"></tr>
 *     </table>
 *   `,
 *   standalone: true,
 * })
 * export class CdkTableExampleComponent {
 *   readonly displayColumns: string[] = ['name', 'area', 'population', 'flag'];
 *   readonly dataSource = new BehaviorSubject<CountryData[]>(countryData);
 *   readonly pathFlagAssets = 'https://upload.wikimedia.org/wikipedia/commons/';
 * }
 * ```
 *
 */
@Directive({
  selector: 'table[bbTable]',
  standalone: false,
})
export class TableDirective {
  /**
   * The data source for the table.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('bbTable') dataSource?: Array<Object>;
  /**
   * Emits an event when a row is selected.
   */
  @Output() selectRow = new EventEmitter();
  /**
   * Emits an event when a row is clicked.
   * An sample implementation could be:
   *
   * ```typescript
   * onRowClick(value: unknown) { ..... }
   *
   * This will require `[bbRow]="rowData"` to table row and `[bbTableFocus]` to root table element.
   * ```
   */
  @Output() rowClick = new EventEmitter();
  /**
   * An event emitter for on sort actions.
   * An sample implementation could be:
   *
   * ```typescript
   * onSort({ column, direction }: SortEvent) { ..... }
   * ```
   *
   * This will require you to add `[bbSortable]="'columnName'"` to column header.
   */
  @Output() sort = new EventEmitter();

  selectedRows: Set<Object> = new Set();
  headers: Set<TableSortableDirective> = new Set();

  registerHeader(header: TableSortableDirective) {
    this.headers.add(header);
  }

  unRegisterHeader(header: TableSortableDirective) {
    this.headers.delete(header);
  }

  onRowClick(rowItem: any) {
    this.rowClick.emit(rowItem);
  }

  isSelected(row: any): boolean {
    return this.selectedRows.has(row);
  }

  toggleItem(row: any, isChecked: boolean) {
    if (isChecked) {
      this.selectedRows.add(row);
    } else {
      this.selectedRows.delete(row);
    }
    this.selectRow.emit(this.selectedRows);
  }

  toggleAllItems() {
    if (this.isSelectedAll()) {
      this.selectedRows = new Set();
    } else {
      this.selectedRows = new Set(this.dataSource);
    }
    this.selectRow.emit(this.selectedRows);
  }

  isSelectedAll(): boolean {
    if (this.dataSource) {
      const matches = this.dataSource.filter((item) => this.selectedRows.has(item));

      return matches.length === this.dataSource.length;
    }

    return false;
  }

  isSelectedPart(): boolean {
    if (this.dataSource) {
      return this.selectedRows.size > 0 && this.selectedRows.size < this.dataSource.length;
    }

    return false;
  }

  onSort(sortEvent: SortEvent) {
    this.headers.forEach((header) => {
      if (header.bbSortable !== sortEvent.column) {
        header.direction = null;
        header.markAllMenuOptionAsInactive();
      }
    });
    this.sort.emit(sortEvent);
  }
}
