import { Component, OnInit } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-amount-ui-showcase',
  templateUrl: './amount-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class AmountShowcaseComponent implements OnInit {
  asyncCurrency = '';
  ngOnInit() {
    setTimeout(() => {
      this.asyncCurrency = 'USD';
    }, 2000);
  }
}
