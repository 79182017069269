import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Optional, Self } from '@angular/core';
import { InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { NgControl } from '@angular/forms';
import { DomAttributesService } from '@backbase/ui-ang/services';
import { idListAttr } from '@backbase/ui-ang/util';

/**
 * @name TextareaComponent
 *
 * ### Accessibility
 * Current component provide option to pass needed accessibility
 * attributes. You need to take care of properties that are required in your case :
 *  - role
 *  - aria-activedescendant
 *  - aria-describedby
 *  - aria-expanded
 *  - aria-invalid
 *  - aria-label
 *  - aria-labelledby
 *  - aria-owns
 *
 * @description
 * Component that displays a text input.
 */
@Component({
  selector: 'bb-textarea-ui',
  templateUrl: './textarea.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TextareaComponent extends InputBaseComponent {
  /**
   * The number of rows to display in the textarea.
   */
  @Input() rows?: number;
  /**
   * The number of columns to display in the textarea.
   */
  @Input() cols?: number;
  /**
   * The placeholder for the text input. Defaults to an empty string.
   */
  @Input() placeholder = '';
  /**
   * The maxLength for the text input.
   */
  @Input() maxLength?: number;
  /**
   * The minLength for the text input.
   */
  @Input() minLength?: number;
  /**
   * Whether the text input is readonly.
   */
  @Input() readonly = false;
  /**
   * Shows character counter based on `maxLength`.
   */
  @Input() showCharCounter = false;
  /**
   * Indicates how the control wraps text.
   */
  @Input() wrap?: 'soft' | 'hard';

  /**
   * The ID of the character counter element.
   */
  public charCounterId: string;

  /**
   * Utility function for use in template
   */
  public idListAttr = idListAttr;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    private domAttributesService: DomAttributesService,
    @Optional() @Self() public parentFormControl: NgControl,
  ) {
    super(cd);
    this.charCounterId = this.domAttributesService.generateId();

    if (this.parentFormControl) {
      this.parentFormControl.valueAccessor = this;
    }
  }
}
