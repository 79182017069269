import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

export const DEFAULT_POSITIVE_CLASS = 'bb-amount--positive';
export const DEFAULT_NEGATIVE_CLASS = 'bb-amount--negative';

/**
 * @name HighlightDirective
 *
 * @description
 * Directive that encapsulate logic for highlighting positive/negative numbers.
 * Example usage with bb-amount-ui (AmountModule)
 */
@Directive({
  selector: '[bbHighlight]',
  standalone: false,
})
export class HighlightDirective implements OnChanges {
  /**
   * Setter (Input) for value of highlighted number
   *
   * @param amount Value of highlighted number
   */
  @Input()
  set amount(amount: string | number) {
    this.amountValue = typeof amount === 'number' ? amount : parseFloat(amount);
  }

  /**
   * Setter (Input) that turn on/off highlight
   *
   * @param value Highlight flag
   */
  @Input()
  set bbHighlight(value: boolean | '') {
    this.highlightValue = value === '' || value;
  }

  /**
   * The class for positive value.
   */
  @Input() negativeClass = DEFAULT_NEGATIVE_CLASS;
  /**
   * The class for negative value.
   */
  @Input() positiveClass = DEFAULT_POSITIVE_CLASS;

  private amountValue = 0;
  private highlightValue = true;

  constructor(
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
  ) {}

  private get isPositive() {
    return this.amountValue >= 0;
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.negativeClass) {
      this.renderer.removeClass(this.elementRef.nativeElement, change.negativeClass.previousValue);
    }

    if (change.positiveClass) {
      this.renderer.removeClass(this.elementRef.nativeElement, change.positiveClass.previousValue);
    }

    if (change.amount || change.highlight || change.bbHighlight) {
      this.renderer.removeClass(this.elementRef.nativeElement, this.negativeClass);
      this.renderer.removeClass(this.elementRef.nativeElement, this.positiveClass);
    }

    if (this.highlightValue) {
      const highlight = this.isPositive ? this.positiveClass : this.negativeClass;
      this.renderer.addClass(this.elementRef.nativeElement, highlight);
    }
  }
}
