import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[bbDigitOnly]',
  standalone: false,
})
export class DigitOnlyDirective {
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];
  inputElement: HTMLInputElement;

  @Input('bbDigitOnly')
  isEnabled = true;

  @Input()
  allowNegativeSign = false;

  previousKey: string | undefined;

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  /* eslint-disable complexity */
  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    const array = ['KeyA', 'KeyC', 'KeyV', 'KeyX'];

    if (this.navigationKeys.indexOf(e.key) > -1 || (array.includes(e.code) && (e.ctrlKey || e.metaKey))) {
      this.previousKey = e.key;

      return;
    }

    const matchPatternItems = ['\\d'];
    if (!this.isEnabled) {
      matchPatternItems.push(...['\\.', ',']);
    }

    if (this.allowNegativeSign) {
      matchPatternItems.unshift('-');
    }
    const matchPattern = new RegExp(`(${matchPatternItems.join('|')})`);

    const preventEventDefault =
      e.key !== 'ArrowUp' && // used for increasing number for keyboard-only users
      e.key !== 'ArrowDown' && // used for decreasing number for keyboard-only users
      // it is other than pattern
      (!matchPattern.test(e.key) ||
        // try to add negative when there is already a value
        (e.key === '-' && this.inputElement.value.indexOf('-') > -1) ||
        // try to add negative sign when you hit negative sign just before
        (e.key === '-' && this.previousKey === '-'));

    if (!this.inputElement.value && this.previousKey !== '-' && this.previousKey !== ',' && this.previousKey !== '.') {
      this.inputElement.value = '';
    }

    this.previousKey = e.key;

    if (preventEventDefault) {
      e.preventDefault();
    }
  }
}
