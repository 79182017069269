import { Component } from '@angular/core';

interface SnoozeDurationExample {
  label: string;
  duration: number;
}

@Component({
  selector: 'bb-dropdown-menu-example-ui',
  templateUrl: './dropdown-menu.example.component.html',
  standalone: false,
})
export class DropdownMenuExampleComponent {
  dropdownMenuOptions: SnoozeDurationExample[] = [
    { label: '30 minutes', duration: 1800 },
    { label: '1 hour', duration: 3600 },
    { label: '1 day', duration: 86400 },
  ];

  dropdownMenuOptionsLabel = this.dropdownMenuOptions.map(
    (item) => item.label,
  );

  onButtonDropdownSelect(label: string) {
    const item = this.dropdownMenuOptions.find(
      (option) => option.label === label,
    );

    if (item) {
      this.snooze(item.duration);
    }
  }

  snooze(duration: number) {
    alert(`Snooze for ${duration} seconds`);
  }
}
