import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ComponentMetaDataService } from '../../../services/component-meta-data.service';
import { ShowcaseService, MenuComponent } from '../../../services/showcase.service';

type Platform = 'androidCompose' | 'androidXML' | 'iosSwiftUI' | 'iosUIKit' | 'design';
enum Platforms {
  ANDROID_COMPOSE = 'androidCompose',
  ANDROID_XML = 'androidXML',
  IOS_SWIFTUI = 'iosSwiftUI',
  IOS_UIKIT = 'iosUIKit',
  DESIGN = 'design',
}

@Component({
  selector: 'bb-mobile-showcase',
  templateUrl: './mobile-showcase.component.html',
  standalone: false,
})
export class MobileShowcaseComponent implements OnInit {
  tabsShown: Platform[] = [];
  activeComponent = '';

  constructor(
    private showcaseService: ShowcaseService,
    private componentMetadataService: ComponentMetaDataService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      this.setActiveComponent(paramMap);
    });
  }
  setActiveComponent(paramMap: any) {
    this.activeComponent = paramMap.params.id;
    const component = this.showcaseService.getMobileComponent(this.activeComponent);
    const isAndroidComponent = this.componentMetadataService.isAndroidComponent(this.activeComponent);
    const isIOSComponent = this.componentMetadataService.isIOSComponent(this.activeComponent);

    this.updateAvailableTabs(component, !!isAndroidComponent, !!isIOSComponent);
    this.handleRouting(component, !!isAndroidComponent, !!isIOSComponent);
  }

  private updateAvailableTabs(
    component: MenuComponent | undefined,
    isAndroidComponent: boolean,
    isIOSComponent: boolean,
  ): void {
    this.tabsShown = [];

    this.addAndroidTabs(component, isAndroidComponent);
    this.addIOSTabs(component, isIOSComponent);

    if (component?.designGuidelinesPath) {
      this.tabsShown.push(Platforms.DESIGN);
    }
  }

  private addAndroidTabs(component: MenuComponent | undefined, isAndroidComponent: boolean): void {
    if (!isAndroidComponent) return;

    if (component?.androidCompose) {
      this.tabsShown.push(Platforms.ANDROID_COMPOSE);
    }
    if (component?.androidXML) {
      this.tabsShown.push(Platforms.ANDROID_XML);
    }
  }

  private addIOSTabs(component: MenuComponent | undefined, isIOSComponent: boolean): void {
    if (!isIOSComponent) return;

    if (component?.iosSwiftUI) {
      this.tabsShown.push(Platforms.IOS_SWIFTUI);
    }
    if (component?.iosUIKit) {
      this.tabsShown.push(Platforms.IOS_UIKIT);
    }
  }

  private handleRouting(
    component: MenuComponent | undefined,
    isAndroidComponent: boolean,
    isIOSComponent: boolean,
  ): void {
    if (this.isPlatformInUrl()) return;

    const defaultRoute = this.getDefaultRoute(component, isAndroidComponent, isIOSComponent);
    this.router.navigate([`${defaultRoute}`], { relativeTo: this.route });
  }

  private isPlatformInUrl(): boolean {
    return (
      this.router.url.includes(Platforms.ANDROID_COMPOSE) ||
      this.router.url.includes(Platforms.ANDROID_XML) ||
      this.router.url.includes(Platforms.IOS_SWIFTUI) ||
      this.router.url.includes(Platforms.IOS_UIKIT) ||
      this.router.url.includes(Platforms.DESIGN)
    );
  }

  private getDefaultRoute(
    component: MenuComponent | undefined,
    isAndroidComponent: boolean,
    isIOSComponent: boolean,
  ): Platform | '' {
    if (isIOSComponent) {
      return component?.iosUIKit ? Platforms.IOS_UIKIT : Platforms.IOS_SWIFTUI;
    }
    if (isAndroidComponent) {
      return component?.androidXML ? Platforms.ANDROID_XML : Platforms.ANDROID_COMPOSE;
    }

    return '';
  }
}
