import { Component } from '@angular/core';
// Please replace relative path imports e.g. '../*' with '@backbase/ui-ang'.
import { ProductItem } from '@backbase/ui-ang/product-selector';
import { PaymentCardNumberFormat } from '@backbase/ui-ang/payment-card-number-pipe';

@Component({
  selector: 'bb-product-item-term-deposit-example-ui',
  templateUrl: './product-item-term-deposit.example.component.html',
  standalone: false,
})
export class ProductItemTermDepositExampleComponent {
  product: ProductItem = {
    id: 'b387bfa5-75fd-410e-bb86-7f764f8ef69c',
    name: 'Ricardo Norton`s Account',
    currency: 'EUR',
    amount: '83652.32',
    productNumber: 'NL75INGB3400120056',
  };

  interest: ProductItem = {
    id: 'd2d85314-e60c-44af-ba98-8f704dad5970',
    name: 'Accrued Interest',
    currency: 'EUR',
    amount: '880.54',
    productNumber: 'NL75INGB3400120056',
  };

  productDetails: { [key: string]: string } = {
    owner: 'Richard Norton',
  };

  productNumberFormat: PaymentCardNumberFormat = {
    length: 18,
    maskRange: [0, 12],
    segments: 4,
  };
}
