import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-input-password-ui-showcase',
  templateUrl: './input-password-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class InputPasswordShowcaseComponent {
  passwordForm: UntypedFormGroup;

  constructor(private readonly formBuilder: UntypedFormBuilder) {
    this.passwordForm = this.formBuilder.group({
      inputPassword: ['', Validators.required],
      inputPasswordNoIcon: ['', Validators.required],
    });
  }

  onSubmit(form: UntypedFormGroup) {
    console.log(form.value.inputPassword);
  }

  isFieldValid(fieldName: string) {
    const control = this.passwordForm?.controls[fieldName];

    return control && (control.valid || control.untouched);
  }
}
