import { Directive, ElementRef, HostBinding, HostListener, Input, Renderer2 } from '@angular/core';
import { TableDirective } from './table.directive';

@Directive({
  selector: 'tr[bbRow]',
  standalone: false,
})
export class TableRowDirective {
  /**
   * The data of the row element
   */
  @Input() bbRow: any;

  @HostBinding('class.selected')
  get selected() {
    return this.parentTable.isSelected(this.bbRow);
  }

  constructor(
    private readonly parentTable: TableDirective,
    private readonly renderer: Renderer2,
  ) {}

  @HostListener('click')
  onRowClick() {
    this.parentTable.onRowClick(this.bbRow);
  }

  @HostListener('focus', ['$event.target'])
  onFocus(el: ElementRef) {
    this.renderer.addClass(el, 'active');
  }

  @HostListener('blur', ['$event.target'])
  onBlur(el: ElementRef) {
    this.renderer.removeClass(el, 'active');
  }
}
