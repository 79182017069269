import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-dropdown-panel-ui-showcase',
  templateUrl: './dropdown-panel-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class DropdownPanelShowcaseComponent {}
