import { Component, OnDestroy } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Subject, filter, map, takeUntil } from 'rxjs';
import { ShowcaseService } from '../../../../services/showcase.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'bb-showcase-storybook',
  templateUrl: './showcase-storybook.component.html',
  standalone: false,
})
export class ShowcaseStorybookComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private showcaseService: ShowcaseService,
  ) {}

  componentStoryUrl$ = this.route.paramMap.pipe(
    map((params) => params.get('id') || ''),
    filter(Boolean),
    map((id: string) => {
      return this.showcaseService.getComponent(id);
    }),
    map((menuComponent) => {
      const { storybook, storybookParentPath } = menuComponent ?? {};

      return `${environment.storybookOrigin}/?path=/story/${storybookParentPath ?? 'design-system'}-${storybook ?? ''}--docs&full=1&shortcuts=false&singleStory=true`;
    }),
    takeUntil(this.destroy$),
  );

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
