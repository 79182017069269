import { Pipe, PipeTransform } from '@angular/core';
import { AlertType } from './alert.constants';

@Pipe({
  name: 'alertIcon',
  standalone: false,
})
export class AlertIconPipe implements PipeTransform {
  transform(alertModifier: AlertType) {
    switch (alertModifier) {
      case 'error':
        return 'error-outline';

      case 'warning':
        return 'warning-amber';

      default:
        return alertModifier;
    }
  }
}
