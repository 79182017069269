import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export interface UserItem {
  id: number;
  name: string;
}

@Component({
  selector: 'bb-search-box-example-ui',
  templateUrl: './search-box.example.component.html',
  standalone: false,
})
export class SearchBoxExampleComponent {
  form = new UntypedFormGroup({
    query: new UntypedFormControl(''),
  });

  startSearching(value: string) {
    alert(`You are searching for ${value}`);
  }

  search(text: Observable<string>): Observable<Array<string>> {
    const searchItems = ['John', 'Jane', 'James', 'Joey'];

    return text.pipe(
      map((term) =>
        searchItems.filter(
          (item) =>
            item.toLowerCase().indexOf(term.toLowerCase()) === 0,
        ),
      ),
    );
  }
  readonly typeaheadOptions = {
    ngbTypeahead: this.search,
  };
}
