import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';
import { isRtl } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-date-pipe-ui-showcase',
  templateUrl: 'date-pipe-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class DatePipeShowcaseComponent {
  public dateToTransform = new Date('Wed Dec 3 2018 15:29:14 GMT+0100 (Central European Standard Time)');
}
