import { Component, OnChanges, OnInit, Input, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';

/**
 * The prefix for the vendor class name.
 */
const classNamePrefix = 'bb-card-vendor-';

const vendorToClassName = (vendor: string | undefined): string => {
  if (typeof vendor !== 'string') {
    return '';
  }

  const classNamePostfix = vendor.trim().toLowerCase().replace(/\W/g, '-').replace(/-{2,}/g, '-');

  return `${classNamePrefix}${classNamePostfix}`;
};

/**
 * @name CardVendorComponent
 *
 * @description
 * Component that displays the logo of a card vendor.
 */
@Component({
  selector: 'bb-card-vendor-ui',
  templateUrl: './card-vendor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CardVendorComponent implements OnChanges, OnInit {
  /**
   * The vendor that this component displays the logo of.  Mandatory.
   */
  @Input() vendor: string | undefined;

  className = '';

  ngOnInit() {
    if (!this.vendor) {
      throw new Error(`"vendor" input is required in "${this.constructor.name}"`);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.className = vendorToClassName(this.vendor);
  }
}
