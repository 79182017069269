import { Component, Input, OnChanges } from '@angular/core';
import { BadgeColor } from '@backbase/ui-ang/badge';

/**
 * @name BadgeCounterComponent
 *
 * @description
 * Component that displays a counter badge.
 */
@Component({
  selector: 'bb-badge-counter-ui',
  templateUrl: './badge-counter.component.html',
  standalone: false,
})
export class BadgeCounterComponent implements OnChanges {
  /**
   * The (background) color of the badge. Defaults to 'primary'.
   */
  @Input() color: BadgeColor = 'primary';
  /**
   * The count to show in the badge. Defaults to 0 and badge will be hidden if count equals 0.
   */
  @Input() count = 0;
  /**
   * The max count value to limit text inside badge. Defaults to 999.
   */
  @Input() maxCount = 999;

  countValue: string | undefined;

  ngOnChanges() {
    const value =
      this.maxCount && this.maxCount > 0 && this.count && this.maxCount < this.count ? `${this.maxCount}+` : this.count;

    this.countValue = value ? value.toString() : undefined;
  }
}
