import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  Validators,
  NgForm,
  UntypedFormGroup,
} from '@angular/forms';

@Component({
  selector: 'bb-input-radio-group-example-ui',
  templateUrl: './input-radio-group.example.component.html',
  standalone: false,
})
export class InputRadioGroupExampleComponent {
  form: UntypedFormGroup = this.formBuilder.group({
    accountType: [{ value: '', disabled: false }, Validators.required],
  });

  constructor(private formBuilder: UntypedFormBuilder) {}

  onRadioTemplateFormSubmit(form: NgForm) {
    console.log(form.value);
  }
}
