import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardVendorTitle',
  standalone: false,
})
export class CardVendorTitlePipe implements PipeTransform {
  transform(vendor: string) {
    return vendor.trim().toLowerCase().replace(/-/g, ' ');
  }
}
