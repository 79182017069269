import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'bb-input-file-example-ui',
  templateUrl: './input-file.example.component.html',
  standalone: false,
})
export class InputFileExampleComponent {
  vForm: UntypedFormGroup | undefined;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.vForm = this.formBuilder.group({
      fileInput: undefined,
    });
  }
}
