import { NgModule } from '@angular/core';

import { CharCounterComponent } from './char-counter.component';

/**
 * @name CharCounterModule
 */
@NgModule({
  declarations: [CharCounterComponent],
  exports: [CharCounterComponent],
})
export class CharCounterModule {}
