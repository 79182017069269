import { Component } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'bb-input-range-example-ui',
  templateUrl: './input-range.example.component.html',
  standalone: false,
})
export class InputRangeExampleComponent {
  form = this.formBuilder.group({
    range: this.formBuilder.group(
      {
        min: [0, Validators.min(10)],
        max: [100, Validators.max(90)],
      },
      {
        validator: (g: UntypedFormGroup) => {
          const min = (g.get('min') as UntypedFormControl).errors;
          const max = (g.get('max') as UntypedFormControl).errors;

          const errors = min || max ? { ...min, ...max } : null;

          return errors;
        },
      },
    ),
  });

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  isFieldValid(control: FormControl) {
    return control && (control.valid || control.untouched);
  }
}
