import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';
import { InputInlineEditState } from '@backbase/ui-ang/input-inline-edit';

@Component({
  selector: 'bb-input-inline-edit-ui-showcase',
  templateUrl: './input-inline-edit-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class InputInlineEditShowcaseComponent {
  editInlineModel = 'Here some text that can be modified';
  editInlineModelWithCounter = 'An input with a counter';
  editInlineModelWithLoading = 'Default text';
  editInlineModelCurrency = '400';
  inlineEditState: InputInlineEditState = InputInlineEditState.IDLE;

  onCancel(): void {}

  onAccept(item: string): void {
    this.editInlineModel = item;
  }

  onAcceptWithCounter(item: string): void {
    this.editInlineModelWithCounter = item;
  }

  onAcceptWithLoading(value: string): void {
    this.inlineEditState = InputInlineEditState.LOADING;

    setTimeout(() => {
      this.inlineEditState = InputInlineEditState.IDLE;
      this.editInlineModelWithLoading = value;
    }, 5000);
  }

  onAcceptCurrency(value: string): void {
    this.editInlineModelCurrency = value;
  }
}
