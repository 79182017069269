import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputBaseComponent } from '@backbase/ui-ang/base-classes';
/* eslint-disable */
enum position {
  left = 'left',
  right = 'right',
}

export type LabelPosition = 'left' | 'right';

/**
 * @name SwitchComponent
 *
 * @description
 * Component that provides you a switch input.
 */
@Component({
  selector: 'bb-switch-ui',
  templateUrl: './switch.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SwitchComponent),
    },
  ],
  standalone: false,
})
export class SwitchComponent extends InputBaseComponent implements OnInit {
  /**
   * Specifies the label position of the switch component.
   * Defaults to left.
   */
  @Input() labelPosition: LabelPosition = position.left;

  focused = false;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    private el: ElementRef,
  ) {
    super(cd);
  }

  ngOnInit(): void {
    this.role = this.role || 'switch';
  }

  toggleSwitch(): void {
    this.onValueChange(!this.value);
    this.el.nativeElement.dispatchEvent(new Event('change', { bubbles: true }));
  }

  onBlur($event: FocusEvent) {
    this.focused = false;
    super.onBlur($event);
  }

  isLabelLeft(): boolean {
    return this.labelPosition === position.left;
  }

  isLabelRight(): boolean {
    return this.labelPosition === position.right;
  }
}
