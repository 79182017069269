import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-input-number-ui-showcase',
  templateUrl: './input-number-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class InputNumberShowcaseComponent {
  vForm: UntypedFormGroup | undefined;
  constructor(private readonly formBuilder: UntypedFormBuilder) {
    this.vForm = this.formBuilder.group({
      inputNumber: ['', Validators.compose([Validators.required, Validators.min(2), Validators.max(20)])],
      inputNumberOnlyDigits: ['', Validators.compose([Validators.required, Validators.min(2), Validators.max(20)])],
      inputNumberNegative: ['', Validators.compose([Validators.required, Validators.min(-25), Validators.max(20)])],
      inputNumberWithAreaLabelledBy: [
        '',
        Validators.compose([Validators.required, Validators.min(2), Validators.max(20)]),
      ],
    });
  }
  isFieldValid(fieldName: string) {
    const control = this.getControl(fieldName);

    return control && (control.valid || control.untouched);
  }

  private getControl(fieldName: string) {
    return this.vForm?.controls[fieldName];
  }

  hasError(field: string, type: string) {
    const fieldControl = this.getControl(field);
    if (!fieldControl || !fieldControl.errors) {
      return;
    }

    return fieldControl.errors[type];
  }

  hasRequiredError(field: string) {
    const fieldControl = this.getControl(field);
    if (!fieldControl || !fieldControl.errors) {
      return;
    }

    return fieldControl.errors.required;
  }

  hasMinError(field: string) {
    return this.hasError(field, 'min');
  }

  hasMaxError(field: string) {
    return this.hasError(field, 'max');
  }
}
