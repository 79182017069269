import { Component, Inject } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';
import {
  AccountNumberType,
  ACCOUNTS_DISPLAYING_FORMAT,
  AccountsDisplayingFormat,
  AccountsDisplayingFormatConfiguration,
} from '@backbase/ui-ang/account-number-pipe';
import { BehaviorSubject, asyncScheduler } from 'rxjs';

interface AccountShowCase {
  description: string;
  typeOrFormat?: AccountNumberType | AccountsDisplayingFormat;
  unMaskedNumber: string;
  maskedNumber: string;
  number: string;
  cardProviderName?: string;
  masked$: BehaviorSubject<boolean>;
}

interface ArrangementShowCase {
  account: {
    IBAN?: string;
    BBAN?: string;
    number?: string;
    productKindName?: string;
    maskedNumber?: string;
    unMaskedNumber?: string;
  };
  masked$: BehaviorSubject<boolean>;
  description: string;
  unmaskable: boolean;
}

@Component({
  selector: 'bb-account-number-ui-showcase',
  templateUrl: './account-number-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class AccountNumberShowcaseComponent {
  private readonly customConfiguration: AccountsDisplayingFormat = { segments: [1, 2, 3, 1, 2, 3, 1, 2, 3] };
  arrangementItems: ArrangementShowCase[] = [
    {
      account: {
        IBAN: 'GB29NWBK60161331926819',
        BBAN: 'BBAN00001',
        number: '1234',
        productKindName: 'Current Account',
        maskedNumber: '****************6819', // Only for demo. In actual, should come from backend api.
        unMaskedNumber: 'GB29NWBK60161331926819',
      },
      masked$: new BehaviorSubject<boolean>(false),
      description: 'With account having iban, bban and number',
      unmaskable: true,
    },
    {
      account: {
        BBAN: 'BBAN00001',
        number: '1234',
        productKindName: 'Current Account',
        maskedNumber: '*****0001',
        unMaskedNumber: 'BBAN00001',
      },
      masked$: new BehaviorSubject<boolean>(false),
      description: 'With account having bban and number',
      unmaskable: true,
    },
    {
      account: {
        number: '1234',
        maskedNumber: '*****',
        unMaskedNumber: '1234',
        productKindName: 'Current Account',
      },
      masked$: new BehaviorSubject<boolean>(false),
      description: 'With account having number',
      unmaskable: true,
    },
    {
      account: {
        IBAN: 'GB29NWBK60161331926819',
        BBAN: 'BBAN00001',
        number: '1234',
        productKindName: 'Current Account',
      },
      masked$: new BehaviorSubject<boolean>(false),
      description: 'With unmaskable set to false',
      unmaskable: false,
    },
  ];

  accounts: AccountShowCase[] = [
    {
      description: 'iban',
      typeOrFormat: 'iban',
      unMaskedNumber: 'IBAN1234567891234567',
      number: 'IBAN1234567891234567',
      maskedNumber: '****************4567',
      masked$: new BehaviorSubject<boolean>(false),
    },
    {
      description: 'bban',
      typeOrFormat: 'bban',
      unMaskedNumber: 'BBAN1234567891234567',
      number: 'BBAN1234567891234567',
      maskedNumber: '****************4567',
      masked$: new BehaviorSubject<boolean>(false),
    },
    {
      description: 'cardNumber',
      typeOrFormat: 'cardNumber',
      unMaskedNumber: 'num1234567891234567',
      maskedNumber: '****************4567',
      number: 'num1234567891234567',
      masked$: new BehaviorSubject<boolean>(false),
    },
    {
      description: 'cardNumber (visa provider)',
      typeOrFormat: 'cardNumber',
      unMaskedNumber: 'num1234567891234567',
      maskedNumber: '****************4567',
      number: 'num1234567891234567',
      cardProviderName: 'Visa',
      masked$: new BehaviorSubject<boolean>(false),
    },
    {
      description: `Custom format [${this.customConfiguration.segments}]`,
      typeOrFormat: this.customConfiguration,
      unMaskedNumber: 'num1234567891234567',
      maskedNumber: '****************4567',
      number: 'num1234567891234567',
      masked$: new BehaviorSubject<boolean>(false),
    },
  ];

  onMask(account: AccountShowCase) {
    asyncScheduler.schedule((_) => {
      account.number = account.maskedNumber;
      account.masked$.next(true);
    });
  }

  onUnMask(account: AccountShowCase) {
    asyncScheduler.schedule((_) => {
      account.number = account.unMaskedNumber;
      account.masked$.next(false);
    });
  }

  onAccountMask(arrangementItem: ArrangementShowCase) {
    const { account, masked$ } = arrangementItem;
    asyncScheduler.schedule((_) => {
      if (account.IBAN) {
        account.IBAN = account.maskedNumber;
      } else if (account.BBAN) {
        account.BBAN = account.maskedNumber;
      } else {
        account.number = account.maskedNumber;
      }
      masked$.next(true);
    });
  }

  onAccountUnMask(arrangementItem: ArrangementShowCase) {
    const { account, masked$ } = arrangementItem;
    asyncScheduler.schedule((_) => {
      if (account.IBAN) {
        account.IBAN = account.unMaskedNumber;
      } else if (account.BBAN) {
        account.BBAN = account.unMaskedNumber;
      } else {
        account.number = account.unMaskedNumber;
      }
      masked$.next(false);
    });
  }

  constructor(@Inject(ACCOUNTS_DISPLAYING_FORMAT) readonly configuration: AccountsDisplayingFormatConfiguration) {}
}
