import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'bb-showcase-scss-tokens',
  templateUrl: './showcase-scss-tokens.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ShowcaseScssTokensComponent {
  @Input() scssTokensHtml = '';
}
