import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'bb-amount-input-example-ui',
  templateUrl: './amount-input.example.component.html',
  standalone: false,
})
export class AmountInputExampleComponent {
  vForm: UntypedFormGroup = this.formBuilder.group({
    name: ['', Validators.required],
  });

  constructor(private formBuilder: UntypedFormBuilder) {}
}
