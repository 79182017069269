import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { noop } from 'rxjs';

let _nextId = 0;

export function getInputNextId() {
  return `bb_input_${_nextId++}`;
}

export const defaultSize = 20;

/**
 * Widget roles specified in ARIA 1.1
 */
const VALID_WIDGET_ROLES = [
  'button',
  'checkbox',
  'combobox',
  'grid',
  'gridcell',
  'link',
  'listbox',
  'menu',
  'menubar',
  'menuitem',
  'menuitemcheckbox',
  'menuitemradio',
  'option',
  'progressbar',
  'radio',
  'radiogroup',
  'scrollbar',
  'searchbox',
  'separator',
  'slider',
  'spinbutton',
  'switch',
  'tab',
  'tablist',
  'tabpanel',
  'textbox',
  'tree',
  'treegrid',
  'treeitem',
];

/**
 * @name InputBaseComponent
 *
 * @description
 * Base component for input fields.
 *
 * ### Accessibility
 * Current component provide option to pass needed accessibility
 * attributes to custom components
 */
@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InputBaseComponent implements ControlValueAccessor {
  private _id = getInputNextId();
  private _labelClasses: string | undefined;

  onTouched: () => void = noop;
  /**
   * The id for the input. Defaults to unique string.
   * Used to map the label to the input.
   */
  @Input()
  set id(value: string) {
    this._id = value;
  }

  get id(): string {
    return this._id;
  }

  /**
   * The label for the input. Defaults to an empty string.
   */
  @Input() label = '';

  private _role: string | undefined;

  /**
   * Customize the ARIA role for the HTML input/select/textarea element inside this component.
   *
   * This can be used to improve accessibility for components, for example by configuring `[role]="'combobox'"`
   * for a component that provides an autocomplete list.
   *
   * Values that are valid for the native HTML form elements are allowed.
   */
  @Input()
  get role(): string | undefined {
    return this._role;
  }

  set role(value: string | undefined) {
    if (typeof value === 'string' && VALID_WIDGET_ROLES.indexOf(value) === -1) {
      // Prevent setting invalid roles
      // TODO: Log a warning about the invalid role
      value = undefined;
    }

    this._role = value;
  }

  protected _size: number = defaultSize;

  /**
   * Configure the minimum width to fit the specified number of characters that should fit for HTML `<input>`
   */
  @Input() get size(): number | string {
    return this._size;
  }

  set size(arg: number | string) {
    const size = this.getSizeByKeyword(arg);

    // Optionally convert a `size` keyword to an integer value.
    // This can be supported by subclasses of `InputBaseComponent`
    arg = typeof size !== 'undefined' ? size : arg;

    const int = parseInt(String(arg), 10);

    if (int >= 1 && !isNaN(int)) {
      this._size = int;
    }
  }

  /**
   * Class names that must be included on the `<input>` form control
   */
  @Input() inputClassName?: string;
  /**
   * It indicates whether inputting text could trigger display of one or more
   * predictions of the user's intended value for a combobox, searchbox, or textbox.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-autocomplete') ariaAutocomplete?: string;

  /**
   * It identifies the currently active element when focus is on a
   * composite widget, combobox, textbox, group, or application.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-activedescendant') ariaActivedescendant?: string;

  /**
   * When an interactive or input control has an impact on another element in a document or application,
   * the aria-control indicates which element or elements the user interface widget controls.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-controls') ariaControls?: string;

  /**
   * Set aria-describedby  with an element id that contains a detailed decription of the widget.
   * It is used to establish a relationship between widgets or groups and the text that describes them.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') ariaDescribedby?: string;

  /**
   * The aria-expanded attribute is set on an element to indicate if a control is expanded or collapsed,
   * and whether or not its child elements are displayed or hidden.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-expanded') ariaExpanded?: string;

  /**
   * The aria-invalid state indicates the entered value is not in a format expected by the application.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-invalid') ariaInvalid?: string;

  /**
   * Accessible label when control does not need to render label tag.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-label') ariaLabel?: string;

  /**
   * The aria-labelledby property enables authors to reference other elements on the page to define an
   * accessible name. This is useful when using elements that don't have native support for associating
   * elements to provide an accessible name.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-labelledby') ariaLabelledby?: string;

  /**
   * The aria-owns attribute identifies an element (or elements) in order to define a visual, functional, or
   * contextual relationship between a parent and its child elements when the DOM hierarchy cannot be used
   * to represent the relationship.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-owns') ariaOwns?: string;

  // `aria-readonly` is not needed, use `readonly` instead
  // `aria-required` is not needed, use `required` instead

  /**
   * Whether the input is required. Defaults to false.
   */
  @Input() required = false;
  /**
   * Whether the input is read only. Defaults to false.
   */
  @Input() readonly = false;
  /**
   * An event emitter for on blur actions.
   */
  @Output() blur = new EventEmitter<FocusEvent | void>();
  /**
   * An event emitter for on focus actions.
   */
  @Output() focus = new EventEmitter<FocusEvent | void>();
  /**
   * Whether the text input should be auto-focused when shown.
   */
  @Input() autofocus = false;

  protected readonly _valueChange = new EventEmitter<any>();

  /**
   * Whether the component is mutable or clickable. Defaults to false.
   */
  @Input() disabled = false;

  value: Object | string | boolean | null = '';

  constructor(protected readonly cd: ChangeDetectorRef) {}

  onBlur($event?: FocusEvent) {
    this.blur.emit($event);
    this.onTouched();
  }

  onChange: (newValue: Object | string | undefined | null) => void = () => {};

  onValueChange(newValue?: Object | string) {
    if (newValue === undefined) {
      this.onChange(this.value);
    } else {
      this.onChange(newValue);
      this.value = newValue;
      this._valueChange.emit(this.value);
    }
  }

  onFocus($event?: FocusEvent) {
    this.focus.emit($event);
  }

  writeValue(inputValue: Object | string | null): void {
    this.value = inputValue === null ? '' : inputValue;
    this.cd.markForCheck();
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }

  /**
   * Returns the `input` `size` value for a keyword, such as "xxl".
   * Subclasses can choose to implement a custom mapping, which will be used to configure `size`.
   */
  protected getSizeByKeyword(arg: any): number | undefined {
    return undefined;
  }
}
