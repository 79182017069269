import { Component, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute, ParamMap, UrlSegment } from '@angular/router';
import { Observable, Subject, combineLatest, filter, map, switchMap, takeUntil, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SassTokensService } from 'apps/design-system-app/src/app/services/sass-tokens.service';
import { ShowcaseService } from '../../../../services/showcase.service';

@Component({
  selector: 'bb-showcase-web',
  templateUrl: './showcase-web.component.html',
  standalone: false,
})
export class ShowcaseWebComponent implements OnInit, OnDestroy {
  componentId = '';

  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private sassTokensService: SassTokensService,
    private showcaseService: ShowcaseService,
  ) {}

  showStorybook$ = combineLatest([this.route.paramMap, this.route.url]).pipe(
    map(([params, url]): [string, UrlSegment[]] => {
      return [params.get('id') || '', url];
    }),
    filter(([id, _]) => !!id),
    map(([id, url]: [string, UrlSegment[]]) => {
      const component = this.showcaseService.getComponent(id);

      return !!component?.storybook && !url.at(-1)?.path.endsWith('-deprecated');
    }),
    takeUntil(this.destroy$),
  );

  scssTokensDoc$: Observable<string> | null = null;

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params: ParamMap) => {
      this.componentId = params.get('id') || '';
      this.sassTokensService.isRendered.next(false);
      this.scssTokensDoc$ = this.http
        .get(`assets/docs/components-scss-tokens/${this.componentId}.html`, {
          responseType: 'text',
        })
        .pipe(
          tap({
            next: () => this.sassTokensService.isRendered.next(true),
            error: () => this.sassTokensService.isRendered.next(true),
          }),
        );
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
