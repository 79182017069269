import { Component } from '@angular/core';

@Component({
  selector: 'bb-infinite-scroll-example-ui',
  templateUrl: './infinite-scroll.example.component.html',
  styles: ['.bb-infinite-scroll-container-showcase { height: 320px; }'],
  standalone: false,
})
export class InfiniteScrollExampleComponent {
  page = 0;
  complete = false;
  loading = false;
  sum = 10;
  items: string[] = [];

  // let verbs, nouns, adjectives, adverbs, preposition;
  nouns = [
    'television',
    'clock',
    'pizza',
    'plastic',
    'mud',
    'thing',
    'tire',
    'river',
    'tree',
    'clouds',
  ];
  verbs = [
    'decided',
    'ran',
    'flew',
    'adjusted',
    'modified',
    'rolled',
    'walked',
    'breathed',
    'slept',
    'passsed',
  ];
  adjectives = [
    'beautiful',
    'lazy',
    'professional',
    'lovely',
    'freat',
    'healty',
    'soft',
    'hot',
    'fresh',
    'slimy',
  ];
  adverbs = [
    'slowly',
    'elegantly',
    'precisely',
    'quickly',
    'sadly',
    'humbly',
    'proudly',
    'shockingly',
    'calmly',
    'passionately',
  ];
  preposition = [
    'down',
    'into',
    'up',
    'on',
    'upon',
    'below',
    'above',
    'through',
    'across',
    'towards',
  ];

  randGen() {
    return Math.floor(Math.random() * 5);
  }

  sentence() {
    const rand1 = Math.floor(Math.random() * 10);
    const rand2 = Math.floor(Math.random() * 10);
    const rand3 = Math.floor(Math.random() * 10);
    const rand4 = Math.floor(Math.random() * 10);
    const rand5 = Math.floor(Math.random() * 10);

    return (
      'The ' +
      this.adjectives[rand1] +
      ' ' +
      this.nouns[rand2] +
      ' ' +
      this.adverbs[rand3] +
      ' ' +
      this.verbs[rand4] +
      ' because some ' +
      this.nouns[rand1] +
      ' ' +
      this.adverbs[rand1] +
      ' ' +
      this.verbs[rand1] +
      ' ' +
      this.preposition[rand1] +
      ' a ' +
      this.adjectives[rand2] +
      ' ' +
      this.nouns[rand5] +
      '.'
    );
  }

  constructor() {
    this.addItems();
  }

  loadMore() {
    if (!this.loading) {
      this.loading = true;
      this.page++;

      window.setTimeout(() => {
        this.loading = false;
        this.addItems();
        this.complete = this.page >= 5;
      }, 1000);
    }
  }

  addItems() {
    for (let i = 0; i < this.sum; ++i) {
      this.items.push(this.sentence());
    }
  }
}
