import { Component } from '@angular/core';

@Component({
    selector: 'bb-product-selector-page-ui',
    templateUrl: './bb-product-selector-page-ui.component.html',
    standalone: false,
})
export class ProductSelectorPageComponent {
  singleDropdownOptions = ['Option One', 'Option Two', 'Option Three'];
  productSelected = {
    id: '1cdb2224',
    amount: '1231',
    currency: 'EUR',
    productNumber: '2342 443234 4234',
    name: 'Some Name',
    productType: 'currentAccounts',
  };
  productsInterfaced = [
    {
      id: '1cdb2224',
      amount: '1231',
      currency: 'EUR',
      productNumber: '2342 443234 4234',
      name: 'Some Name',
      productType: 'currentAccounts',
    },
    {
      id: '1cdb222sgdfd4',
      amount: '123123',
      currency: 'EUR',
      productNumber: '2342 443234 4234432',
      name: 'Some Name 234',
      productType: 'my Type 234',
      custonType: 'header',
      header: 'savingsAccounts',
    },
    {
      id: '1cdb222gdfgsd4',
      amount: '123123',
      currency: 'EUR',
      productNumber: '2342 443234 4234432',
      name: 'Some NA34534',
      productType: 'termDeposits',
    },
    {
      id: '1cdb222sdfgd4',
      amount: '123123',
      currency: 'EUR',
      productNumber: '2342 443234 4234432',
      name: 'Some NA3454',
      productType: 'loans',
    },
    {
      id: '1cddfgb222sd4',
      amount: '123123',
      currency: 'EUR',
      productNumber: '2342 443234 4234432',
      name: 'Some N34534',
      productType: 'creditCards',
    },
    {
      id: '1cdb2sfd22sd4',
      amount: '123123',
      currency: 'EUR',
      productNumber: '2342 443233454 4234432',
      name: 'Some NAm345',
      productType: 'debitCards',
    },
    {
      id: '1cdb2df22sd4',
      amount: '123123',
      currency: 'EUR',
      productNumber: '2343542 443234 4234432',
      name: 'Some Name 234',
      productType: 'investmentAccounts',
    },
  ];
}
