import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';

@Directive({
  selector: '[bbDropdownToggleFullWidth]',
  standalone: false,
})
export class DropdownMenuFullWidthDirective implements OnDestroy, AfterViewInit {
  private dropdownMenuChanges!: MutationObserver;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const dropdownBtn = this.elementRef.nativeElement.querySelector('[ngbDropdownToggle]');
    const dropdownMenu = this.elementRef.nativeElement.querySelector('[ngbDropdownMenu]');
    const splitBtnWrapper = dropdownBtn?.closest('div.btn-group');
    if (this.bbDropdownToggleFullWidth && dropdownBtn.attributes['aria-expanded'].value === 'true' && dropdownMenu) {
      this.setDropdownMenuWidth(splitBtnWrapper, dropdownBtn, dropdownMenu);
    }
  }
  /**
   * If true it will stretch the dropdown menu to 100% width
   */
  @Input() bbDropdownToggleFullWidth = false;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    const dropdownBtn: HTMLElement = this.elementRef.nativeElement.querySelector('[ngbDropdownToggle]');
    const splitBtnWrapper: HTMLElement | null = dropdownBtn?.closest('div.btn-group');

    if (!this.bbDropdownToggleFullWidth) {
      return;
    }

    this.dropdownMenuChanges = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const dropdownMenu = mutation.target.parentNode?.querySelector('[ngbDropdownMenu]');
        if (mutation.attributeName === 'aria-expanded' && dropdownMenu) {
          this.setDropdownMenuWidth(splitBtnWrapper, dropdownBtn, dropdownMenu);
        }
      });
    });

    this.dropdownMenuChanges.observe(dropdownBtn, {
      attributeFilter: ['aria-expanded'],
      childList: false,
    });

    if (dropdownBtn.hasAttribute('aria-expanded')) {
      const menu = dropdownBtn.parentNode?.querySelector('[ngbDropdownMenu]');

      if (!menu) {
        return;
      }
      this.setDropdownMenuWidth(splitBtnWrapper, dropdownBtn, menu);
    }
  }

  private setDropdownMenuWidth(splitBtnWrapper: HTMLElement | null, dropdownBtn: HTMLElement, dropdownMenu: Element) {
    const minWidth = splitBtnWrapper ? splitBtnWrapper.clientWidth : dropdownBtn.clientWidth;
    this.renderer.setStyle(dropdownMenu, 'min-width', `${minWidth}px`);
  }

  ngOnDestroy(): void {
    this.dropdownMenuChanges?.disconnect();
  }
}
