import { NgModule } from '@angular/core';
import { AsyncPipe, DatePipe, NgTemplateOutlet } from '@angular/common';
import { ProgressTrackerComponent } from './progress-tracker.component';
import { ProgressItemComponent } from './components/progress-item/progress-item.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProgressTrackerContainerComponent } from './containers/progress-tracker-container.component';
import { IconModule } from '@backbase/ui-ang/icon';
import { ButtonModule } from '@backbase/ui-ang/button';
import { ProgressbarModule } from '@backbase/ui-ang/progressbar';
import { PubSubService } from './pub-sub.service';

@NgModule({
  imports: [AsyncPipe, DatePipe, NgTemplateOutlet, IconModule, ButtonModule, ProgressbarModule, NgbModule],
  declarations: [ProgressTrackerComponent, ProgressTrackerContainerComponent, ProgressItemComponent],
  exports: [ProgressTrackerComponent, ProgressTrackerContainerComponent, ProgressItemComponent, ProgressbarModule],
  providers: [PubSubService],
})
export class ProgressTrackerModule {}
