import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { getKeyCode, KEY_CODES } from '@backbase/ui-ang/util';
import { DomAttributesService } from '@backbase/ui-ang/services';
import { ModalHeaderComponent } from './modal-templates/modal-header.component';
import { DOCUMENT } from '@angular/common';
import { ModalBodyComponent } from './modal-templates/modal-body.component';
import { BehaviorSubject, Subject, debounceTime, filter, merge, takeUntil, tap } from 'rxjs';
import { BB_MONEY_PROTECTION_BANNER_CONFIG_TOKEN } from '@backbase/ui-ang/money-protection';

/**
 * @name ModalComponent
 *
 * @description
 * Component that displays a modal window.
 * <br><br>
 *
 * ### Controlling components with overflow/z-index
 * If you have nested modals, dropdowns or select components,
 * add (or remove) the property `container="body"` on your topmost component.
 * You may have to toggle around depending on your setup or if your modal/dropdown/select has overflow.
 *
 * Alternatively, you can also add the `.modal-overflow` class by doing:
 * `[modalOptions]="{ modalDialogClass: 'modal-overflow' }"`.
 */
@Component({
  selector: 'bb-modal-ui',
  templateUrl: './modal.component.html',
  standalone: false,
})
export class ModalComponent implements OnDestroy, AfterContentInit {
  constructor(
    private readonly modalService: NgbModal,
    private readonly renderer: Renderer2,
    private readonly domAttrService: DomAttributesService,
    // cannot use type `Document` due to compilation issues caused by `strictMetadataEmit`
    @Inject(DOCUMENT) private readonly document: any,
  ) {}

  private modalRef: NgbModalRef | undefined;
  readonly id = this.domAttrService.generateId();

  private readonly _isOpen = new BehaviorSubject(false);
  /**
   * The flag to show the dialog window. Defaults to 'false'.
   */
  @Input()
  set isOpen(value: boolean) {
    this._isOpen.next(value);
  }

  get isOpen(): boolean {
    return this._isOpen.getValue();
  }
  /**
   * Set aria-labelledby with an element id that contains a brief label about the modal.
   * By default is takes the modal heading.
   */
  @Input('aria-labelledby')
  get ariaLabelledby(): string | undefined {
    return this._ariaLabelledby ?? this.modalOptions.ariaLabelledBy ?? this.modalHeader?.headerId;
  }

  set ariaLabelledby(value: string | undefined) {
    this._ariaLabelledby = value;
  }

  private _ariaLabelledby: string | undefined;

  /**
   * Set aria-describedby with an element id that contains a detailed description about the modal.
   */
  @Input('aria-describedby')
  get ariaDescribedby(): string | undefined {
    return this._ariaDescribedby ?? this.modalOptions.ariaDescribedBy ?? this.modalBody?.bodyId;
  }

  set ariaDescribedby(value: string | undefined) {
    this._ariaDescribedby = value;
  }

  private _ariaDescribedby: string | undefined;

  /**
   * Customize the ARIA role this component.
   *
   * This can be used to improve accessibility for components, for example by configuring `[role]="'alertdialog'"`
   * if it requires immediate attention of the user.
   *
   * Defaults to 'dialog'.
   */
  @Input() role = 'dialog';
  /**
   * Dialog options inherited from https://ng-bootstrap.github.io/#/components/modal/api#NgbModalOptions. Defaults to an empty object.
   */
  @Input() modalOptions: NgbModalOptions = {};

  /**
   * The flag to show money protection insurance banner on top of the modal.
   * In order to see banner you will need to configure `BB_MONEY_PROTECTION_BANNER_CONFIG_TOKEN` to provide
   * component to render in banner content outlet.
   */
  @Input() showMoneyProtectionBanner = false;
  /**
   * Custom class to be assigned for money protection banner inside modal
   * @type {string}
   */
  @Input() moneyProtectionBannerClasses = '';
  /**
   * The event that's fired after confirm button is pressed. Can be used with
   * (click)="dialogRef.onConfirm()" where dialogRef is a templateRef on <bb-dialog-ui #dialogRef />
   */
  @Output() confirm = new EventEmitter();
  /**
   * The event that's fired after cancel button is pressed. Can be used with
   * (click)="dialogRef.onCancel()" where dialogRef is a templateRef on <bb-dialog-ui #dialogRef />
   */
  @Output() cancel = new EventEmitter();
  /**
   * Used for two way binding with the isOpen Input. If used, backdrop
   * and esc dismiss cases will be handled automatically.
   */
  @Output() isOpenChange = new EventEmitter();

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any> | undefined;

  @ContentChild(ModalHeaderComponent) modalHeader: ModalHeaderComponent | undefined;
  @ContentChildren(ModalHeaderComponent) private modalHeaders: QueryList<ModalHeaderComponent> | undefined;
  @ContentChild(ModalBodyComponent) modalBody: ModalBodyComponent | undefined;

  protected readonly moneyProtectionBanner? = inject(BB_MONEY_PROTECTION_BANNER_CONFIG_TOKEN, { optional: true });

  private emitConfirm = true;
  private headerChange$ = new Subject<void>();
  private destroy$ = new Subject<void>();

  ngAfterContentInit(): void {
    this.subscribeIsOpen();
    this.subscribeHeaderClose();
    this.modalHeaders?.changes
      .pipe(
        tap(() => {
          this.headerChange$.next();
        }),
        debounceTime(0),
        filter(Boolean),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.subscribeHeaderClose();
      });
  }

  getDismissReason(reason: ModalDismissReasons | string): ModalDismissReasons | string {
    this.isOpenChange.emit(false);

    return reason;
  }

  dismissModal(reason?: string): void {
    if (this.modalRef) this.modalRef.dismiss(reason);
  }

  closeModal(reason?: string): void {
    if (this.modalRef) this.modalRef.close(reason);
  }

  setConfirm(flag: boolean): void {
    this.emitConfirm = flag;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.closeModal();
  }

  trapKeyEvent(event: KeyboardEvent) {
    if (getKeyCode(event) !== KEY_CODES.ESCAPE) {
      event.stopPropagation();
    }
  }

  private subscribeHeaderClose() {
    this.modalHeader?.close.pipe(takeUntil(merge(this.destroy$, this.headerChange$))).subscribe(() => {
      this.dismissModal();
    });
  }

  private subscribeIsOpen() {
    this._isOpen.pipe(filter(Boolean), takeUntil(this.destroy$)).subscribe(() => {
      this.setConfirm(true);

      this.modalBody?.setScrollable(this.modalOptions.scrollable);
      this.modalRef = this.modalService.open(this.modalContent, this.modalOptions);
      this.modalRef.result.then(
        (result) => {
          if (this.emitConfirm) {
            this.confirm.emit(result);
          }
        },
        (reason) => this.cancel.emit(this.getDismissReason(reason)),
      );

      const modalContent = (this.document as Document).getElementsByClassName('modal-dialog');
      const modal = (this.document as Document).getElementsByClassName('modal');
      modalContent?.[0]?.removeAttribute('role');
      modal?.[0]?.scrollTo?.(0, 0);

      // remove some a11y attributes for ngb-modal since we  already set it on the HTML
      // to allow better a11y support
      const ngbModalWindow = (this.document as Document).querySelector('ngb-modal-window');
      this.renderer.removeAttribute(ngbModalWindow, 'role');
      this.renderer.removeAttribute(ngbModalWindow, 'aria-modal');
    });

    this._isOpen
      .pipe(
        filter((value) => !value && !!this.modalRef),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.setConfirm(false);
        this.modalRef!.close();
      });
  }
}
/*
 * @deprecated Deprecated in ui-ang@12. To be removed in ui-ang@14
 * Remove the usage of the directive from the HTML
 */
@Directive({
  selector: '[bbElementViewInit]',
  standalone: false,
})
export class ElementViewInitDirective implements AfterViewInit {
  @Output() bbElementViewInit = new EventEmitter<ElementRef>();

  constructor(private readonly elem: ElementRef) {}

  ngAfterViewInit(): void {
    this.bbElementViewInit.emit(this.elem);
  }
}
