import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Input,
  EventEmitter,
  Output,
  Self,
  Optional,
  OnDestroy,
} from '@angular/core';
import { InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { NgControl } from '@angular/forms';
import { Subject, debounceTime, takeUntil } from 'rxjs';

/**
 * @name InputCheckboxComponent
 *
 * @description
 * Component that displays a checkbox input.
 */
@Component({
  selector: 'bb-input-checkbox-ui',
  templateUrl: './input-checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InputCheckboxComponent extends InputBaseComponent implements OnDestroy {
  /**
   * The native `<input type="checkbox">` element.
   */
  @ViewChild('input', { static: true }) inputElement?: ElementRef<HTMLInputElement>;
  /**
   * Event emitted when the checkbox's `indeterminate` value changes.
   */
  @Output() readonly indeterminateChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * Whether checkbox should be rendered.
   * There will be no visual changes but the checkbox will not be interactive.
   * Used for preventing nested interactive control violations for accessibility e.g bb-account-selector-ui.
   * Defaults to false.
   */
  @Input() hideCheckbox = false;
  /**
   * The value that represents indeterminate state of checkbox.
   */
  @Input()
  get indeterminate(): boolean {
    return this.indeterminateValue;
  }
  set indeterminate(value: boolean) {
    if (this.indeterminateValue !== value) {
      this.indeterminateValue = value;
      this.indeterminateChange.emit(this.indeterminate);
    }
  }

  private onBlur$ = new Subject<FocusEvent | undefined>();
  private destroy$ = new Subject<void>();
  private indeterminateValue = false;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    protected readonly el: ElementRef,
    @Self() @Optional() public parentFormControl: NgControl,
  ) {
    super(cd);

    if (this.parentFormControl) {
      this.parentFormControl.valueAccessor = this;
    }

    this.blurHandler();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onValueChange() {
    if (this.indeterminate && this.inputElement) {
      this.indeterminate = false;
      this.inputElement.nativeElement.checked = false;
      this.inputElement.nativeElement.dispatchEvent(new Event('change'));
    }
    super.onValueChange();
  }

  setFocus() {
    if (this.inputElement) {
      this.inputElement.nativeElement.focus();
    }
  }

  onBlur($event?: FocusEvent) {
    this.onBlur$.next($event);
  }

  private blurHandler() {
    this.onBlur$.pipe(debounceTime(100), takeUntil(this.destroy$)).subscribe(($event: FocusEvent | undefined) => {
      this.blur.emit($event);
      this.onTouched();
    });
  }
}
