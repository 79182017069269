import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-period-selector-ui-showcase',
  templateUrl: './period-selector-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class PeriodSelectorShowcaseComponent {
  periodStartDate = new Date('2019-02-09');
  periodEndDate = new Date('2019-02-21');
}
