import { Component } from '@angular/core';
import { Subject, Observable, merge, of } from 'rxjs';
import { startWith, mergeMap, map, delay, tap } from 'rxjs/operators';
// Please replace relative path imports e.g. '../*' with '@backbase/ui-ang'.
import { LoadMoreParams } from '@backbase/ui-ang/load-button';

const LOADING_DURATION = 1500;

@Component({
  selector: 'bb-load-button-example-ui',
  templateUrl: './load-button.example.component.html',
  standalone: false,
})
export class LoadButtonExampleComponent {
  itemsPerPage = 4;
  page = 0;
  totalItems = 12;

  loadRequest: Subject<LoadMoreParams> = new Subject();
  loadProcedure = this.loadRequest.pipe(
    mergeMap((params) => this.loadMore(params)),
  );

  isLoading: Observable<boolean> = merge(
    this.loadRequest.pipe(map(() => true)),
    this.loadProcedure.pipe(map(() => false)),
  ).pipe(startWith(false));

  onLoadButtonPress(params: LoadMoreParams) {
    this.loadRequest.next(params);
  }

  loadMore(params: LoadMoreParams) {
    console.log('Loading more...');

    return of(params).pipe(
      delay(LOADING_DURATION),
      tap(() => {
        this.page = params.from;
      }),
    );
  }
}
