import { Pipe, PipeTransform } from '@angular/core';
import { EmptyStatePresetType, PRESETS } from './type';

@Pipe({
  name: 'presetSubtitle',
  standalone: false,
})
export class PresetSubtitlePipe implements PipeTransform {
  transform(value: EmptyStatePresetType) {
    const result = PRESETS[value]?.subtitle;

    return (
      result ??
      $localize`:@@bb-empty-state-ui.bb-empty-state__message.unknown_error_personal:
    Try again or contact us if the problem continues`
    );
  }
}
