import { Component } from '@angular/core';
import { LayoutService } from '@backbase/ui-ang/layout';

@Component({
  selector: 'bb-header',
  templateUrl: './header.component.html',
  standalone: false,
})
export class HeaderComponent {
  constructor(public layoutService: LayoutService) {}
}
