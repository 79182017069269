import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-input-timepicker-ui-showcase',
  templateUrl: './input-timepicker-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
/**
 * @name InputTimepickerShowcaseComponent
 *
 * @description
 * Component that displays multiple examples with the timepicker component.
 */
export class InputTimepickerShowcaseComponent {
  vForm: UntypedFormGroup = this.formBuilder.group({
    time: [''],
  });
  timeModel = '';
  min = '13:00:00';
  max = '14:30:00';

  hasError(type: string): boolean {
    const time = this.vForm.get('time');
    if (time !== null && time.errors) {
      return !!time.errors[type];
    }

    return false;
  }

  showErrors(): boolean | null {
    const time = this.vForm.get('time');
    if (time !== null) {
      return time.errors && !time.valid;
    }

    return null;
  }

  constructor(private readonly formBuilder: UntypedFormBuilder) {}
}
