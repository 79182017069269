import { Injectable } from '@angular/core';
import menuConfig from '_menu';

export interface MetadataItem {
  component: string;
  web: string;
  iosSwiftUI: string;
  iosUIKit: string;
  androidCompose: string;
  androidXML: string;
}

export interface MenuJsonItem {
  name: string;
  path?: string;
  children?: MenuJsonItem[];
  iosSwiftUI?: boolean;
  iosUIKit?: boolean;
  androidCompose?: boolean;
  androidXML?: boolean;
  web?: boolean;
  webTheme?: boolean;
  hide?: boolean;
  storybook?: string;
}

@Injectable({ providedIn: 'root' })
export class ComponentMetaDataService {
  private _supportedComponents: MetadataItem[] = [];

  get supportedComponents() {
    if (this._supportedComponents.length) {
      return this._supportedComponents;
    }
    this.getMetadataArrayFromMenuJsonItems(menuConfig, this._supportedComponents);

    return this._supportedComponents;
  }

  private normalizeComponentName(name: string) {
    return name.trim().replace(/\s/g, '-').toLowerCase();
  }

  getMobileComponents() {
    return this.supportedComponents.filter(
      (component) => component.androidCompose || component.androidXML || component.iosSwiftUI || component.iosUIKit,
    );
  }

  getWebComponents() {
    return this.supportedComponents.filter((component) => component.web);
  }

  getIOSComponents() {
    return this.supportedComponents.filter((component) => component.iosSwiftUI || component.iosUIKit);
  }

  getAndroidComponents() {
    return this.supportedComponents.filter((component) => component.androidCompose || component.androidXML);
  }

  getSupportInfoForComponent(component: string) {
    return this.supportedComponents.find(
      (supportedComponent) => supportedComponent.component.toLowerCase() === component.toLowerCase(),
    );
  }

  isAndroidComponent(component: string) {
    const supportInfo = this.getSupportInfoForComponent(component);

    return (supportInfo && supportInfo.androidCompose) || (supportInfo && supportInfo.androidXML);
  }

  isIOSComponent(component: string) {
    const supportInfo = this.getSupportInfoForComponent(component);

    return (supportInfo && supportInfo.iosSwiftUI) || (supportInfo && supportInfo.iosUIKit);
  }

  isWebComponent(component: string) {
    const supportInfo = this.getSupportInfoForComponent(component);

    return supportInfo && supportInfo.web;
  }

  private getMetadataArrayFromMenuJsonItems(items: MenuJsonItem[], metadataItems: MetadataItem[], parent = ''): void {
    // eslint-disable-next-line complexity
    items.forEach((item: MenuJsonItem) => {
      const parsedName = this.normalizeComponentName(item.name);

      if (item.children) {
        this.getMetadataArrayFromMenuJsonItems(item.children, metadataItems, parent + parsedName + '/');
      } else {
        if (item.web || item.storybook || item.iosSwiftUI || item.iosUIKit || item.androidCompose || item.androidXML) {
          const metadataItem = metadataItems.find(
            (metadataItemCurrent: MetadataItem) => metadataItemCurrent.component === parsedName,
          );

          if (metadataItem) {
            if (item.web || item.storybook) {
              metadataItem.web = parent;
            }
            if (item.iosSwiftUI) {
              metadataItem.iosSwiftUI = parent;
            }
            if (item.iosUIKit) {
              metadataItem.iosUIKit = parent;
            }
            if (item.androidCompose) {
              metadataItem.androidCompose = parent;
            }
            if (item.androidXML) {
              metadataItem.androidXML = parent;
            }
          } else {
            metadataItems.push({
              component: parsedName,
              web: !!(item.storybook ?? item.web) ? parent : '',
              iosSwiftUI: !!item.iosSwiftUI ? parent : '',
              iosUIKit: !!item.iosUIKit ? parent : '',
              androidCompose: !!item.androidCompose ? parent : '',
              androidXML: !!item.androidXML ? parent : '',
            });
          }
        }
      }
    });
  }
}
