import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { FocusService } from './focus.service';

@Directive({
  selector: '[bbFocus]',
  exportAs: 'bbFocus',
  standalone: false,
})
export class FocusDirective implements AfterViewInit {
  @Input()
  set bbFocus(value: boolean | '') {
    this.autofocus = value === '' || !!value;
  }

  private autofocus = true;

  constructor(
    private readonly service: FocusService,
    private readonly el: ElementRef,
  ) {}

  ngAfterViewInit(): void {
    if (this.autofocus) {
      this.focus();
    }
  }

  focus() {
    this.service.focus(this.el);
  }
}
