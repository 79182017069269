import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';
import { NotificationService } from '@backbase/ui-ang/notification';

declare const Prism: any;
@Component({
  selector: 'bb-showcase-usage',
  templateUrl: './showcase-usage.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ShowcaseUsageComponent {
  _importStr = '';
  importStrRaw = '';
  @Input()
  set moduleName(val: string) {
    const specialPaths = [{ current: 'tooltip', replacement: 'tooltip-directive' }];
    const replaceSpecialPaths = (val: string): string =>
      specialPaths.find(({ current }) => current === val)?.replacement ?? val;

    const pathName = replaceSpecialPaths(
      val
        .replace('Module', '')
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .toLowerCase(),
    );

    this.importStrRaw = `import { ${val} } from '@backbase/ui-ang/${pathName}';`;

    val = Prism.highlight(this.importStrRaw, Prism.languages.js);
    this._importStr = val;
  }

  constructor(@Optional() private notificationService: NotificationService) {}
  copyToClipboard(input: HTMLInputElement) {
    this.showNotification();
    input.select();
    navigator.clipboard.writeText(input.value);
  }

  showNotification() {
    if (this.notificationService) {
      this.notificationService.showNotification({
        header: 'Successfully copied import statement to the clipboard',
        message: '',
        dismissible: true,
        ttl: 5000,
        modifier: 'success',
      });
    } else {
      console.log('Need a notification service, there`s one in ui-ang!');
    }
  }
}
