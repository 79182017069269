import { Directive, AfterContentInit, ElementRef, HostListener, ContentChildren, QueryList } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { SassTokensService } from '../services/sass-tokens.service';
import { ScrollSpyService } from '../services/scroll-spy.service';
import { ThemeDocsService } from '../services/theme-docs.service';

@Directive({
  selector: '[bbScrollSpy]',
  standalone: false,
})
export class ScrollSpyDirective implements AfterContentInit {
  sectionDOMChildren: Array<any> = [];
  trackedSections: Array<any> = [];
  currentScrolledSection = '';
  @ContentChildren(ScrollSpyDirective, { descendants: true })
  contentChildren!: QueryList<ScrollSpyDirective>;
  constructor(
    private _el: ElementRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private scrollSpyService: ScrollSpyService,
    private themeDocsService: ThemeDocsService,
    private sassTokensService: SassTokensService,
  ) {}

  ngAfterContentInit() {
    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        mergeMap(() => this.themeDocsService.isRendered.pipe(filter((isRendered) => isRendered))),
        mergeMap(() => this.sassTokensService.isRendered.pipe(filter((isRendered) => isRendered))),
        // Workaround:
        // Otherwise, picks up previous fragments
        debounceTime(100),
      )
      .subscribe(() => {
        this.initializeSectionHeaders();
        this.initializeSectionScrollBreakpoints();
        this.registerFragmentScrollTo();
      });
  }

  initializeSectionHeaders() {
    // requires destructuring on HTMLCollection to be treated as Array
    this.sectionDOMChildren = Array.from(this._el.nativeElement.querySelectorAll('.dso__content h2'));
    const displayedFragments = (this.sectionDOMChildren.length > 1 ? this.sectionDOMChildren : []).filter(
      (fragItem) => !fragItem.id.includes('px'),
    );

    this.scrollSpyService.initialFragment = this.sectionDOMChildren;
    this.scrollSpyService.fragmentStore.next(displayedFragments);
  }
  // gets the section measurements so that onScroll can handle
  // offsets to every section <h2> tag position.
  initializeSectionScrollBreakpoints() {
    // Array<DOMNode:div.sect1>
    // Sets up breakpoints to trigger
    this.trackedSections = this.sectionDOMChildren.map((child: any, idx, arr) => ({
      sectionId: child.id,
      triggerPos: idx > 0 ? child.offsetTop : 0,
    }));
    // Post append last scrollEnd offset
    this.trackedSections.push({
      sectionId: '_LAST_OFFSET',
      triggerPos: this._el.nativeElement.scrollHeight,
    });
  }
  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    // Check if initialized section offsetTop's are met during the scroll (currentSection)
    const currentScrollPos = event.target.scrollTop;
    this.scrollSpyService.stickyStore.next(currentScrollPos);

    const elmOffsetHeight = this._el.nativeElement.offsetHeight / 2;
    const currentSection = this.trackedSections.find((sectionDOMInfo, currIdx, arr) =>
      currentScrollPos >= sectionDOMInfo.triggerPos && currIdx !== arr.length - 1
        ? currIdx !== arr.length - 3
          ? currentScrollPos < arr[currIdx + 1].triggerPos
          : currentScrollPos + elmOffsetHeight < arr[currIdx + 1].triggerPos
        : currentScrollPos < this._el.nativeElement.offsetHeight,
    );

    // Only inform scrollStore Subject when the section has changed
    if (currentSection !== this.currentScrolledSection) {
      this.currentScrolledSection = currentSection;
      this.scrollSpyService.scrollStore.next(currentSection);
    }
  }
  // Workaround for: ViewportScroller -- requires @angular/commons 6.1.0+
  registerFragmentScrollTo() {
    this.activatedRoute.fragment.subscribe((event) => {
      if (event && this.trackedSections.length) {
        const targetFragment = this.trackedSections.find((fragmentItem) => fragmentItem.sectionId === event);
        this._el.nativeElement.scrollTo(0, targetFragment.triggerPos);
      } else {
        this._el.nativeElement.scrollTo(0, 0);
      }
    });
  }
}
