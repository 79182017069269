import { Component } from '@angular/core';

@Component({
    selector: 'bb-tab-page-ui',
    templateUrl: './bb-tab-page-ui.component.html',
    standalone: false,
})
export class TabPageComponent {
  onTabSelect(index: number) {
    console.log(`Selected tab with index: ${index}`);
  }
}
