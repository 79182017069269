import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-dropdown-multi-select-ui-showcase',
  templateUrl: 'dropdown-multi-select-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class DropdownMultiSelectShowcaseComponent {
  public flatListDropdownMultiSelect = new UntypedFormControl('');
  public parentChildDropdownMultiSelect = new UntypedFormControl('');
  public disabledDropdownMultiSelect = new UntypedFormControl({ value: '', disabled: true });

  public options = [
    { value: 'one', label: 'One' },
    { value: 'two', label: 'Two' },
    { value: 'three', label: 'Three' },
    { value: 'four', label: 'Four' },
    { value: 'five', label: 'Five' },
    { value: 'eleven', label: 'Eleven' },
  ];

  public dynamicOptions = [
    { value: 'one', label: 'One' },
    { value: 'two', label: 'Two' },
    { value: 'three', label: 'Three' },
    { value: 'four', label: 'Four' },
  ];

  public parentChildOptions = [];

  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      categories: new UntypedFormControl(),
      dynamicCategories: new UntypedFormControl(),
    });
  }

  changeOptions() {
    const getRandomInt = (max: number) => {
      return Math.floor(Math.random() * max) || 1;
    };

    this.dynamicOptions = new Array(getRandomInt(10)).fill('').map((_, index) => {
      return { value: `value${index}`, label: `Label ${index}` };
    });
  }

  resetForm() {
    this.form.reset();
  }
}
