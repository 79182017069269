import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';
import { DROPDOWN_MENU_OPTIONS, DROPDOWN_TEMPLATE_MENU_OPTIONS } from '@backbase/ui-ang/dropdown-menu';

@Component({
  selector: 'bb-dropdown-menu-ui-showcase',
  templateUrl: './dropdown-menu-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class DropdownMenuShowcaseComponent {
  message = '';

  dropdownTemplateMenuOptions = DROPDOWN_TEMPLATE_MENU_OPTIONS;
  dropdownMenuOptions = DROPDOWN_MENU_OPTIONS;

  onButtonDropdownSelect(item: any) {
    this.message = item;
  }

  doesParentContainDropdown(node: any): boolean {
    if (node.parentNode) {
      if (node.parentNode.closest('.dropdown')) {
        return true;
      }

      return false;
    } else {
      return false;
    }
  }
}
