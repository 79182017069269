import { ChangeDetectionStrategy, Component, Input, OnInit, EventEmitter, Output, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PubSubService } from './pub-sub.service';
import { IProgressTrackerConfig } from './progress-tracker.model';
import {
  IProgressTrackerItemTemplateConfig,
  IProgressTrackerTemplateConfig,
} from './containers/progress-tracker-container.model';

/**
 * @name ProgressTrackerComponent
 *
 * @description
 * A tracker component that tracks multiple ongoing or asynchronous processes on a page.
 * It handles multiple ongoing process events so that you can trigger, cancel and/or destroy events on demand.
 * It is easily extendable as you can you can extend the templates by passing your customised
 * template as input bindings. Refer to examples for the implementation.
 *
 */
@Component({
  selector: 'bb-progress-tracker',
  templateUrl: './progress-tracker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ProgressTrackerComponent implements OnInit {
  /**
   * The reference to a custom template for the Progress Tracker Container.
   *
   * Allows to completely override the way Progress Tracker is displayed.
   *
   */
  @Input() progressTrackerTemplate?: TemplateRef<IProgressTrackerTemplateConfig>;

  /**
   * The reference to a custom template for the Progress Tracker Item.
   *
   * Allows to completely override the way Progress Tracker Item is displayed.
   *
   */
  @Input() progressTrackerItemTemplate?: TemplateRef<IProgressTrackerItemTemplateConfig>;

  /**
   * The reference to a custom template for the Progress Tracker Item Header.
   *
   * Allows to completely override the way Progress Tracker Item heading is displayed.
   *
   */
  @Input() progressTrackerHeaderTemplate?: TemplateRef<any>;

  /**
   * Determines whether the Progress Tracker is opened or closed on render.
   *
   * This input property controls the state of the Progress Tracker.
   * When set to `true`, the Progress Tracker will be opened by default.
   * When set to `false`, it will be closed by default.
   *
   * Note: A toggle outside of the `progress-tracker-container` is not currently supported.
   * Clicking on the toggle calls `document:click` which keeps the container closed.
   *
   * Defaults to `false`.
   *
   */
  @Input() isOpen = false;

  private eventListSource = new BehaviorSubject<IProgressTrackerConfig[]>([]);
  eventListData: Observable<IProgressTrackerConfig[]> = this.eventListSource.asObservable();

  // change data to use getter and setter
  @Input()
  set eventList(value: IProgressTrackerConfig[]) {
    // set the latest value for eventListSource BehaviorSubject
    this.eventListSource.next(value);
  }

  @Output() cancel = new EventEmitter<string>();

  constructor(private readonly pubSubService: PubSubService) {}

  ngOnInit(): void {
    this.pubSubService.data.subscribe({
      next: (response: string) => {
        this.cancel.emit(response);
      },
    });
  }
}
