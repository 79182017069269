import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-stepper-ui-showcase',
  templateUrl: './stepper-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class StepperShowcaseComponent {
  data = '';

  public steps = [
    {
      label: 'Fill out your Name',
      ariaLabel: 'Name',
      isCurrent: false,
      isChecked: true,
      isActive: true,
    },
    {
      label: 'Fill out your Address',
      ariaLabel: 'Address',
      isCurrent: true,
      isChecked: false,
      isActive: true,
    },
    {
      label: 'Fill out your Date of Birth',
      ariaLabel: 'Date of Birth',
      isCurrent: false,
      isChecked: false,
      isActive: false,
    },
  ];

  public stepsWithChildren = [
    {
      label: 'Fill out your Name',
      stateLabel: 'Successful',
      ariaLabel: 'Name',
      isCurrent: false,
      isChecked: true,
      isActive: true,
    },
    {
      label: 'Fill out your Date of Birth',
      ariaLabel: 'Date of Birth',
      stateLabel:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
      data: 'category-1',
      isCurrent: false,
      isChecked: true,
      isActive: true,
    },
    {
      label: 'Fill out your Full Address',
      stateLabel: 'Open',
      ariaLabel: 'Full Address',
      isCurrent: true,
      isChecked: false,
      isActive: true,
      children: [
        {
          label: 'Fill out your Street Name',
          ariaLabel: 'Street name',
          data: 'category-2',
          isCurrent: false,
          isChecked: true,
          isActive: true,
        },
        {
          label: 'Fill out your House Number',
          ariaLabel: 'House Number',
          isCurrent: true,
          isChecked: false,
          isActive: true,
        },
        {
          label: 'Fill out your Zip Code',
          ariaLabel: 'Zipcode',
          data: 'category-3',
          isCurrent: false,
          isChecked: false,
          isActive: false,
        },
        {
          label: 'Fill out your State',
          ariaLabel: 'State',
          isCurrent: false,
          isChecked: false,
          isActive: false,
        },
        {
          label: 'Fill out your Country',
          ariaLabel: 'Country',
          isCurrent: false,
          isChecked: false,
          isActive: false,
        },
      ],
    },
    {
      label: 'Select a Payment method',
      stateLabel: 'Open',
      data: 'category-4',
      ariaLabel: 'Payment method',
      isCurrent: false,
      isChecked: false,
      isActive: false,
    },
    {
      label: 'Payment is completed',
      stateLabel: 'Open',
      ariaLabel: 'Payment completed',
      isCurrent: false,
      isChecked: false,
      isActive: false,
    },
    {
      label: 'Your Order has be placed',
      stateLabel: 'Open',
      ariaLabel: 'Order Placed',
      isCurrent: false,
      isChecked: false,
      isActive: false,
    },
  ];

  showData(data: any) {
    this.data = data;
  }
}
