import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'bb-period-selector-page-ui',
    template: '',
    styles: [],
    standalone: false,
})
export class BbPeriodSelectorPageUiComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
