import { Component } from '@angular/core';

@Component({
  selector: 'bb-date-pipe-example-ui',
  templateUrl: './date-pipe.example.component.html',
  standalone: false,
})
export class DatePipeExampleComponent {
  exampleDate = '2018-12-12T14:29:14.000Z';
}
