import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

/**
 * @name FieldsetComponent
 *
 * @description
 * Component that displays a fieldset group.
 */
@Component({
  selector: 'bb-fieldset-ui',
  templateUrl: './fieldset.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FieldsetComponent {
  /**
   * The caption for the `fieldset` element.
   */
  @Input() heading?: string;

  /**
   * Whether to disable all form items within the fieldset.
   */
  @Input() disabled = false;
}
