import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { MetadataService } from './metadata.service';
import { BbDsDocs } from './ds-toolkit.module';
import { ƟEXAMPLE_COMPONENTS, ƟSHOWCASE_COMPONENTS, ConfigurationInputService } from '@backbase/ui-ang-examples';

import { marked } from 'marked';

@Component({
  selector: 'bb-showcase-ui',
  templateUrl: './showcase.component.html',
  standalone: false,
})
export class ShowcaseComponent implements OnInit, OnChanges {
  @Input() bbdocs: BbDsDocs | undefined;
  @Input() componentId = '';
  @Input() scssTokensHtml = '';

  /**
   * Element that contains the Showcae component
   */
  @ViewChild('showcaseHost', { read: ViewContainerRef, static: true })
  private showcaseRef: ViewContainerRef | undefined;

  /**
   * Element that contains the Example component
   */
  @ViewChild('exampleHost', { read: ViewContainerRef, static: true })
  private exampleRef: ViewContainerRef | undefined;

  activeTab = 0;

  componentTemplate = '';

  modules: Array<any>;
  moduleAnnotations = new Map<string, any>();
  moduleName = '?';
  props = [];
  selector = '';
  deprecated = '';
  description = '';
  accessibilityDescription = '';
  exampleSourceCode = '';
  methods = '';

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private metadataService: MetadataService,
    private configurationInputService: ConfigurationInputService,
  ) {
    this.bbdocs = this.metadataService.libDocs;
    this.modules = [...ƟEXAMPLE_COMPONENTS, ...ƟSHOWCASE_COMPONENTS];
    for (const module of this.modules) {
      if (module.ɵcmp?.selectors) {
        for (const selector of module.ɵcmp?.selectors.flat()) {
          this.moduleAnnotations.set(selector, module);
        }
      }
    }
  }

  ngOnInit() {
    this.createAndUpdate(this.componentId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.componentId && changes.componentId.currentValue !== changes.componentId.previousValue) {
      this.componentId = changes.componentId.currentValue;
      this.createAndUpdate(this.componentId);
    }
  }

  createAndUpdate(componentId: string): void {
    if (this.bbdocs) {
      this.update(this.bbdocs, componentId);
      this.updateExampleSourceCode(this.bbdocs, componentId);
    }

    this.setShowcaseModule(`bb-${componentId}-ui-showcase`);
    this.setExampleModule(`bb-${componentId}-example-ui`);
  }

  setShowcaseModule(showcaseComponentId: string) {
    this.renderComponentInElement(this.showcaseRef, showcaseComponentId);
  }

  getComponentTemplate(componentId: string) {
    return this.bbdocs?.components.find((component: any) => component.selector === componentId)?.template;
  }

  setExampleModule(exampleComponentId: string) {
    const component = this.renderComponentInElement(this.exampleRef, exampleComponentId);

    const template = component ? this.getComponentTemplate(exampleComponentId) : '';
    const componentSelector = template.includes('<' + this.componentId)
      ? this.componentId
      : `bb-${this.componentId}-ui`;
    if (this.componentId === 'button') {
      this.componentTemplate = template.slice(
        template.indexOf('<' + componentSelector),
        template.indexOf(componentSelector + '>') + componentSelector.length + 1,
      );
    } else {
      this.componentTemplate = template;
    }

    this.configurationInputService.sharedField.subscribe({
      next: (value: any) => {
        this.componentTemplate = this.updateComponentTemplate(value);
      },
    });
  }

  updateComponentTemplate(updatedValue: any) {
    const arrayOfChanges: any = {};

    Object.keys(updatedValue).forEach((categories) => {
      Object.keys(updatedValue[categories]).forEach((item) => {
        arrayOfChanges[item] = updatedValue[categories][item];
      });
    });
    //added hardcoding only for configuration options for clickable button examples on DSO
    if (this.componentId === 'button') {
      return this.componentTemplate
        .replace(/\[color\]=".+"/, `[color]="'${arrayOfChanges['color']}'"`)
        .replace(/\[buttonSize\]=".+"/, `[buttonSize]="'${arrayOfChanges['buttonSize']}'"`)
        .replace(/\[disabled\]=".+"/, `[disabled]="${arrayOfChanges['disabled']}"`)
        .replace(/\[block\]=".+"/, `[block]="${arrayOfChanges['block']}"`)
        .replace(/{{ .+ | titlecase }}/, `{{ '${arrayOfChanges['color']}' | titlecase `);
    } else {
      return this.componentTemplate;
    }
  }

  protected renderComponentInElement(elementRef: ViewContainerRef | undefined, componentSelector: string) {
    if (elementRef) {
      elementRef.clear();
    }
    let moduleInstance = this.modules.find((module: any) => {
      const moduleAnnotations = this.metadataService.getModuleAnnotations(module);

      return Array.isArray(moduleAnnotations?.selector) && moduleAnnotations.selector.includes(componentSelector);
    });

    if (!moduleInstance) {
      moduleInstance = this.moduleAnnotations.get(componentSelector);
    }

    let component: ComponentFactory<any>;

    if (moduleInstance) {
      component = this.componentFactoryResolver.resolveComponentFactory(moduleInstance);
      if (elementRef && component) {
        elementRef.createComponent(component);
      }
    } else {
      throw new Error(`${componentSelector} wasn't found!`);
    }

    return component;
  }

  // TODO: decrease cyclomatic complexity
  // eslint-disable-next-line complexity
  update(docs: BbDsDocs, componentId: string) {
    const components = docs.components;
    const directives = docs.directives;
    const pipes = docs.pipes;
    for (const component of components) {
      const selector = component.selector;
      if (selector.includes(`bb-${componentId}-ui`) && !selector.includes('[')) {
        this.populateFields(component);
        this.moduleName = `${component.name.replace('Component', 'Module')}` || '?';

        return;
      }
    }

    for (const directive of directives) {
      const selector = directive.selector.toLowerCase();
      if (selector.includes(componentId.split('-').join('')) && selector.includes('[')) {
        this.populateFields(directive);
        this.moduleName = `${directive.name.replace('Directive', 'Module')}` || '?';

        return;
      }
    }
    for (const pipe of pipes) {
      if (pipe.name.toLowerCase().includes(componentId.split('-').join(''))) {
        this.populateFields(pipe);

        /**
         * Dirty hack.
         * MAINT-7772 - The module key is incorrect
         *
         * Context: incorrect module name displayed for PaymentCardNumberPipe pipe.
         *
         * Reason: PaymentCardNumberPipe module doesn't follow the correct module naming pattern.
         *
         * Solutions: To avoid "breaking changes" added this crutch
         */
        if (pipe.name === 'PaymentCardNumberPipe') {
          this.moduleName = 'PaymentCardNumberModule';

          return;
        }

        this.moduleName = `${pipe.name.replace('Pipe', 'PipeModule')}` || '?';

        return;
      }
    }
  }

  updateExampleSourceCode(docs: BbDsDocs, componentId: string) {
    const components = docs.components;
    const directives = docs.directives;

    for (const directive of directives) {
      const selector = directive.selector.toLowerCase();
      if (selector.includes(componentId) && selector.includes('example')) {
        this.exampleSourceCode = directive.sourceCode;

        return;
      }
    }
    for (const component of components) {
      // also pipes
      if (component.selector.includes(`bb-${componentId}-example-ui`)) {
        this.exampleSourceCode = component.sourceCode;

        return;
      }
    }
  }

  populateFields(obj: any) {
    if (obj.type !== 'pipe') {
      const inputs: any = obj.inputsClass;
      inputs.forEach((val: any) => (val.input = true));
      const outputs: any = obj.outputsClass;
      this.props = inputs.concat(outputs);
      this.selector = `${obj.selector}` || '';
    } else {
      this.props = [];
      this.selector = `${obj.ngname}` || '';
    }

    this.deprecated = obj.deprecationMessage;
    this.description = marked.parse(obj?.description ?? '');
    this.methods = obj.methodsClass;
  }

  onTabSelect(tabIndex: number) {
    this.activeTab = tabIndex;
  }
}
