import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from '@angular/core';

export type LoadingIndicatorSize = 'lg' | 'md' | 'sm';

/**
 * @name LoadingIndicatorComponent
 *
 * @description
 * Component that displays a loading spinner and message.
 */
@Component({
  selector: 'bb-loading-indicator-ui',
  templateUrl: './loading-indicator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LoadingIndicatorComponent implements OnInit {
  /**
   * The text to display under the loading indicator.
   */
  @Input() text?: string;
  /**
   * The size of the loading indicator.
   * Defaults to 'md'.
   */
  @Input() loaderSize: LoadingIndicatorSize = 'md';
  /**
   * Delay in milliseconds before the loading indicator display.
   * Default to 0.
   */
  @Input() showDelay = 0;
  /**
   * Defines whether gray background should be displayed.
   * Default to false (background hidden).
   */
  @Input() hasBackground = false;
  /**
   * Define if spinner should be shown inline without any default padding and margin.
   * Default to false (show default padding and margin).
   */
  @Input() inline = false;

  showLoadingState = false;

  constructor(private readonly ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.showLoadingState = true;
      this.ref.markForCheck();
    }, this.showDelay);
  }
}
