import { NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'bb-overview-list-card',
  templateUrl: './overview-list-card.component.html',
  imports: [RouterLink, NgTemplateOutlet],
})
export class OverviewListCardComponent {
  @Input() name = '';

  @Input() title?: string;

  @Input() subtitle?: string;

  @Input() imageSrc?: string;

  @Input({ required: true })
  get linkTo() {
    return this._linkTo;
  }

  set linkTo(value: string) {
    const urlAndHashArr = value.split('#');

    if (urlAndHashArr.length) {
      this._linkTo = urlAndHashArr[0];
      this.fragment = urlAndHashArr[1];
    } else {
      this._linkTo = value;
    }
  }

  private _linkTo = '';

  fragment?: string;
}
