import { Component, AfterViewInit, Injector } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { combineLatestWith, filter, map } from 'rxjs/operators';
import { googleAnalytics } from './script-tag-ga';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from '../environments/environment';
import { createCustomElement } from '@angular/elements';
import { DesignTokensComponent } from './ui/custom-elements/design-tokens/design-tokens.component';
import { DesignTokenListComponent } from './ui/custom-elements/design-token-list/design-token-list.component';
import { ComponentListComponent } from './ui/custom-elements/component-list/component-list.component';
import { OverviewListComponent } from './ui/custom-elements/overview-list/overview-list.component';

import { LayoutService } from '@backbase/ui-ang/layout';
import { Observable } from 'rxjs';
import { UsersnapService } from './services/usersnap.service';

@Component({
  selector: 'bb-design-system-app',
  templateUrl: './app.component.html',
  animations: [
    trigger('slideInOut', [
      state(
        'out',
        style({
          left: '*',
        }),
      ),
      state(
        'in',
        style({
          left: '0',
        }),
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ],
  standalone: false,
})
export class AppComponent implements AfterViewInit {
  cookieDialog = false;

  isMenuOpened$: Observable<boolean> = this.layoutService.isHighResolutionView$.pipe(
    combineLatestWith(this.layoutService.navigationExpanded$),
    map(([isDesktopView, isExpanded]) => {
      return isExpanded && !isDesktopView;
    }),
  );

  isMenuClosed$: Observable<boolean> = this.layoutService.isHighResolutionView$.pipe(
    combineLatestWith(this.layoutService.navigationExpanded$),
    map(([isDesktopView, isExpanded]) => {
      return !isExpanded && !isDesktopView;
    }),
  );

  constructor(
    private router: Router,
    private injector: Injector,
    public layoutService: LayoutService,
    readonly usersnapService: UsersnapService,
  ) {
    this.usersnapService.initialize();
    this.createCustomElements();

    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event: any) => {
      const url = event['url'];
      if (url !== null && url !== undefined && url !== '' && url.indexOf('null') < 0) {
        googleAnalytics(url);
      }
    });

    // eslint-disable-next-line no-console
    console.info(`
      Environment:

      ui-ang version ...................... ${environment.uiAngVersion}
    `);
  }

  ngAfterViewInit() {
    try {
      decodeURIComponent(document.cookie);
      const match = document.cookie.match(new RegExp('(^| )privacyCookie=([^;]+)'));
      if (!match || match[2] !== 'true') {
        this.cookieDialog = true;
      }
    } catch (err) {
      //cookie read error.
    }
  }

  saveAccept() {
    //store accepted cookie for 1 years
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 24 * 365);
    document.cookie = 'privacyCookie=true; ' + 'expires=' + date.toUTCString() + '; path=/';
    this.cookieDialog = false;
  }

  private createCustomElements() {
    // create elements to be used by menu-item > design-tokens-api
    const designTokensElement = createCustomElement(DesignTokensComponent, { injector: this.injector });
    customElements.define('bb-design-tokens', designTokensElement);

    // create elements to be used by overview pages
    const componentListElement = createCustomElement(ComponentListComponent, { injector: this.injector });
    customElements.define('bb-component-list', componentListElement);

    // create elements to be used by overview pages
    const overviewListElement = createCustomElement(OverviewListComponent, { injector: this.injector });
    customElements.define('bb-overview-list', overviewListElement);

    // create elements to be used by overview pages
    const designTokenListElement = createCustomElement(DesignTokenListComponent, { injector: this.injector });
    customElements.define('bb-design-token-list', designTokenListElement);
  }
}
