import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-currency-input-ui-showcase',
  templateUrl: 'currency-input-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class CurrencyInputShowcaseComponent {
  public withDecimalControl: UntypedFormControl;
  constructor() {
    this.withDecimalControl = new UntypedFormControl({
      amount: '-1234567.89',
      currency: 'USD',
    });
  }

  public withDecimal = {
    amount: undefined,
    currency: 'USD',
  };
  public withoutDecimal = {
    amount: undefined,
    currency: 'EUR',
  };
  public withoutCurrency = {
    amount: undefined,
  };
  public currencies: string[] = [
    'USD',
    'EUR',
    // Bahraini dinar (BHD) and Omani rial have a decimal precision of three
    'BHD',
    'OMR',
    // Malagasy ariary (MGA) is a non-decimal currency
    'MGA',
  ];
}
