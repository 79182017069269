import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
// Please replace relative path imports e.g. '../../*' with '@backbase/ui-ang'.
import { NotificationService } from '@backbase/ui-ang/notification';

@Component({
  selector: 'bb-input-password-example-ui',
  templateUrl: './input-password.example.component.html',
  standalone: false,
})
export class InputPasswordExampleComponent {
  changePasswordForm: UntypedFormGroup = this.formBuilder.group({
    password: ['', Validators.required],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
  ) {}

  onSubmit() {
    this.notificationService.showNotification({
      header: 'Password Changed',
      message: 'Password has been changed',
      modifier: 'success',
    });
  }
}
