import { Component, EventEmitter, Host, Input, OnDestroy, Optional, Output } from '@angular/core';
import { CollapsibleAccordionComponent, ICollapsible } from '@backbase/ui-ang/collapsible-accordion';

export type CollapsibleCardSize = 'sm' | 'md' | 'lg';

/**
 * @name CollapsibleCardComponent
 *
 * @description
 * Provides an expandable details-summary/body-header view.
 * Uses bb-collapsible-ui for a predefined template
 *
 * ### Accessibility
 * ariaLabelForIcon is discernible text for chevron icon
 */
@Component({
  selector: 'bb-collapsible-card-ui',
  templateUrl: 'collapsible-card.component.html',
  standalone: false,
})
export class CollapsibleCardComponent implements ICollapsible, OnDestroy {
  /**
   * Collapsible card open state
   * @default false
   */
  @Input()
  isOpen = false;

  /**
   * Accessible label for chevron icon.
   */
  @Input() ariaLabelForIcon = 'Chevron icon';
  /**
   * Accessible label for collapsible card
   */
  @Input('aria-label') ariaLabel: string | null = 'toggle';
  /**
   * Whether chevron should be displayed. Defaults to true.
   */
  @Input() hasChevron = true;
  /**
   * Whether card should be highlighted. Defaults to false.
   */
  @Input() highlight = false;
  /**
   * Whether card should has shadow effect. Defaults to true.
   */
  @Input() shadow = true;
  /**
   * Card size. Defaults to md.
   */
  @Input() size: CollapsibleCardSize = 'md';

  /**
   * Emits when the `isOpen` input is changed.
   */
  @Output() isOpenChange = new EventEmitter<boolean>();

  constructor(
    @Optional()
    @Host()
    private readonly bbCollapsibleAccordion: CollapsibleAccordionComponent,
  ) {
    if (this.bbCollapsibleAccordion) {
      this.bbCollapsibleAccordion.registerNestedPanel(this);
    }
  }

  ngOnDestroy() {
    if (this.bbCollapsibleAccordion) {
      this.bbCollapsibleAccordion.unregisterNestedPanel(this);
    }
  }

  toggle() {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
  }
}
