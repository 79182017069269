import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { PaymentCardNumberFormat, DefaultPaymentCardNumberFormat } from '@backbase/ui-ang/payment-card-number-pipe';
import { DomAttributesService } from '@backbase/ui-ang/services';
import { BackbaseDocs } from '@backbase/ui-ang/util';
import { AccountSelectorComponent, AccountSelectorDefaultItem } from '@backbase/ui-ang/account-selector';
import { Subject, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'bb-account-selector-ui-showcase',
  templateUrl: './account-selector-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class AccountSelectorShowcaseComponent {
  items: AccountSelectorDefaultItem[] = [
    {
      id: '1',
      name: 'Mrs J. Smith',
      balance: 102.1,
      availableBalance: 150,
      number: 'GB29NWBK60161331926819',
      currency: 'EUR',
      favorite: true,
      status: 'inactive',
      bankBranchCode: '01788902',
    },
    {
      id: '2',
      name: 'Mr J. Simposon',
      balance: 131230.1,
      availableBalance: 150000,
      number: 'GB29NWBK60161331926819',
      currency: 'EUR',
      favorite: false,
      type: 'accounts',
      bankBranchCode: '01788902',
    },
    {
      id: '3',
      name: 'Eddie',
      balance: 133,
      availableBalance: 150,
      number: 'GB29NWBK60161331926819',
      currency: 'USD',
      favorite: false,
      type: 'accounts',
      status: 'closed',
      bankBranchCode: '01788902',
    },
    {
      id: '4',
      name: 'Gerald Carr',
      balance: 400,
      availableBalance: 500,
      number: 'GB94BARC10201530093459',
      currency: 'USD',
      favorite: false,
      type: 'accounts',
      status: 'inactive',
      bankBranchCode: '01788902',
    },
    {
      id: '5',
      name: 'Jenny Hawkins',
      balance: 500,
      availableBalance: 850,
      number: 'GB33BUKB20201555555555',
      currency: 'USD',
      favorite: false,
      type: 'accounts',
      status: 'closed',
      bankBranchCode: '01788902',
    },
    {
      id: '6',
      name: 'Bob Sanders',
      balance: 300,
      availableBalance: 550,
      number: 'GB29NWBK60161331926819',
      currency: 'USD',
      favorite: false,
      type: 'accounts',
      status: 'closed',
      bankBranchCode: '01788902',
    },
    {
      id: '7',
      name: 'Ashley Lowe',
      balance: 900,
      availableBalance: 1000,
      number: 'GB94BARC10201530093459',
      currency: 'USD',
      favorite: false,
      type: 'accounts',
      status: 'inactive',
      bankBranchCode: '01788902',
    },
    {
      id: '8',
      name: 'George Mason',
      balance: 250,
      availableBalance: 450,
      number: 'GB33BUKB20201555555555',
      currency: 'USD',
      favorite: false,
      type: 'accounts',
      status: 'active',
      bankBranchCode: '01788902',
    },
    {
      id: '9',
      name: 'Wesley Harper',
      balance: 350,
      availableBalance: 550,
      number: 'GB94BARC10201530093459',
      currency: 'USD',
      favorite: false,
      type: 'accounts',
      status: 'active',
      bankBranchCode: '01788902',
    },
    {
      id: '10',
      name: 'Kristin Frazier',
      balance: 550,
      availableBalance: 750,
      number: 'GB33BUKB20201555555555',
      currency: 'USD',
      favorite: false,
      type: 'accounts',
      status: 'closed',
      bankBranchCode: '01788902',
    },
  ];

  typeahead = new Subject<string>();
  typeaheadSearchTerm = '';
  typeaheadItems$ = new BehaviorSubject([...this.items]);

  accountSelectors = [
    {
      labelId: this.domAttributesService.generateId(),
      heading: 'Single select',
      placeholder: "Select Account Single (doesn't close on select)",
      dataRole: 'account-selector-single-select',
      multiple: false,
      items: [...this.items],
      selectedItems: undefined,
      showCurrencySymbol: true,
      eventTriggered: undefined,
      eventState: undefined,
      position: 'auto',
      closeOnSelect: false,
      productNumberFormat: { length: 18, maskRange: [0, 12], segments: 4 },
      errorMessageId: undefined,
      invalid: undefined,
      typeahead: undefined,
      minTermLength: undefined,
      typeToSearchText: undefined,
      errorMessage: '',
      change: (self: any, item: any) => {
        self.selectedItem = item;
        self.eventTriggered = '$change';
        self.eventState = item;
      },
      search: (self: any, searchQ: any) => {
        self.eventTriggered = '$search';
        self.eventState = searchQ;
      },
      clearSearch: (self: any, event: any) => {
        self.eventTriggered = '$clearSearch';
        self.eventState = event;
      },
      scrollToEnd: (self: any, event: any) => {
        self.eventTriggered = '$scrollToEnd';
        self.eventState = event;
      },
    },
    {
      labelId: this.domAttributesService.generateId(),
      heading: 'Single select with custom not found template and filter enabled',
      placeholder: 'Select Account Single',
      dataRole: 'account-selector-single-select-no-text-found',
      multiple: false,
      items: [...this.items],
      selectedItems: undefined,
      eventTriggered: undefined,
      eventState: undefined,
      showCurrencySymbol: true,
      position: 'bottom',
      productNumberFormat: DefaultPaymentCardNumberFormat as PaymentCardNumberFormat,
      notFoundText: 'Nothing found custom text',
      markFirst: true,
      closeOnSelect: true,
      filterItems: true,
      change: (self: any, item: any) => {
        self.selectedItem = item;
        self.eventTriggered = '$change';
        self.eventState = item;
      },
      search: (self: any, searchQ: any) => {
        self.eventTriggered = '$search';
        self.eventState = searchQ;
      },
      clearSearch: (self: any, event: any) => {
        self.eventTriggered = '$clearSearch';
        self.eventState = event;
      },
      scrollToEnd: (self: any, event: any) => {
        self.eventTriggered = '$scrollToEnd';
        self.eventState = event;
      },
    },
    {
      labelId: this.domAttributesService.generateId(),
      heading: 'Single select with disabled attr',
      placeholder: 'Select Account Single',
      dataRole: 'account-selector-single-select-disabled',
      multiple: false,
      items: [...this.items],
      selectedItems: undefined,
      showCurrencySymbol: true,
      eventTriggered: undefined,
      eventState: undefined,
      position: 'auto',
      productNumberFormat: { length: 18, maskRange: [0, 0], segments: 4 },
      disabled: true,
      change: (self: any, item: any) => {
        self.selectedItem = item;
        self.eventTriggered = '$change';
        self.eventState = item;
      },
      search: (self: any, searchQ: any) => {
        self.eventTriggered = '$search';
        self.eventState = searchQ;
      },
      clearSearch: (self: any, event: any) => {
        self.eventTriggered = '$clearSearch';
        self.eventState = event;
      },
      scrollToEnd: (self: any, event: any) => {
        self.eventTriggered = '$scrollToEnd';
        self.eventState = event;
      },
    },
    {
      labelId: this.domAttributesService.generateId(),
      heading: 'Multiple select',
      placeholder: 'Select Multiple Accounts',
      dataRole: 'account-selector-multi-select',
      multiple: true,
      items: [...this.items],
      selectedItems: undefined,
      eventTriggered: undefined,
      eventState: undefined,
      showCurrencySymbol: true,
      position: 'auto',
      closeOnSelect: false,
      productNumberFormat: { length: 18, maskRange: [0, 0], segments: 4 },
      change: (self: any, item: any) => {
        self.selectedItem = item;
        self.eventTriggered = '$change';
        self.eventState = item;
      },
      search: (self: any, searchQ: any) => {
        self.eventTriggered = '$search';
        self.eventState = searchQ;
      },
      clearSearch: (self: any, event: any) => {
        self.eventTriggered = '$clearSearch';
        self.eventState = event;
      },
      scrollToEnd: (self: any, event: any) => {
        self.eventTriggered = '$scrollToEnd';
        self.eventState = event;
      },
    },
    {
      labelId: this.domAttributesService.generateId(),
      heading: 'Dropdown position top select',
      placeholder: 'Select Account Single Dropdown Position',
      dataRole: 'account-selector-single-select-position-top',
      multiple: false,
      items: [...this.items],
      selectedItems: undefined,
      eventTriggered: undefined,
      eventState: undefined,
      showCurrencySymbol: true,
      position: 'top',
      closeOnSelect: true,
      productNumberFormat: { length: 18, maskRange: [0, 12], segments: 4 },
      change: (self: any, item: any) => {
        self.selectedItem = item;
        self.eventTriggered = '$change';
        self.eventState = item;
      },
      search: (self: any, searchQ: any) => {
        self.eventTriggered = '$search';
        self.eventState = searchQ;
      },
      clearSearch: (self: any, event: any) => {
        self.eventTriggered = '$clearSearch';
        self.eventState = event;
      },
      scrollToEnd: (self: any, event: any) => {
        self.eventTriggered = '$scrollToEnd';
        self.eventState = event;
      },
    },
    {
      labelId: this.domAttributesService.generateId(),
      heading: 'Single select with Currency Code',
      placeholder: "Select Account Single (doesn't close on select)",
      dataRole: 'account-selector-single-select-with-currency',
      multiple: false,
      items: [...this.items],
      selectedItems: undefined,
      showCurrencySymbol: false,
      eventTriggered: undefined,
      eventState: undefined,
      position: 'auto',
      closeOnSelect: false,
      productNumberFormat: { length: 18, maskRange: [0, 12], segments: 4 },
      change: (self: any, item: any) => {
        self.selectedItem = item;
        self.eventTriggered = '$change';
        self.eventState = item;
      },
      search: (self: any, searchQ: any) => {
        self.eventTriggered = '$search';
        self.eventState = searchQ;
      },
      clearSearch: (self: any, event: any) => {
        self.eventTriggered = '$clearSearch';
        self.eventState = event;
      },
      scrollToEnd: (self: any, event: any) => {
        self.eventTriggered = '$scrollToEnd';
        self.eventState = event;
      },
    },
    {
      labelId: this.domAttributesService.generateId(),
      heading: 'Single select with Bank Branch Code display',
      placeholder: 'Select Account',
      dataRole: 'account-selector-single-select-with-branch-code',
      multiple: false,
      items: [...this.items],
      selectedItems: undefined,
      showCurrencySymbol: false,
      eventTriggered: undefined,
      showBankBranchCode: true,
      eventState: undefined,
      position: 'auto',
      closeOnSelect: true,
      productNumberFormat: { length: 18, maskRange: [0, 12], segments: 4 },
      change: (self: any, item: any) => {
        self.selectedItem = item;
        self.eventTriggered = '$change';
        self.eventState = item;
      },
      search: (self: any, searchQ: any) => {
        self.eventTriggered = '$search';
        self.eventState = searchQ;
      },
      clearSearch: (self: any, event: any) => {
        self.eventTriggered = '$clearSearch';
        self.eventState = event;
      },
      scrollToEnd: (self: any, event: any) => {
        self.eventTriggered = '$scrollToEnd';
        self.eventState = event;
      },
    },
    {
      labelId: this.domAttributesService.generateId(),
      heading: 'Single select with booked and available balances',
      placeholder: 'Select Account Single (doesn`t close on select)',
      dataRole: 'account-selector-single-select-both-balances-ordinary',
      multiple: false,
      items: [...this.items],
      selectedItems: undefined,
      showCurrencySymbol: true,
      eventTriggered: undefined,
      eventState: undefined,
      position: 'auto',
      closeOnSelect: false,
      productNumberFormat: { length: 18, maskRange: [0, 12], segments: 4 },
      change: (self: any, item: any) => {
        self.selectedItem = item;
        self.eventTriggered = '$change';
        self.eventState = item;
      },
      search: (self: any, searchQ: any) => {
        self.eventTriggered = '$search';
        self.eventState = searchQ;
      },
      clearSearch: (self: any, event: any) => {
        self.eventTriggered = '$clearSearch';
        self.eventState = event;
      },
      scrollToEnd: (self: any, event: any) => {
        self.eventTriggered = '$scrollToEnd';
        self.eventState = event;
      },
      showAvailableBalance: 'ordinary',
    },
    {
      labelId: this.domAttributesService.generateId(),
      heading: 'Single select with booked and available balances in reverse order',
      placeholder: 'Select Account Single (doesn`t close on select)',
      dataRole: 'account-selector-single-select-both-balances-reverse',
      multiple: false,
      items: [...this.items],
      selectedItems: undefined,
      showCurrencySymbol: true,
      eventTriggered: undefined,
      eventState: undefined,
      position: 'auto',
      closeOnSelect: false,
      productNumberFormat: { length: 18, maskRange: [0, 12], segments: 4 },
      change: (self: any, item: any) => {
        self.selectedItem = item;
        self.eventTriggered = '$change';
        self.eventState = item;
      },
      search: (self: any, searchQ: any) => {
        self.eventTriggered = '$search';
        self.eventState = searchQ;
      },
      clearSearch: (self: any, event: any) => {
        self.eventTriggered = '$clearSearch';
        self.eventState = event;
      },
      scrollToEnd: (self: any, event: any) => {
        self.eventTriggered = '$scrollToEnd';
        self.eventState = event;
      },
      showAvailableBalance: 'reverse',
    },
  ];

  accountSelectorValidation = {
    labelId: this.domAttributesService.generateId(),
    heading: 'Single select with required validation',
    placeholder: "Select Account Single (doesn't close on select)",
    dataRole: 'account-selector-required-validation',
    multiple: false,
    items: [...this.items],
    selectedItems: new UntypedFormControl(undefined, Validators.required),
    eventTriggered: undefined,
    eventState: undefined,
    position: 'auto',
    closeOnSelect: false,
    required: true,
    showError: false,
    productNumberFormat: { length: 18, maskRange: [0, 12], segments: 4 },
    change: (self: any, item: any) => {
      self.selectedItems.setValue({ ...item });
      self.eventTriggered = '$change';
      self.eventState = item;
      self.showError = self.selectedItems?.invalid;
    },
    search: (self: any, searchQ: any) => {
      self.eventTriggered = '$search';
      self.eventState = searchQ;
    },
    clearSearch: (self: any, event: any) => {
      self.eventTriggered = '$clearSearch';
      self.eventState = event;
    },
    scrollToEnd: (self: any, event: any) => {
      self.eventTriggered = '$scrollToEnd';
      self.eventState = event;
    },
    submit: (self: any) => {
      if (!self.selectedItems.value || self.selectedItems.value.length === 0) {
        self.showError = true;

        return;
      }
      self.showError = false;
    },
    reset: (self: any) => {
      self.selectedItems.reset();
      self.showError = false;
      this.accountSelectorWithValidation.resetAccountSelectorModel();
    },
    onBlur: (self: any) => {
      self.selectedItems.markAsTouched();
      self.showError = self.selectedItems?.touched && self.selectedItems?.invalid;
    },
  };

  accountSelectorTypeAhead = {
    labelId: this.domAttributesService.generateId(),
    heading: 'Single select with typeahead',
    placeholder: 'Select Account',
    dataRole: 'account-selector-single-select-with-typeahead',
    multiple: false,
    selectedItems: undefined,
    showCurrencySymbol: false,
    eventTriggered: undefined,
    showBankBranchCode: true,
    eventState: undefined,
    position: 'bottom',
    editableSearchTerm: true,
    clearSearchOnAdd: false,
    closeOnSelect: true,
    typeahead: this.typeahead,
    typeToSearchText: 'This search uses typeahead',
    minTermLength: 0,
    productNumberFormat: { length: 18, maskRange: [0, 12], segments: 4 },
    change: (self: any, item: any) => {
      self.selectedItem = item;
      self.eventTriggered = '$change';
      self.eventState = item;
    },
    search: (self: any, searchQ: any) => {
      self.eventTriggered = '$search';
      self.eventState = searchQ;
    },
    clearSearch: (self: any, event: any) => {
      self.eventTriggered = '$clearSearch';
      self.eventState = event;
      this.typeahead.next('');
    },
    scrollToEnd: (self: any, event: any) => {
      self.eventTriggered = '$scrollToEnd';
      self.eventState = event;
    },
  };

  accountSelectorWithDisabledSearch = {
    labelId: this.domAttributesService.generateId(),
    heading: 'Single select with typeahead',
    placeholder: 'Select Account',
    dataRole: 'account-selector-with-disabled-search',
    multiple: false,
    selectedItems: undefined,
    showCurrencySymbol: false,
    eventTriggered: undefined,
    showBankBranchCode: true,
    eventState: undefined,
    position: 'bottom',
    editableSearchTerm: true,
    clearSearchOnAdd: false,
    closeOnSelect: true,
    searchable: false,
    typeahead: this.typeahead,
    typeToSearchText: 'This search uses typeahead',
    minTermLength: 0,
    productNumberFormat: { length: 18, maskRange: [0, 12], segments: 4 },
    change: (self: any, item: any) => {
      self.selectedItem = item;
      self.eventTriggered = '$change';
      self.eventState = item;
    },
    search: (self: any, searchQ: any) => {
      self.eventTriggered = '$search';
      self.eventState = searchQ;
    },
    clearSearch: (self: any, event: any) => {
      self.eventTriggered = '$clearSearch';
      self.eventState = event;
      this.typeahead.next('');
    },
    scrollToEnd: (self: any, event: any) => {
      self.eventTriggered = '$scrollToEnd';
      self.eventState = event;
    },
  };

  @ViewChild('accountSelectorWithValidation') accountSelectorWithValidation!: AccountSelectorComponent;

  groupByItems(item: any) {
    return item.favorite;
  }

  searchFunc(term: string, item: any) {
    const SEARCHED_FIELDS = ['number', 'balance'];

    return SEARCHED_FIELDS.some((field: any) => item[field].toString().includes(term));
  }

  constructor(private domAttributesService: DomAttributesService) {
    this.typeahead.subscribe({
      next: (term) => {
        this.typeaheadSearchTerm = term || '';
        const filteredItems = this.items.filter((item) =>
          (item.name || '').toLowerCase().includes(this.typeaheadSearchTerm.toLowerCase()),
        );
        this.typeaheadItems$.next(filteredItems);
      },
    });
  }
}
