import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  QueryList,
} from '@angular/core';
import { InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  BB_DROPDOWN_CHANGE_DETECTION_REF_TOKEN,
  DropdownSingleSelectOptionComponent,
} from './dropdown-single-select-option.component';

interface Option {
  [key: string]: any;
}

/**
 * @name DropdownSingleSelectComponent
 *
 * @description
 * Component that displays a dropdown with a single select option.
 *
 * ### Accessibility
 * Current component provides option to pass needed accessibility
 * attributes. You need to take care of properties that are required in your case :
 *  - aria-label will be linked to the single select dropdown with label
 *  - set aria-labelledby with ID of another element in the DOM as dropdown's label.
 *  - set aria-describedby with ID of another element in the DOM with descriptive text about the dropdown
 *  - set aria-expanded with a boolean value based on the listbox display.
 *  - set aria-invalid to true when a selected option is not valid ans vice versa
 *  - aria-owns handles contextual relationship between a parent and its child elements,
 *    in this case between dropdown menu and dropdown items, use the id from the input binding.
 *
 */
@Component({
  selector: 'bb-dropdown-single-select-ui',
  templateUrl: './dropdown-single-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownSingleSelectComponent),
      multi: true,
    },
    {
      provide: BB_DROPDOWN_CHANGE_DETECTION_REF_TOKEN,
      useExisting: ChangeDetectorRef,
    },
  ],
  standalone: false,
})
export class DropdownSingleSelectComponent extends InputBaseComponent {
  /**
   * The placeholder for the select. Defaults to an empty string;
   */
  @Input() placeholder = '';
  /**
   * This Boolean attribute determine if default option as placeholder is displayed. Defaults to false.
   */
  @Input() defaultOptionAsPlaceholder = false;

  /**
   * The value for the input field. It can be a list of strings or list of objects.
   * If the value is a list of objects, you have to provide the `displayAttributePath` property.
   */
  @Input() options: Array<Object | string> = [];

  /**
   * The child option components of type DropdownSingleSelectOptionComponent
   */
  @ContentChildren(DropdownSingleSelectOptionComponent)
  contentOptions: QueryList<DropdownSingleSelectOptionComponent> | undefined;

  /**
   * In case the list of options is a list of objects,
   * this property will set the path to the object property which value will be used to display the option.
   * Undefined will assume the options are strings.
   * Child Nodes should be seperated by '.'
   */
  @Input() displayAttributePath?: string;

  // TODO: after backbase-theme-v2 released. Change default value to 'corner-down'.
  /**
   * Right corner icon name.
   * Default name 'toggle-down'
   */
  @Input() iconName = 'toggle-down';

  /**
   * Allowing override the option comparison algorithm for tracking identities when
   * checking for change
   */
  @Input() compareWithFn: (a: any, b: any) => boolean = (a, b) => a === b;

  constructor(protected readonly cd: ChangeDetectorRef) {
    super(cd);
  }

  getOptionDisplayText(option: Object | string) {
    return this.displayAttributePath && typeof option === 'object' && option !== null
      ? this.resolvePath(this.displayAttributePath, option as Option)
      : option;
  }

  private resolvePath(path: string, object: Option): string {
    if (!object && !path) {
      return '';
    }
    let paths;
    let propName: string;
    if (path.match(/^\[\d\]/) !== null) {
      paths = path.replace(/^[\[\]]/g, '').split(/\./);
      propName = paths[0].replace(/\]/, '')[0];
    } else {
      paths = path.split(/[\.\[]/);
      propName = paths[0];
    }

    const remainingPath = paths
      .slice(1)
      .reduce((result: string[], item: string) => {
        if (item) {
          if (item.match(/^\d\]/)) {
            item = '[' + item;
          }
          result.push(item);
        }

        return result;
      }, [])
      .join('.');

    if (!remainingPath) {
      const propValue = object[propName];

      return typeof propValue === 'string' ? propValue : propValue ? String(propValue) : '';
    } else if (object.hasOwnProperty(propName)) {
      return this.resolvePath(remainingPath, object[propName]);
    } else {
      return '';
    }
  }
}
