import { Component } from '@angular/core';

@Component({
  selector: 'bb-iban-pipe-example-ui',
  templateUrl: './iban-pipe.example.component.html',
  standalone: false,
})
export class IbanPipeExampleComponent {
  productNumber = 'NL81TRIO0212471066';
}
