import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  LOCALE_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import BigNumber from 'bignumber.js';
import { getLocaleNumberSymbol, getNumberOfCurrencyDigits, NumberSymbol } from '@angular/common';
import { idListAttr } from '@backbase/ui-ang/util';

/**
 * @name AmountInputComponent
 *
 * ### Accessibility
 * Current component provide option to pass needed accessibility
 * attributes. You need to take care of properties that are required in your case :
 *  - set aria-describedby with ID of another element in the DOM with descriptive text about the amount input,
 *    by default it is set to div defining the type of currency
 *  - set aria-invalid with boolean value when the entered input value is not valid
 *  - set aria-labelledby with ID of another element in the DOM as input's label
 *
 * @description
 * Component that displays a text input.
 */
@Component({
  selector: 'bb-amount-input-ui',
  templateUrl: './amount-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmountInputComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class AmountInputComponent extends InputBaseComponent {
  @HostBinding('class') inputClass = 'bb-amount-input';

  /**
   * Unique ID used for the accessibility property. New value is created when component is initialized.
   *
   * @internal
   */
  readonly componentUniqueId = `${this.id}-currency`;
  /**
   * The ID of the text hint for the input field.
   */
  readonly textHintId = `${this.id}-text-hint`;
  /**
   * Utility function for use in template
   */
  public idListAttr = idListAttr;

  private readonly groupSeparator: string;
  private readonly decimalSeparator: string;
  private readonly nonNumbersAndDecimalSeparatorRegex: RegExp;
  private readonly endWithDecimal: RegExp;

  /**
   * The format used for bignumber;
   */
  private readonly format: BigNumber.Format;

  /**
   * The placeholder for the text input. Defaults to an empty string.
   */
  @Input() placeholder = '';
  /**
   * The maxLength for the text input.
   */
  @Input() maxLength: number | undefined;
  /**
   * classnames for the wrapper div
   */
  @Input() wrapperClasses = '';
  /**
   * Whether currency local should be transformed to symbol.
   * Default value true.
   */
  @Input() mapCurrency = true;

  /**
   * Displays currency either as currency symbol or value, if provided.
   */
  @Input()
  get currency(): string | undefined {
    return this._currency;
  }
  set currency(value: string | undefined) {
    if (value) {
      this._currency = value;
      this._decimals = getNumberOfCurrencyDigits(value);
    }
  }

  private _currency = '';
  private _decimals = 2;

  /**
   * Whether to automatically add decimal places to the input value. Defaults to `false`.
   */
  @Input() autoDecimal = false;
  /**
   * Flag to use dot(.) symbol as a decimal separator for the amount value provided to the component.
   * Default value is false which means it expect a locale based decimal separator
   */
  @Input() isDotDecimalSeparator = false;
  /**
   * Provided to add a text hint for the input field.
   */
  @Input() textHint = '';
  /**
   * Whether we swap places for the currency and value for the input field.
   * The default value is false.
   * If true, the currency will appear on the right and the value on the left of the input field.
   */
  @Input() swapCurrencyAndValue = false;
  /**
   * ElementRef for amount input.
   */
  @ViewChild('amountInput', { static: true }) private amountEl!: ElementRef<HTMLInputElement>;

  constructor(
    cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) private readonly locale: string,
    private readonly renderer: Renderer2,
  ) {
    super(cd);
    this.groupSeparator = getLocaleNumberSymbol(this.locale, NumberSymbol.CurrencyGroup);
    this.decimalSeparator = getLocaleNumberSymbol(this.locale, NumberSymbol.CurrencyDecimal);
    this.nonNumbersAndDecimalSeparatorRegex = new RegExp(`[^${this.decimalSeparator}\\d]`, 'g');
    this.endWithDecimal = new RegExp(`[${this.decimalSeparator}]$`, 'g');
    this.format = {
      groupSeparator: this.groupSeparator,
      decimalSeparator: this.decimalSeparator,
      groupSize: 3,
    };
  }

  writeValue(model: any): void {
    const sanitizedInput = model ?? '';

    if (typeof sanitizedInput !== 'string') {
      return;
    }

    const isNumericType = !isNaN(Number(sanitizedInput));
    const currentValue =
      isNumericType && !this.isDotDecimalSeparator
        ? sanitizedInput.replace('.', this.decimalSeparator)
        : sanitizedInput;
    const decSeparatorRegex = this.isDotDecimalSeparator ? /[^.\d]/ : this.nonNumbersAndDecimalSeparatorRegex;
    const numeric = currentValue.replace(decSeparatorRegex, '').slice(0, this.maxLength) || '';

    this.renderer.setProperty(this.amountEl.nativeElement, 'value', this.formatAmount(numeric));

    this.updateOutputValue(numeric.replace(this.endWithDecimal, ''));
  }

  private formatAmount(numeric: string): string {
    if (!numeric) return numeric;

    // we check `.` on top of the existing decimal separator because
    // isDotDecimalSeparator does not fully support `.` yet
    const decimalSeparatorRegex = this.isDotDecimalSeparator
      ? new RegExp(`[\.\\${this.decimalSeparator}]`)
      : new RegExp(`\\${this.decimalSeparator}`);

    if (decimalSeparatorRegex.test(numeric)) {
      const [int, dec] = numeric.split(decimalSeparatorRegex);

      return new BigNumber(int || 0).toFormat(this.format) + this.decimalSeparator + dec.slice(0, this._decimals);
    } else {
      return new BigNumber(numeric).toFormat(this.format);
    }
  }

  private updateAmountInput() {
    const amountEl = this.amountEl.nativeElement;
    const numeric = amountEl.value?.replace(this.nonNumbersAndDecimalSeparatorRegex, '').slice(0, this.maxLength) || '';

    this.renderer.setProperty(amountEl, 'value', this.formatAmount(numeric));
  }

  private setSelection(start: number, end: number): void {
    const amountEl = this.amountEl.nativeElement;
    this.renderer.setProperty(amountEl, 'selectionStart', start);
    this.renderer.setProperty(amountEl, 'selectionEnd', end);
  }

  correctInputValue() {
    this.onBlur();
    const amountEl = this.amountEl?.nativeElement;
    if (amountEl.value) {
      const numeric = amountEl.value.replace(this.endWithDecimal, '');
      this.renderer.setProperty(amountEl, 'value', numeric);
      this.updateOutputValue(numeric.replace(this.nonNumbersAndDecimalSeparatorRegex, ''));
    }
  }

  /**
   * Event handler for backspace key press, and check if correct number is deleted
   *
   * @param el - The HTML input element.
   */
  onBackspace(el: HTMLInputElement) {
    if (this.autoDecimal && el.selectionStart && el.value[el.selectionStart - 1] === this.groupSeparator) {
      this.setSelection(el.selectionStart - 1, el.selectionStart - 1);
    }
  }

  onPress($event: KeyboardEvent) {
    const key = $event.key;
    const elm = this.amountEl.nativeElement;
    const value = elm.value;
    if (value) {
      const isDotExist = value.includes(this.decimalSeparator) && key === this.decimalSeparator;
      const regexString = `^[\\d${this.groupSeparator}]*[${this.decimalSeparator}]?\\d{0,${this._decimals}}$`;
      const formattedCurrencyRegex = new RegExp(regexString, 'g');
      const decimalStructure = formattedCurrencyRegex.test(value);
      if (isDotExist || !decimalStructure) {
        const pos = elm.selectionStart;
        if (value.indexOf(this.decimalSeparator) === pos) {
          this.setSelection(pos + 1, pos + 1);
        }
        $event.preventDefault();
      }
    }
  }

  onInput() {
    const elm = this.amountEl.nativeElement;
    const value = elm.value.trim();
    const cursorPos = elm.selectionStart || 0;
    const oldPos = value.length - cursorPos;
    this.updateAmountInput();
    const formatted = elm.value.replace(this.nonNumbersAndDecimalSeparatorRegex, '').replace(this.endWithDecimal, '');
    if (formatted) {
      if (value.length === 1 && value !== this.decimalSeparator) {
        const newValue = this.autoDecimal ? value + this.decimalSeparator + '0'.repeat(this._decimals) : value;
        this.renderer.setProperty(elm, 'value', newValue);
        this.setSelection(1, 1);
      } else {
        let newpos = elm.value.length - oldPos;
        if (value.includes(this.decimalSeparator) && cursorPos > value.indexOf(this.decimalSeparator)) {
          newpos++;
        }
        this.setSelection(newpos, newpos);
      }
    }
    this.updateOutputValue(formatted);
  }

  private updateOutputValue(value: string): void {
    this.onValueChange(value.replace(this.decimalSeparator, '.'));
  }
}
