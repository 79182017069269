import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Optional,
  Renderer2,
  Self,
  ViewChild,
} from '@angular/core';
import { InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { NgControl } from '@angular/forms';
import { DomAttributesService } from '@backbase/ui-ang/services';

/**
 * @name InputNumberComponent
 *
 * @description
 * Component that displays a text input.
 *
 * ### Accessibility
 * Current component provide option to pass needed accessibility
 * attributes. You need to take care of properties that are required in your case :
 *  - role
 *  - aria-activedescendant
 *  - aria-describedby
 *  - aria-expanded
 *  - aria-invalid
 *  - aria-errormessage (Always paired with aria-invalid,when aria-invalid is true error message is read)
 *  - aria-label
 *  - aria-labelledby
 *  - aria-owns
 *
 */
@Component({
  selector: 'bb-input-number-ui',
  templateUrl: './input-number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InputNumberComponent extends InputBaseComponent implements AfterViewInit {
  private _min?: number;

  /**
   * The placeholder for the number input. Defaults to an empty string;
   */
  @Input() placeholder = '';
  /**
   * The maximum value to accept.
   */
  @Input() max: number | undefined;
  /**
   * The minimum value to accept for this input.
   */
  @Input() set min(value: number | undefined) {
    this._min = value;
  }

  get min() {
    if (this.allowNegativeSign) {
      return this._min;
    }

    if (!this._min || this._min < 0) {
      return 0;
    }

    return this._min;
  }
  /**
   * A stepping interval to use when using up and down arrows to adjust the value
   */
  @Input() step: number | undefined;
  /**
   * Whether the number input is readonly.
   */
  @Input() readonly = false;

  /**
   * The autocomplete value of the enclosed input control.
   */
  @Input() autocomplete: string | undefined;

  /**
   * Text used to describe the number input for screen readers.
   */
  @Input()
  ariaLabel = $localize`:@@bb-input-number-ui.input-number.aria-label:Number Input`;
  @ViewChild('inputNumber') inputEl: ElementRef | undefined;

  /**
   * If `true` then the under-laying input control will behave as a default number input.
   */
  @Input() allowNonDigits = false;

  /**
   * If `true` then the under-laying input control will allow negative sign as well.
   * Known issue is that it is possible to insert the negative sign anywhere in the field, not only at the beginning.
   * Inserting the negative sign after a digit will render the value empty.
   */
  @Input() allowNegativeSign = false;

  constructor(
    private readonly domAttrService: DomAttributesService,
    private readonly elem: ElementRef,
    private readonly renderer2: Renderer2,
    protected readonly cd: ChangeDetectorRef,
    @Self() @Optional() public parentFormControl: NgControl,
  ) {
    super(cd);

    if (this.parentFormControl) {
      this.parentFormControl.valueAccessor = this;
    }
  }

  /* eslint-disable-next-line no-null/no-null */
  private oldInputValue: string | null = null;

  onInput(inputNumber: HTMLInputElement) {
    if (!inputNumber.value && this.oldInputValue !== null) {
      inputNumber.value = this.oldInputValue;
      this.onValueChange(inputNumber.value);
    }
    /* eslint-disable-next-line no-null/no-null */
    this.oldInputValue = null;
  }

  onPaste(inputNumber: HTMLInputElement) {
    this.oldInputValue = inputNumber.value;
  }

  ngAfterViewInit(): void {
    if (!this.ariaLabel) {
      this.domAttrService.moveAriaAttributes(
        this.elem.nativeElement,
        this.inputEl && this.inputEl.nativeElement,
        this.renderer2,
      );
    }
  }
}
