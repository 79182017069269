import { Component } from '@angular/core';
// Please replace relative path imports e.g. '../*' with '@backbase/ui-ang'.
import { AccountNumberType } from '@backbase/ui-ang/account-number-pipe';

@Component({
  selector: 'bb-account-number-pipe-example-ui',
  templateUrl: './account-number-pipe.example.component.html',
  standalone: false,
})
export class AccountNumberPipeExampleComponent {
  productNumber = 'IBAN1234567891234567';
  numberType: AccountNumberType = 'iban';
}
