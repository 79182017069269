import { Component } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ValidationErrorComponentModel } from '@backbase/ui-ang/control-error-handler';

@Component({
  selector: 'bb-custom-error-showcase',
  template: `
    @if (text) {
      <small @fade [id]="errorId" role="alert" class="bb-input-validation-message">
        Custom error component - {{ text }}
      </small>
    }
  `,
  animations: [
    trigger('fade', [
      transition('void => *', [style({ opacity: 0, height: '0px' }), animate(150, style({ opacity: 1, height: '*' }))]),
    ]),
  ],
  standalone: false,
})
export class CustomErrorShowcaseComponent implements ValidationErrorComponentModel {
  errorId = 'testID';
  text: string | undefined;
}

export const customErrorMessagesShowcase = { required: () => 'My Field is required' };
