import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'bb-fieldset-example-ui',
  templateUrl: './fieldset.example.component.html',
  standalone: false,
})
export class FieldsetExampleComponent {
  vForm: UntypedFormGroup = this.formBuilder.group({
    anonymous: false,
    personalInformation: this.formBuilder.group({
      name: [''],
      surname: [''],
    }),
  });

  constructor(private formBuilder: UntypedFormBuilder) {}
}
