@if (label) {
  <label class="bb-dropdown-single-select__label form-label" for="{{ id }}" [attr.data-role]="'label'">{{
    label
  }}</label>
}
<!-- Note: in HTML readonly is not supported for <select> like it is for <input> elements -->
<select
  [(ngModel)]="value"
  class="form-control bb-dropdown__select"
  (blur)="onBlur()"
  (change)="onValueChange()"
  (focus)="onFocus()"
  [compareWith]="compareWithFn"
  [attr.aria-label]="ariaLabel"
  [attr.aria-labelledby]="ariaLabelledby"
  [attr.aria-describedby]="ariaDescribedby"
  [attr.aria-expanded]="ariaExpanded"
  [attr.role]="role"
  [attr.aria-invalid]="ariaInvalid"
  [attr.aria-owns]="ariaOwns"
  [disabled]="disabled"
  [required]="required"
  id="{{ id }}"
  [bbFocus]="autofocus"
  [attr.data-role]="'dropdown'"
  [attr.data-id]="id ? id + '_dropdown-single-select_select' : undefined"
>
  @if (defaultOptionAsPlaceholder && placeholder) {
    <option value="">
      {{ placeholder }}
    </option>
  }
  @if (!defaultOptionAsPlaceholder && placeholder) {
    <option class="bb-dropdown-single-select__option-hidden" value="" disabled>
      {{ placeholder }}
    </option>
  }
  @for (option of options; track $index) {
    <option [ngValue]="option">
      {{ getOptionDisplayText(option) }}
    </option>
  }
  @for (option of contentOptions; track option.value) {
    <option [ngValue]="option.value">
      {{ option.label }}
    </option>
  }
</select>
<div class="bb-dropdown__icon">
  <bb-icon-ui color="muted" [name]="iconName" size="md"></bb-icon-ui>
</div>
