import { Component, ContentChild, Directive, EventEmitter, Input, Optional, Output, TemplateRef } from '@angular/core';
import { ModalComponent } from '../modal.component';

@Directive({
  selector: 'ng-template[bbCustomModalFooter]',
  standalone: false,
})
export class CustomModalFooterDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'bb-modal-footer-ui',
  templateUrl: './modal-footer.component.html',
  standalone: false,
})
export class ModalFooterComponent {
  @ContentChild(CustomModalFooterDirective, { static: true }) customModalFooter: CustomModalFooterDirective | undefined;

  readonly hostRef: ModalFooterComponent = this;

  constructor(@Optional() private readonly modal: ModalComponent) {}
  /**
   * The text of the confirm button.
   */
  @Input() confirmText?: string;
  /**
   * The text of the cancel button.
   */
  @Input() cancelText?: string;
  /**
   * The event that's fired after confirm button is pressed. Can be used with (click)="dialogRef.onConfirm()"
   * where dialogRef is a templateRef on <bb-dialog-ui #dialogRef />
   */
  @Output() confirm = new EventEmitter();
  /**
   * The event that's fired after cancel button is pressed. Can be used with (click)="dialogRef.onCancel()"
   * where dialogRef is a templateRef on <bb-dialog-ui #dialogRef />
   */
  @Output() cancel = new EventEmitter();

  onCancel($event?: any) {
    if (this.modal) {
      this.modal.dismissModal($event);
    } else {
      this.cancel.emit($event);
    }
  }

  onConfirm($event?: any) {
    if (this.modal) {
      this.modal.closeModal($event);
    } else {
      this.confirm.emit($event);
    }
  }
}
