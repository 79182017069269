import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';
import { BehaviorSubject } from 'rxjs';
import { DateSelectionModel } from '@backbase/ui-ang/input-datepicker';
import { DROPDOWN_TEMPLATE_MENU_OPTIONS } from '@backbase/ui-ang/dropdown-menu';
import { NotificationService } from '@backbase/ui-ang/notification';

@Component({
  selector: 'bb-modal-ui-showcase',
  templateUrl: './modal-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class ModalShowcaseComponent {
  console = console;
  smallModal = false;
  defaultModal = false;
  largeModal = false;
  passCodeModal = false;
  actionModal = false;
  threeBtnModal = false;
  leftHeaderModal = false;
  scrollableModal = false;
  longModal = false;
  imageModal = false;
  datepickerModal = false;
  modalLower = false;
  modalUpper = false;
  modalWithDropdown = false;
  modalWithAccountSelector = false;
  modalWithNotificationAfterClose = false;

  passwordSize = 5;
  charsArray: number[] = Array.from(Array(this.passwordSize).keys());
  focusedDate$$ = new BehaviorSubject<DateSelectionModel | undefined>(undefined);
  predefinedDate = new Date('2019-10-29T00:00').toISOString();
  dropdownTemplateMenuOptions = DROPDOWN_TEMPLATE_MENU_OPTIONS;
  accountSelectorItems = [
    {
      id: '1',
      name: 'Mrs J. Smith',
      balance: 102.1,
      number: 'GB29NWBK60161331926819',
      currency: 'EUR',
      favorite: true,
      status: 'inactive',
      bankBranchCode: '01788902',
    },
    {
      id: '2',
      name: 'Mr J. Simposon',
      balance: 131230.1,
      number: 'GB29NWBK60161331926819',
      currency: 'EUR',
      favorite: false,
      type: 'accounts',
      bankBranchCode: '01788902',
    },
    {
      id: '3',
      name: 'Eddie',
      balance: 133,
      number: 'GB29NWBK60161331926819',
      currency: 'USD',
      favorite: false,
      type: 'accounts',
      status: 'closed',
      bankBranchCode: '01788902',
    },
  ];

  constructor(private notificationService: NotificationService) {}

  focusedDate = (event: DateSelectionModel) => {
    const { date = null, isSelecting = false } = event;

    this.focusedDate$$.next({
      date,
      isSelecting,
    });
  };

  showNotification() {
    this.notificationService.showNotification({
      header: 'Notification',
      message: 'Notification message',
      dismissible: false,
    });
  }
}
