import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'bb-collapsibles-page-ui',
    templateUrl: './bb-collapsibles-page-ui.component.html',
    styles: [],
    standalone: false,
})
export class BbCollapsiblesPageUiComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
