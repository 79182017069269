import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'bb-input-validation-message-example-ui',
  templateUrl: './input-validation-message.example.component.html',
  standalone: false,
})
export class InputValidationMessageExampleComponent {
  vForm: UntypedFormGroup = this.formBuilder.group({
    name: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(5),
      ]),
    ],
  });

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  isFieldValid(fieldName: string) {
    const control = this.getControl(fieldName);

    return control && (control.valid || control.untouched);
  }

  private getControl(fieldName: string) {
    return this.vForm?.controls[fieldName];
  }

  hasError(field: string, type: string) {
    const fieldControl = this.getControl(field);
    if (!fieldControl || !fieldControl.errors) {
      return;
    }

    return fieldControl.errors[type];
  }

  hasLengthError(field: string) {
    const fieldControl = this.getControl(field);
    if (!fieldControl || !fieldControl.errors) {
      return;
    }

    return fieldControl.errors.minlength;
  }

  hasRequiredError(field: string) {
    const fieldControl = this.getControl(field);
    if (!fieldControl || !fieldControl.errors) {
      return;
    }

    return fieldControl.errors.required;
  }
}
