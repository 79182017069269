import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'bb-input-inline-edit-example-ui',
  templateUrl: './input-inline-edit.example.component.html',
  standalone: false,
})
export class InputInlineEditExampleComponent {
  form: UntypedFormGroup = this.formBuilder.group({
    description: ['Monthly Rent'],
  });

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  onAcceptDescription(description: any) {
    this.form.patchValue({
      description,
    });
  }
}
