import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'bb-rich-text-editor-example-ui',
  templateUrl: './rich-text-editor.example.component.html',
  standalone: false,
})
export class RichTextEditorExampleComponent {
  vForm: UntypedFormGroup = this.formBuilder.group({
    remarks: '',
  });

  constructor(private readonly formBuilder: UntypedFormBuilder) {}
}
