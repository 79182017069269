import { Component } from '@angular/core';

@Component({
  selector: 'bb-value-diff-ui-example',
  templateUrl: './value-diff-example.component.html',
  standalone: false,
})
export class ValueDiffExampleComponent {
  oldValue = 'Old Value';
  newValue = 'New Value';
}
