import { inject, Pipe, PipeTransform } from '@angular/core';
import { AVATAR_CONFIG_TOKEN, defaultAvatarInitialsFn } from './avatar.token-config';

@Pipe({
  name: 'avatarInitials',
  standalone: false,
})
export class AvatarInitialsPipe implements PipeTransform {
  private readonly customInitialsFn = inject(AVATAR_CONFIG_TOKEN, { optional: true });

  transform(text: string) {
    const initialsFn = this.customInitialsFn?.initialsFn ?? defaultAvatarInitialsFn;

    return initialsFn(text);
  }
}
