import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, Optional, SkipSelf } from '@angular/core';
import { Subscription } from 'rxjs';

export interface ICollapsible extends OnDestroy {
  toggle: () => void;
  isOpen: boolean;
  isOpenChange: EventEmitter<boolean>;
}

/**
 * @name CollapsibleAccordionComponent
 *
 * @description
 * Component that groups Collapsible components to show them in an accordion fashion.
 * It can assure that only one Collapsible panel can be opened at a time.
 */
@Component({
  selector: 'bb-collapsible-accordion-ui',
  templateUrl: 'collapsible-accordion.component.html',
  standalone: false,
})
export class CollapsibleAccordionComponent implements AfterContentInit, OnDestroy {
  /**
   * Assures that only one Collapsible panel can be opened at a time.
   */
  @Input() closeOthers = false;
  /**
   * When true displays the first panel on load.
   */
  @Input() openFirst = false;
  /**
   * When true the Accordion will inherit the parent Accordion behaviour.
   */
  @Input()
  set obeyParentAccordion(obey: boolean) {
    if (!this.parentAccordion) {
      return;
    }

    if (obey) {
      this.parentAccordion.registerNestedAccordion(this);
    } else {
      this.parentAccordion.unregisterNestedAccordion(this);
    }
  }

  private readonly collapsiblePanels = new Map<ICollapsible, Subscription>();
  private readonly nestedAccordions = new Set<CollapsibleAccordionComponent>();

  constructor(
    @Optional()
    @SkipSelf()
    private readonly parentAccordion: CollapsibleAccordionComponent,
  ) {}

  ngOnDestroy() {
    this.obeyParentAccordion = false;
  }

  registerNestedAccordion(childAccordion: CollapsibleAccordionComponent) {
    this.nestedAccordions.add(childAccordion);
  }

  unregisterNestedAccordion(childAccordion: CollapsibleAccordionComponent) {
    this.nestedAccordions.delete(childAccordion);
  }

  registerNestedPanel(panel: ICollapsible) {
    const subscription = panel.isOpenChange.subscribe({
      next: (isOpen: boolean) => this.closeOtherPanels(panel)(isOpen),
    });
    this.collapsiblePanels.set(panel, subscription);
  }

  unregisterNestedPanel(panel: ICollapsible) {
    const subscription = this.collapsiblePanels.get(panel);
    if (subscription) {
      subscription.unsubscribe();
    }
    this.collapsiblePanels.delete(panel);
  }

  expandAll() {
    if (this.closeOthers) {
      return;
    }
    this.setPanelsState(true);
    this.setNestedAccordionsState(true);
  }

  collapseAll() {
    this.setPanelsState(false);
    this.setNestedAccordionsState(false);
  }

  ngAfterContentInit() {
    if (this.openFirst) {
      const firstPanel = this.collapsiblePanels.keys().next().value;

      if (firstPanel) {
        firstPanel.isOpen = true;
      }
    }
  }

  private readonly closeOtherPanels = (panel: ICollapsible) => (value: boolean) => {
    if (!value) {
      return;
    }

    if (!this.closeOthers) {
      return;
    }

    this.collapsiblePanels.forEach((_, item) => {
      if (panel !== item) {
        item.isOpen = false;
      }
    });
  };

  private setPanelsState(isOpen: boolean) {
    this.collapsiblePanels.forEach((_, panel) => (panel.isOpen = isOpen));
  }

  private setNestedAccordionsState(isOpen: boolean) {
    this.nestedAccordions.forEach((accordion) => {
      if (isOpen) {
        accordion.expandAll();
      } else {
        accordion.collapseAll();
      }
    });
  }
}
