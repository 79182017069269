import { DecimalPipe, getLocaleNumberSymbol, getNumberOfCurrencyDigits, NumberSymbol } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import BigNumber from 'bignumber.js';
import { getInputNextId } from '@backbase/ui-ang/base-classes';
import { DomAttributesService } from '@backbase/ui-ang/services';
import { idListAttr } from '@backbase/ui-ang/util';
import { CurrencyInputConfig, CURRENCY_INPUT_CONFIG_TOKEN } from './currency-input.token-config';
import { combineLatest, filter, Subject, takeUntil } from 'rxjs';

const defaultIntLength = 13;
const defaultDecimalPrecision = 2;

export interface CurrencyFieldsState {
  currency: boolean;
  integer: boolean;
  decimal: boolean;
}

/**
 * @name CurrencyInputComponent
 *
 * @description
 * Component to display and manage the currency and the amount
 *
 * ### Global configuration token
 * `CURRENCY_INPUT_CONFIG_TOKEN` enables you to globally set the same configuration for all instances of the `CurrencyInputComponent` in your project.
 *
 * *Note:* The token overwrites the default value only. If you have provided a value as a property on a specific component, the token is not able to overwrite it.
 *
 * The following properties can be overwritten using the token:
 *  - `allowNegativeValue`
 *  - `currencies`
 *  - `placeholder`
 *
 * #### Usage notes
 * The following is an example of how to use the token:
 *
 * ```typescript
 * import { CURRENCY_INPUT_CONFIG_TOKEN } from '@backbase/ui-ang/currency-input';
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 * import { AppModule } from './app/app.module';
 *
 * const currencyInputConfig = {
 *   allowNegativeValue: true
 * }
 *
 * platformBrowserDynamic().bootstrapModule(AppModule, {
 *   providers: [{ provide: CURRENCY_INPUT_CONFIG_TOKEN, useValue: currencyInputConfig }]
 * });
 * ```
 *
 * ### Accessibility
 * Current component provide option to pass needed accessibility
 * attributes. You need to take care of properties that are required in your case :
 *  - aria-describedby
 *  - aria-invalid
 *  - aria-labelledby
 *  - integerLabelSrOnly
 *  - decimalLabelSrOnly
 *
 */
@Component({
  selector: 'bb-currency-input-ui',
  templateUrl: './currency-input.component.html',
  providers: [DecimalPipe],
  standalone: false,
})
export class CurrencyInputComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges, AfterViewInit {
  private currencyList: Array<string> = [];
  private currencyDisabledState: CurrencyFieldsState = {
    currency: false,
    integer: false,
    decimal: false,
  };
  private currency: string | undefined;
  private intMaxLen: number | undefined;
  readonly decimalSeparator: string = getLocaleNumberSymbol(this.locale, NumberSymbol.CurrencyDecimal);
  private readonly localeSeparatorSymbols = [
    this.decimalSeparator,
    getLocaleNumberSymbol(this.locale, NumberSymbol.CurrencyGroup),
  ];

  private _decMaxLen: number | undefined;
  private destroy$ = new Subject<void>();

  /**
   * Configure the maximum number of decimals.
   * When greater than the default number of decimals for the specified currency, this value will take precedence.
   * Configuring this value can affect the input placeholder.
   */
  @Input()
  get decMaxLen(): number | undefined {
    return typeof this._decMaxLen === 'number' ? this._decMaxLen : this.getDecimalDigits(this.currency || '');
  }

  set decMaxLen(value: number | undefined) {
    this._decMaxLen = value;
  }

  /**
   * Number of minimum currency precision. Defaults to 2.
   */
  @Input() decMinLen = defaultDecimalPrecision;

  /**
   * Non-configurable placeholder for the decimal input.
   * Displays a zero digit for every digit that is allowed in the decimal field.
   */
  get decPlaceholder(): string {
    return '0'.repeat(this.decMaxLen || 0);
  }

  readonly currControl = new UntypedFormControl();
  readonly intControl = new UntypedFormControl();
  readonly decControl = new UntypedFormControl();

  /**
   * The placeholder for the input. Defaults to 0.
   *
   * This attribute can be overwritten via the global configuration token
   */
  @Input()
  set placeholder(value: CurrencyInputConfig['placeholder']) {
    this._placeholder = value;
  }

  get placeholder(): string {
    return this._placeholder ?? this.overrideConfig?.placeholder ?? '0';
  }

  private _placeholder: CurrencyInputConfig['placeholder'];

  /**
   * For set output type string/number, by default 'number', when  need work with long number
   * ( bigger than MAX_SAFE_INTEGER = 9,007,199,254,740,993 ) need to use 'string'
   */
  @Input() resultType: 'string' | 'number' = 'number';
  /**
   * Whether there should be only the integer part.
   * You have to keep in mind, that if you have decimal part, even ".00"
   * you will not be able to remove it
   */
  @Input() integer = false;

  /**
   * Whether the input is required. Defaults to false.
   */
  @Input() required = false;

  /**
   * Whether the input is read only. Defaults to false.
   */
  @Input() readonly = false;

  /**
   * Whether the input is disabled. Defaults to false.
   */
  @Input()
  set disabled(disabled: boolean | CurrencyFieldsState) {
    if (typeof disabled === 'boolean') {
      this.currencyDisabledState = {
        currency: disabled,
        integer: disabled,
        decimal: disabled,
      };
    } else {
      this.currencyDisabledState = disabled;
    }
  }

  get disabled() {
    return this.currencyDisabledState;
  }

  /**
   * Whether integer label is only visible for screen readers.
   * By default is visible if it is needed to hide change this property
   * to "true" and it will have class "visually-hidden"
   */
  @Input() integerLabelSrOnly = false;
  /**
   * By default is visible if it is needed to hide change this property
   * to "true" and it will have class "visually-hidden"
   * By default is hidden and accessible only for screen reader
   */
  @Input() decimalLabelSrOnly = false;

  /**
   * Currency list to be displayed in the dropdown.
   *
   * Note: Non-empty currency list is required for the currency symbol
   * field to be shown. Otherwise, only the amount fields will be shown.
   *
   * Additionally, If only a single currency provided, the dropdown of
   * the currency selection will be disabled and its value set to that single currency
   *
   * This attribute can be overwritten via the global configuration token
   */
  @Input()
  set currencies(list: Array<string>) {
    this.currencyList = list.length ? list : this.overrideConfig?.currencies ?? [];

    if (this.currencyList && this.currencyList.length > 1) {
      this.currControl.enable();
    } else {
      this.currControl.disable();
    }

    // if currency is not set, use first item from the list
    if (this.currencyList && (this.currency === undefined || this.currencyList.indexOf(this.currency) === -1)) {
      // [FIXME] Nothing guarantees that the currencyList isn't empty
      this.updateCurrency(this.currencyList[0]);
    }
  }

  /**
   * Whether decimal text is aligned to the right.
   * By default is false
   */
  @Input() isRightAligned = false;

  /**
   * The id for the currency input. Defaults to unique string.
   * Used to map the label to the input.
   */
  @Input() currencyInputId?: string;

  /**
   * The id for the decimal input. Defaults to unique string.
   * Used to map the label to the input.
   */
  @Input() decimalInputId?: string;

  /**
   * The id for the integer input. Defaults to unique string.
   * Used to map the label to the input.
   */
  @Input() integerInputId?: string;

  private _integerId = getInputNextId();
  private _decimalId = getInputNextId();

  public readonly currencyLabelId: string;
  public readonly decimalLabelId: string;
  public readonly integerLabelId: string;
  public readonly validationMessagesId: string;
  public readonly idListAttr = idListAttr;

  protected initialized = false;

  /**
   * The id for the integer input. Defaults to unique string.
   * Used to map the label to the input.
   */
  @Input()
  set integerId(value: string) {
    this._integerId = value;
  }

  get integerId() {
    return this._integerId;
  }

  /**
   * The id for the decimal input. Defaults to unique string.
   * Used to map the label to the input.
   */
  @Input()
  set decimalId(value: string) {
    this._decimalId = value;
  }

  get decimalId() {
    return this._decimalId;
  }

  /**
   * The label for the integer input.
   */
  @Input() integerLabel = '';
  /**
   * The label for the decimal input.
   */
  @Input() decimalLabel = '';
  /**
   * The label for the currency input.
   */
  @Input() currencyLabel = '';

  /**
   * Flag is used to allow input negative value
   *
   * This attribute can be overwritten via the global configuration token
   */
  @Input()
  set allowNegativeValue(value: CurrencyInputConfig['allowNegativeValue']) {
    this._allowNegativeValue = value;
  }

  get allowNegativeValue(): boolean {
    return this._allowNegativeValue ?? this.overrideConfig?.allowNegativeValue ?? false;
  }

  private _allowNegativeValue: CurrencyInputConfig['allowNegativeValue'];

  /**
   * Aria label for the currency dropdown.
   */
  @Input() currencyListAriaLabel = 'Currency List Dropdown';
  /**
   * Aria label for the currency list with one item.
   */
  @Input() currencyListWithOnItemAriaLabel = 'Currency';

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') ariaDescribedby?: string;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-labelledby') ariaLabelledby?: string;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-invalid') ariaInvalid?: string;

  @ViewChild('integerInput', { static: true }) intEl?: ElementRef;
  @ViewChild('decimalsInput') decEl?: ElementRef;

  /**
   * An event emitter for on blur actions.
   */
  @Output() blur = new EventEmitter<FocusEvent | void>();

  constructor(
    private readonly el: ElementRef,
    @Inject(LOCALE_ID) private readonly locale: string,
    private domAttributeService: DomAttributesService,
    @Optional() @Self() public parentFormControl: NgControl,
    @Optional() @Inject(CURRENCY_INPUT_CONFIG_TOKEN) private readonly overrideConfig: CurrencyInputConfig,
  ) {
    this.currencyInputId = this.domAttributeService.generateId();
    this.currencyLabelId = this.domAttributeService.generateId();
    this.decimalInputId = this.domAttributeService.generateId();
    this.decimalLabelId = this.domAttributeService.generateId();
    this.integerInputId = this.domAttributeService.generateId();
    this.integerLabelId = this.domAttributeService.generateId();
    this.validationMessagesId = this.domAttributeService.generateId();

    BigNumber.config({
      FORMAT: {
        // grouping separator of the integer part
        groupSeparator: getLocaleNumberSymbol(this.locale, NumberSymbol.Group),
        // primary grouping size of the integer part
        groupSize: 3,
      },
    });
    if (this.parentFormControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.parentFormControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.initialized = true;
    if (!this.integerLabel || !this.decimalLabel) {
      console.warn(
        `
          Found an input from '
          ${this.constructor.name}
          ' without 'label' or 'aria-label' attribute, please provide one of them
        `,
      );
    }

    this.setDisabled(this.currencyDisabledState);

    this.formatInteger();
    this.sanitizeValidation();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled) {
      this.setDisabled(this.currencyDisabledState);
    }
  }

  decOnBlur($event: FocusEvent) {
    this.markAsTouched($event);
    this.formatDecimal(this.decControl);
  }

  get cur() {
    return this.currency;
  }

  get curList(): string[] {
    return this.currencyList.length ? this.currencyList : this.overrideConfig?.currencies ?? [];
  }

  ngAfterViewInit(): void {
    const len = this.el.nativeElement.getAttribute('maxlength');
    this.intMaxLen = len ? parseInt(len, 10) : defaultIntLength;
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  onPress($event: any) {
    this.focusDecEl($event.key);

    const keyCode = $event.keyCode || $event.which;
    const key = String.fromCharCode(keyCode);

    // allow to enter 'minus' only at the first position and if correspondent flag is enabled
    const isLeadingMinusPosition =
      this.allowNegativeValue && this.intEl && this.intEl.nativeElement.selectionStart === 0 && key === '-';

    // regexp is used here to support both of regular keyboard's numbers and numpad's numbers
    if (!(isLeadingMinusPosition || /^\d$/.test(key)) && $event.keyCode !== 8 && $event.keyCode !== 9) {
      $event.preventDefault();
    }
  }

  onInput() {
    const isFormatted = this.formatInteger();
    if (isFormatted) {
      this.triggerChange();
    }
  }

  updateCurrency(currency?: string) {
    this.currency = currency || this.currControl.value;
    this.triggerChange();
  }

  checkValues() {
    if (this.currency === undefined) {
      return true;
    }
    if (!this.decControl.value && !this.intControl.value) {
      this.onChange(null);

      return true;
    }

    return false;
  }

  formatDecimal(decControl: UntypedFormControl) {
    const { value } = decControl;
    if (value && this.decMaxLen && value.length < this.decMaxLen) {
      const updatedValue = String(value).padEnd(this.decMaxLen, '0');
      decControl.patchValue(updatedValue);

      return;
    }
  }

  triggerChange() {
    if (this.checkValues()) {
      return;
    }

    const int: string = this.intControl.value || '0';

    if (this.resultType === 'string') {
      const currencyGroupSymbol = new RegExp(`\\${this.localeSeparatorSymbols[1]}`, 'g');
      const whole: string = int.replace(currencyGroupSymbol, '');
      const dec: string = this.decControl.value || '0';
      const newAmount = whole + (dec !== '0' ? '.' + dec : '');
      this.onChange({
        currency: this.currency,
        amount: newAmount,
      });
    } else {
      const whole: number = parseInt(int.replace(/[^-\d]/g, ''), 10);
      const dec = parseFloat('0.' + this.decControl.value) || 0;
      const sign = whole < 0 ? -1 : 1;
      const newAmount = (Math.abs(whole) + dec) * sign;
      this.onChange({
        currency: this.currency,
        amount: newAmount,
      });
    }
  }

  checkNumeric(numeric: any): string {
    // Removed all dashes except the first one.
    if (numeric.length > 0) {
      numeric = numeric[0] + numeric.slice(1).replace(/-/g, '');
    }

    numeric = this.sanitizeNumericValue(numeric);

    return numeric === '' ? numeric : new BigNumber(numeric || 0).toFormat();
  }

  /**
   * Formats integer to user-friendly format.
   *
   * @returns Returns true in case of successful formatting, otherwise - false.
   */
  formatInteger(): boolean {
    // The behavior of `formatInteger` is dependent on some component inputs,
    // so don't format anything until the configuration is available.

    if (!this.initialized || !this.intEl) {
      return false;
    }
    if (!this.intControl.value) {
      this.intControl.setValue('');

      return true;
    }
    const offset = this.intEl.nativeElement.selectionStart - this.intControl.value.length;

    const numeric: string = this.intControl.value.replace(this.allowNegativeValue ? /[^-\d]/g : /\D/g, '');
    if (numeric === '-') {
      this.intControl.setValue('-', { emitEvent: false });

      return false;
    }
    const newVal = this.checkNumeric(numeric);
    // Extend maxlength (if set) with the amount of special chars.
    if (this.intMaxLen) {
      const specialCount = newVal.replace(/\d/g, '').length;
      this.intEl.nativeElement.setAttribute('maxlength', '' + (this.intMaxLen + specialCount));
    }

    this.intControl.setValue(newVal);
    const newLen = this.intControl.value.length;
    const newPos = Math.max(offset + newLen, 0);
    if (newLen > 0 && document.activeElement === this.intEl.nativeElement) {
      this.intEl.nativeElement.selectionStart = newPos;
      this.intEl.nativeElement.selectionEnd = newPos;
    }

    return true;
  }

  /**
   * Event handler for backspace key press, and check if correct number is deleted.
   */
  onBackspace(el: HTMLInputElement) {
    if (el.selectionStart) {
      const selectionStartPosition = el.selectionStart - 1;
      const value = el.value[selectionStartPosition];

      if (this.localeSeparatorSymbols.includes(value)) {
        el.setSelectionRange(selectionStartPosition, selectionStartPosition);
      }
    }
  }

  // method is used to correct rendered input's value if user entered '-' and left the field
  correctInputValue($event: FocusEvent) {
    this.markAsTouched($event);
    const numeric = this.intControl.value;
    if (numeric !== '-') {
      return;
    }
    this.intControl.setValue('', { emitEvent: false });
  }

  writeValue(model: any | null): void {
    if (!model) {
      return;
    }
    // currency cannot be set to nothing
    if (model.currency) {
      this.currency = model.currency;
      this.currControl.setValue(model.currency);
    }

    if (typeof model.amount === 'string' || typeof model.amount === 'number') {
      const [whole, decimals] = ('' + model.amount).split('.');
      this.intControl.setValue(whole);
      this.decControl.setValue(decimals);
    } else {
      this.intControl.setValue('');
      this.decControl.setValue('');
    }

    this.formatInteger();
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  markAsTouched($event: FocusEvent) {
    this.blur.emit($event);
    this.onTouched();
  }

  protected setDisabled(disableState: CurrencyFieldsState) {
    if (disableState.currency) {
      this.currControl.disable();
    } else {
      this.currControl.enable();
    }
    if (disableState.integer) {
      this.intControl.disable();
    } else {
      this.intControl.enable();
    }
    if (disableState.decimal) {
      this.decControl.disable();
    } else {
      this.decControl.enable();
    }
  }

  private focusDecEl(key: string): void {
    if (this.decEl && ['.', ','].indexOf(key) !== -1) {
      this.decEl.nativeElement.focus();
    }
  }

  /**
   * Make sure that numeric value doesn't exceed max length (if set)
   * this might happen in case value is pasted to input that has extended max length
   *
   * @param numeric
   */
  private sanitizeNumericValue(numeric: string): string {
    if (this.intMaxLen && numeric) {
      return numeric.slice(0, this.intMaxLen);
    }

    return numeric;
  }

  /**
   * If currency doesn't have decimal part, decimal placeholder
   * will be set to default decMinLen
   */
  private getDecimalDigits(currency: string) {
    return getNumberOfCurrencyDigits(currency) === 0 ? this.decMinLen : getNumberOfCurrencyDigits(currency);
  }

  private sanitizeValidation() {
    combineLatest([this.intControl.valueChanges, this.decControl.valueChanges])
      .pipe(
        filter(() => this.required),
        takeUntil(this.destroy$),
      )
      .subscribe(([intValue, decValue]) => {
        if (!intValue && !!decValue) {
          const errors = { ...(this.intControl.errors ?? {}) };
          delete errors.required;

          this.intControl.setErrors(!!Object.keys(errors).length ? errors : null);
        }

        if (!!intValue && !decValue) {
          const errors = { ...(this.decControl.errors ?? {}) };
          delete errors.required;

          this.decControl.setErrors(!!Object.keys(errors).length ? errors : null);
        }
      });
  }
}
