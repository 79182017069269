import { Component } from '@angular/core';

@Component({
    selector: 'bb-alert-page-ui',
    templateUrl: './bb-alert-page-ui.component.html',
    standalone: false,
})
export class AlertPageComponent {
  onAlertClosed() {
    console.log('the alert was closed');
  }
}
