import { Component } from '@angular/core';

@Component({
    selector: 'bb-payment-card-page-ui',
    templateUrl: './bb-payment-card-page-ui.component.html',
    standalone: false,
})
export class PaymentCardPageComponent {
  get paymentCard() {
    return {
      name: 'Ricardo Peterson',
      number: '1234',
      vendor: 'mastercard',
      expirationDate: new Date(2021, 2),
    };
  }

  get paymentCardNumberFormat() {
    return {
      length: 16,
      maskRange: [0, 12],
      segments: 4,
    };
  }
}
