import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-input-checkbox-ui-showcase',
  templateUrl: './input-checkbox-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class InputCheckboxShowcaseComponent implements OnInit {
  vForm: UntypedFormGroup | undefined;

  readonly tableOptions = [{ name: 'Option1' }, { name: 'Option2' }];

  constructor(private formBuilder: UntypedFormBuilder) {
    this.vForm = this.formBuilder.group({
      inputCheckbox: false,
      inputCheckbox2: false,
      inputCheckbox3: false,
      inputCheckbox4: false,
      inputCheckbox5: false,
      inputCheckbox6: false,
      inputCheckbox7: false,
      inputCheckbox8: false,
      inputCheckboxDisabled: { value: false, disabled: true },
      inputCheckboxDisabledChecked: { value: true, disabled: true },
      inputCheckboxError: ['', Validators.requiredTrue],
      inputCheckboxIndeterminate: { value: true },
    });
  }

  isFieldValid(fieldName: string): boolean {
    if (this.vForm) {
      const control = this.vForm.controls[fieldName];

      return control && control.valid;
    }

    return false;
  }

  ngOnInit() {
    if (this.vForm) {
      const control = this.vForm.get('inputCheckboxError');

      if (control) {
        control.markAsTouched();
      }
    }
  }
}
