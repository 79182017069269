import { Injectable } from '@angular/core';
import { noDesign, uiGroups } from '@backbase/ui-ang-doc-pages';
import menu from '_menu';

export interface MenuComponent {
  designGuidelinesPath?: string;
  children?: MenuComponent[];
  name: string;
  web?: boolean;
  storybook?: string;
  storybookParentPath?: string;
  iosSwiftUI?: boolean;
  iosUIKit?: boolean;
  androidCompose?: boolean;
  androidXML?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ShowcaseService {
  menuComponents: MenuComponent[] = this.getMenuComponents(menu);

  getComponent(id: string) {
    return this.menuComponents.find(
      (menuComponent: MenuComponent) => menuComponent.name.toLowerCase().replaceAll(' ', '-') === id,
    );
  }

  getMobileComponent(id: string) {
    return this.menuComponents.find(
      (menuComponent: MenuComponent) =>
        menuComponent.name.toLowerCase().replaceAll(' ', '-') === id &&
        (menuComponent.androidCompose === true ||
          menuComponent.androidXML === true ||
          menuComponent.iosSwiftUI === true ||
          menuComponent.iosUIKit === true),
    );
  }

  private getMenuComponents(menuItems: MenuComponent[]): MenuComponent[] {
    const result: MenuComponent[] = [];

    menuItems.forEach((menuItem: MenuComponent) => {
      if (this.isMenuComponent(menuItem)) {
        result.push(menuItem);
      }
      if (menuItem.children) {
        result.push(...this.getMenuComponents(menuItem.children));
      }
    });

    return result;
  }

  private isMenuComponent(menuItem: MenuComponent): boolean {
    // menuItem.web is true only for deprecated components
    // menuItem.storybook is mandatory to display storybook example
    return !!(
      menuItem.storybook ||
      menuItem.web ||
      menuItem.iosSwiftUI ||
      menuItem.iosUIKit ||
      menuItem.androidCompose ||
      menuItem.androidXML
    );
  }

  public isGroupItem(id: string): boolean {
    return uiGroups.reduce((accum, curr) => accum.concat(curr[Object.keys(curr)[0]]), []).includes(id);
  }

  public isGroupSection(id: string): boolean {
    const uiGroupNames = uiGroups.map((group) => Object.keys(group)[0]);

    return uiGroupNames.includes(id);
  }

  public hasDesignGuidelines(id: string): boolean {
    return !noDesign.includes(id);
  }
}
