import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActionStatusShowcaseModule } from './action-status/showcase/action-status-showcase.module';
import { AccountSelectorShowcaseModule } from './account-selector/showcase/account-selector-showcase.module';
import { AlertShowcaseModule } from './alert/showcase/alert-showcase.module';
import { AmountShowcaseModule } from './amount/showcase/amount-showcase.module';
import { AmountInputShowcaseModule } from './amount-input/showcase/amount-input-showcase.module';
import { AvatarShowcaseModule } from './avatar/showcase/avatar-showcase.module';
import { BadgeCounterShowcaseModule } from './badge-counter/showcase/badge-counter-showcase.module';
import { BadgeShowcaseModule } from './badge/showcase/badge-showcase.module';
import { ButtonShowcaseModule } from './button/showcase/button-showcase.module';
import { CardVendorShowcaseModule } from './card-vendor/showcase/card-vendor-showcase.module';
import { CharCounterShowcaseModule } from './char-counter/showcase/char-counter-showcase.module';
import { CheckboxGroupShowcaseModule } from './checkbox-group/showcase/checkbox-group-showcase.module';

import { CollapsibleAccordionShowcaseModule } from './collapsible-accordion/showcase/collapsible-accordion-showcase.module';

import { CollapsibleCardShowcaseModule } from './collapsible-card/showcase/collapsible-card-showcase.module';
import { CollapsibleShowcaseModule } from './collapsible/showcase/collapsible-showcase.module';
import { CommonErrorStateShowcaseModule } from './common-error-state/showcase/common-error-state-showcase.module';
import { CurrencyInputShowcaseModule } from './currency-input/showcase/currency-input-showcase.module';

import { DatePipeShowcaseModule } from './date-pipe/showcase/date-pipe-showcase.module';
import { DropdownMenuShowcaseModule } from './dropdown-menu/showcase/dropdown-menu-showcase.module';
import { DropdownMultiSelectShowcaseModule } from './dropdown-multi-select/showcase/dropdown-multi-select-showcase.module';
import { DropdownPanelShowcaseModule } from './dropdown-panel/showcase/dropdown-panel-showcase.module';
import { DropdownSingleSelectShowcaseModule } from './dropdown-single-select/showcase/dropdown-single-select-showcase.module';
import { EllipsisShowcaseModule } from './ellipsis/showcase/ellipsis-showcase.module';
import { EmptyStateShowcaseModule } from './empty-state/showcase/empty-state-showcase.module';
import { FieldsetShowcaseModule } from './fieldset/showcase/fieldset-showcase.module';
import { FileAttachmentShowcaseModule } from './file-attachment/showcase/file-attachment-showcase.module';
import { FileAttachmentShowcaseComponent } from './file-attachment/showcase/file-attachment-showcase.component';
import { FocusShowcaseModule } from './focus/showcase/focus-showcase.module';
import { HighlightShowcaseModule } from './highlight/showcase/highlight-showcase.module';
import { IbanPipeShowcaseModule } from './iban-pipe/showcase/iban-pipe-showcase.module';
import { IconShowcaseModule } from './icon/showcase/icon-showcase.module';
import { InfiniteScrollShowcaseModule } from './infinite-scroll/showcase/infinite-scroll-showcase.module';
import { InputCheckboxShowcaseModule } from './input-checkbox/showcase/input-checkbox-showcase.module';
import { InputDatepickerShowcaseModule } from './input-datepicker/showcase/input-datepicker-showcase.module';
import { InputEmailShowcaseModule } from './input-email/showcase/input-email-showcase.module';
import { InputInlineEditShowcaseModule } from './input-inline-edit/showcase/input-inline-edit-showcase.module';
import { InputNumberShowcaseModule } from './input-number/showcase/input-number-showcase.module';
import { InputPasswordShowcaseModule } from './input-password/showcase/input-password-showcase.module';
import { InputPhoneShowcaseModule } from './input-phone/showcase/input-phone-showcase.module';
import { InputRadioGroupShowcaseModule } from './input-radio-group/showcase/input-radio-group-showcase.module';
import { InputRangeShowcaseModule } from './input-range/showcase/input-range-showcase.module';
import { InputTextShowcaseModule } from './input-text/showcase/input-text-showcase.module';
import { InputTimepickerShowcaseModule } from './input-timepicker/showcase/input-timepicker-showcase.module';
import { InputValidationMessageShowcaseModule } from './input-validation-message/showcase/input-validation-message-showcase.module';

import { ItemLogShowcaseModule } from './item-log/showcase/item-log-showcase.module';
import { ListMultipleSelectShowcaseModule } from './list-multiple-select/showcase/list-multiple-select-showcase.module';
import { LoadButtonShowcaseModule } from './load-button/showcase/load-button-showcase.module';
import { LoadingIndicatorShowcaseModule } from './loading-indicator/showcase/loading-indicator-showcase.module';

import { LogoShowcaseModule } from './logo/showcase/logo-showcase.module';
import { ModalShowcaseModule } from './modal/showcase/modal-showcase.module';
import { NotificationShowcaseModule } from './notification/showcase/notification-showcase.module';
import { PageHeaderShowcaseModule } from './page-header/showcase/page-header-showcase.module';
import { PaginationShowcaseModule } from './pagination/showcase/pagination-showcase.module';
import { PaymentCardNumberPipeShowcaseModule } from './payment-card-number-pipe/showcase/payment-card-number-pipe-showcase.module';

import { AccountNumberPipeShowcaseModule } from './account-number-pipe/showcase/account-number-pipe-showcase.module';
import { PaymentCardShowcaseModule } from './payment-card/showcase/payment-card-showcase.module';
import { PeriodSelectorShowcaseModule } from './period-selector/showcase/period-selector-showcase.module';
import { PhoneNumberFormatPipeShowcaseModule } from './phone-number-format-pipe/showcase/phone-number-format-pipe-showcase.module';
import { ProductItemBasicAccountShowcaseModule } from './product-item-basic-account/showcase/product-item-basic-account-showcase.module';
import { ProductItemCreditCardShowcaseModule } from './product-item-credit-card/showcase/product-item-credit-card-showcase.module';
import { ProductItemCurrentAccountShowcaseModule } from './product-item-current-account/showcase/product-item-current-account-showcase.module';
import { ProductItemDebitCardShowcaseModule } from './product-item-debit-card/showcase/product-item-debit-card-showcase.module';
import { ProductItemInvestmentAccountShowcaseModule } from './product-item-investment-account/showcase/product-item-investment-account-showcase.module';
import { ProductItemLoanShowcaseModule } from './product-item-loan/showcase/product-item-loan-showcase.module';
import { ProductItemSavingsAccountShowcaseModule } from './product-item-savings-account/showcase/product-item-savings-account-showcase.module';
import { ProductItemTermDepositShowcaseModule } from './product-item-term-deposit/showcase/product-item-term-deposit-showcase.module';

import { ProductSelectorShowcaseModule } from './product-selector/showcase/product-selector-showcase.module';
import { ProgressbarShowcaseModule } from './progressbar/showcase/progressbar-showcase.module';
import { ProgressTrackerShowcaseModule } from './progress-tracker/showcase/progress-tracker-showcase.module';
import { RichTextEditorShowcaseModule } from './rich-text-editor/showcase/rich-text-editor-showcase.module';
import { SearchBoxShowcaseModule } from './search-box/showcase/search-box-showcase.module';
import { SelectListShowcaseModule } from './select-list/showcase/select-list-showcase.module';
import { StepperShowcaseModule } from './stepper/showcase/stepper-showcase.module';
import { SwitchShowcaseModule } from './switch/showcase/switch-showcase.module';
import { TabShowcaseModule } from './tab/showcase/tab-showcase.module';
import { TableShowcaseModule } from './table/showcase/table-showcase.module';
import { TextareaShowcaseModule } from './textarea/showcase/textarea-showcase.module';
import { TooltipShowcaseModule } from './tooltip-directive/showcase/tooltip-showcase.module';

import { AccountSelectorShowcaseComponent } from './account-selector/showcase/account-selector-showcase.component';
import { ActionStatusShowcaseComponent } from './action-status/showcase/action-status-showcase.component';
import { AlertShowcaseComponent } from './alert/showcase/alert-showcase.component';
import { AmountShowcaseComponent } from './amount/showcase/amount-showcase.component';
import { BadgeCounterShowcaseComponent } from './badge-counter/showcase/badge-counter-showcase.component';
import { CardVendorShowcaseComponent } from './card-vendor/showcase/card-vendor-showcase.component';
import { CheckboxGroupShowcaseComponent } from './checkbox-group/showcase/checkbox-group-showcase.component';
import { CollapsibleAccordionShowcaseComponent } from './collapsible-accordion/showcase/collapsible-accordion-showcase.component';
import { CollapsibleCardShowcaseComponent } from './collapsible-card/showcase/collapsible-card-showcase.component';
import { CollapsibleShowcaseComponent } from './collapsible/showcase/collapsible-showcase.component';

import { CurrencyInputShowcaseComponent } from './currency-input/showcase/currency-input-showcase.component';
import { DropdownMenuShowcaseComponent } from './dropdown-menu/showcase/dropdown-menu-showcase.component';
import { CharCounterShowcaseComponent } from './char-counter/showcase/char-counter-showcase.component';
import { ButtonShowcaseComponent } from './button/showcase/button-showcase.component';
import { BadgeShowcaseComponent } from './badge/showcase/badge-showcase.component';
import { AvatarShowcaseComponent } from './avatar/showcase/avatar-showcase.component';
import { DatePipeShowcaseComponent } from './date-pipe/showcase/date-pipe-showcase.component';
import { DropdownMultiSelectShowcaseComponent } from './dropdown-multi-select/showcase/dropdown-multi-select-showcase.component';
import { DropdownSingleSelectShowcaseComponent } from './dropdown-single-select/showcase/dropdown-single-select-showcase.component';
import { CommonErrorStateShowcaseComponent } from './common-error-state/showcase/common-error-state-showcase.component';
import { EllipsisShowcaseComponent } from './ellipsis/showcase/ellipsis-showcase.component';
import { DropdownPanelShowcaseComponent } from './dropdown-panel/showcase/dropdown-panel-showcase.component';
import { EmptyStateShowcaseComponent } from './empty-state/showcase/empty-state-showcase.component';
import { FieldsetShowcaseComponent } from './fieldset/showcase/fieldset-showcase.component';
import { FocusShowcaseComponent } from './focus/showcase/focus-showcase.component';
import { InputFileShowCaseModule } from './input-file/showcase/input-file-showcase.module';
import { InputFileShowcaseComponent } from './input-file/showcase/input-file-showcase.component';
import { HighlightShowcaseComponent } from './highlight/showcase/highlight-showcase.component';
import { IbanPipeShowcaseComponent } from './iban-pipe/showcase/iban-pipe-showcase.component';
import { IconShowcaseComponent } from './icon/showcase/icon-showcase.component';
import { InfiniteScrollShowcaseComponent } from './infinite-scroll/showcase/infinite-scroll-showcase.component';
import { InputCheckboxShowcaseComponent } from './input-checkbox/showcase/input-checkbox-showcase.component';
import { InputDatepickerShowcaseComponent } from './input-datepicker/showcase/input-datepicker-showcase.component';
import { InputEmailShowcaseComponent } from './input-email/showcase/input-email-showcase.component';
import { InputInlineEditShowcaseComponent } from './input-inline-edit/showcase/input-inline-edit-showcase.component';
import { InputNumberShowcaseComponent } from './input-number/showcase/input-number-showcase.component';
import { InputPasswordShowcaseComponent } from './input-password/showcase/input-password-showcase.component';

import { InputPhoneShowcaseComponent } from './input-phone/showcase/input-phone-showcase.component';
import { InputRadioGroupShowcaseComponent } from './input-radio-group/showcase/input-radio-group-showcase.component';
import { InputRangeShowcaseComponent } from './input-range/showcase/input-range-showcase.component';
import { InputTextShowcaseComponent } from './input-text/showcase/input-text-showcase.component';
import { InputTimepickerShowcaseComponent } from './input-timepicker/showcase/input-timepicker-showcase.component';
import { InputValidationMessageShowcaseComponent } from './input-validation-message/showcase/input-validation-message-showcase.component';
import { ItemLogShowcaseComponent } from './item-log/showcase/item-log-showcase.component';
import { ListMultipleSelectShowcaseComponent } from './list-multiple-select/showcase/list-multiple-select-showcase.component';
import { LoadButtonShowcaseComponent } from './load-button/showcase/load-button-showcase.component';
import { LoadingIndicatorShowcaseComponent } from './loading-indicator/showcase/loading-indicator-showcase.component';
import { LogoShowcaseComponent } from './logo/showcase/logo-showcase.component';
import { ModalShowcaseComponent } from './modal/showcase/modal-showcase.component';
import { NotificationShowcaseComponent } from './notification/showcase/notification-showcase.component';
import { PageHeaderShowcaseComponent } from './page-header/showcase/page-header-showcase.component';
import { PaginationShowcaseComponent } from './pagination/showcase/pagination-showcase.component';
import { PaymentCardNumberPipeShowcaseComponent } from './payment-card-number-pipe/showcase/payment-card-number-pipe-showcase.component';
import { AccountNumberPipeShowcaseComponent } from './account-number-pipe/showcase/account-number-pipe-showcase.component';
import { PaymentCardShowcaseComponent } from './payment-card/showcase/payment-card-showcase.component';
import { ProductItemCreditCardShowcaseComponent } from './product-item-credit-card/showcase/product-item-credit-card-showcase.component';
import { PhoneNumberFormatPipeShowcaseComponent } from './phone-number-format-pipe/showcase/phone-number-format-pipe-showcase.component';
import { ProductItemBasicAccountShowcaseComponent } from './product-item-basic-account/showcase/product-item-basic-account-showcase.component';
import { PeriodSelectorShowcaseComponent } from './period-selector/showcase/period-selector-showcase.component';
import { ProductItemCurrentAccountShowcaseComponent } from './product-item-current-account/showcase/product-item-current-account-showcase.component';
import { ProductItemDebitCardShowcaseComponent } from './product-item-debit-card/showcase/product-item-debit-card-showcase.component';
import { ProductItemInvestmentAccountShowcaseComponent } from './product-item-investment-account/showcase/product-item-investment-account-showcase.component';
import { ProductItemLoanShowcaseComponent } from './product-item-loan/showcase/product-item-loan-showcase.component';
import { ProductItemSavingsAccountShowcaseComponent } from './product-item-savings-account/showcase/product-item-savings-account-showcase.component';
import { ProductItemTermDepositShowcaseComponent } from './product-item-term-deposit/showcase/product-item-term-deposit-showcase.component';
import { ProductSelectorShowcaseComponent } from './product-selector/showcase/product-selector-showcase.component';
import { ProgressbarShowcaseComponent } from './progressbar/showcase/progressbar-showcase.component';
import { ProgressTrackerShowcaseComponent } from './progress-tracker/showcase/progress-tracker-showcase.component';
import { RichTextEditorShowcaseComponent } from './rich-text-editor/showcase/rich-text-editor-showcase.component';
import { SearchBoxShowcaseComponent } from './search-box/showcase/search-box-showcase.component';
import { SelectListShowcaseComponent } from './select-list/showcase/select-list-showcase.component';
import { StepperShowcaseComponent } from './stepper/showcase/stepper-showcase.component';
import { SwitchShowcaseComponent } from './switch/showcase/switch-showcase.component';
import { TabShowcaseComponent } from './tab/showcase/tab-showcase.component';
import { TableShowcaseComponent } from './table/showcase/table-showcase.component';
import { TextareaShowcaseComponent } from './textarea/showcase/textarea-showcase.component';
import { TooltipShowcaseComponent } from './tooltip-directive/showcase/tooltip-showcase.component';
import { ModeHeaderShowcaseModule } from './mode-header/showcase/mode-header-showcase.module';
import { ControlErrorHandlerShowcaseModule } from './control-error-handler/showcase/control-error-handler-showcase.module';
import { ControlErrorHandlerShowcaseComponent } from './control-error-handler/showcase/control-error-handler-showcase.component';
import { AmountInputShowcaseComponent } from './amount-input/showcase/amount-input-showcase.component';
import { ValueDiffShowcaseModule } from './value-diff/showcase/value-diff-showcase.module';
import { ValueDiffShowcaseComponent } from './value-diff/showcase/value-diff-showcase.component';
import { AccountNumberShowcaseModule } from './account-number/showcase/account-number-showcase.module';
import { AccountNumberShowcaseComponent } from './account-number/showcase/account-number-showcase.component';
import { LocaleSelectorShowcaseModule } from './locale-selector/showcase/locale-selector.showcase.module';
import { LocaleSelectorShowcaseComponent } from './locale-selector/showcase/locale-selector.showcase.component';
import { IllustrationShowcaseModule } from './illustration/showcase/illustration-showcase.module';
import { IllustrationShowcaseComponent } from './illustration/showcase/illustration-showcase.component';
import { ModeHeaderShowcaseComponent } from './mode-header/showcase/mode-header-showcase.component';

const showcaseModule: any = [
  AccountNumberPipeShowcaseModule,
  AccountSelectorShowcaseModule,
  ActionStatusShowcaseModule,
  AlertShowcaseModule,
  AmountInputShowcaseModule,
  AmountShowcaseModule,
  AvatarShowcaseModule,
  BadgeCounterShowcaseModule,
  BadgeShowcaseModule,
  ButtonShowcaseModule,
  CardVendorShowcaseModule,
  CharCounterShowcaseModule,
  CheckboxGroupShowcaseModule,
  CollapsibleAccordionShowcaseModule,
  CollapsibleCardShowcaseModule,
  CollapsibleShowcaseModule,
  CommonErrorStateShowcaseModule,
  ControlErrorHandlerShowcaseModule,
  CurrencyInputShowcaseModule,
  DatePipeShowcaseModule,
  DropdownMenuShowcaseModule,
  DropdownMultiSelectShowcaseModule,
  DropdownPanelShowcaseModule,
  DropdownSingleSelectShowcaseModule,
  EllipsisShowcaseModule,
  EmptyStateShowcaseModule,
  FieldsetShowcaseModule,
  FileAttachmentShowcaseModule,
  FocusShowcaseModule,
  HighlightShowcaseModule,
  IbanPipeShowcaseModule,
  IconShowcaseModule,
  IllustrationShowcaseModule,
  InfiniteScrollShowcaseModule,
  InputCheckboxShowcaseModule,
  InputDatepickerShowcaseModule,
  InputEmailShowcaseModule,
  InputFileShowCaseModule,
  InputInlineEditShowcaseModule,
  InputNumberShowcaseModule,
  InputPasswordShowcaseModule,
  InputPhoneShowcaseModule,
  InputRadioGroupShowcaseModule,
  InputRangeShowcaseModule,
  InputTextShowcaseModule,
  InputTimepickerShowcaseModule,
  InputValidationMessageShowcaseModule,
  ItemLogShowcaseModule,
  ListMultipleSelectShowcaseModule,
  LoadButtonShowcaseModule,
  LoadingIndicatorShowcaseModule,
  LogoShowcaseModule,
  LocaleSelectorShowcaseModule,
  ModalShowcaseModule,
  ModeHeaderShowcaseModule,
  NotificationShowcaseModule,
  PageHeaderShowcaseModule,
  PaginationShowcaseModule,
  PaymentCardNumberPipeShowcaseModule,
  PaymentCardShowcaseModule,
  PeriodSelectorShowcaseModule,
  PhoneNumberFormatPipeShowcaseModule,
  ProductItemBasicAccountShowcaseModule,
  ProductItemCreditCardShowcaseModule,
  ProductItemCurrentAccountShowcaseModule,
  ProductItemDebitCardShowcaseModule,
  ProductItemInvestmentAccountShowcaseModule,
  ProductItemLoanShowcaseModule,
  ProductItemSavingsAccountShowcaseModule,
  ProductItemTermDepositShowcaseModule,
  ProductSelectorShowcaseModule,
  ProgressbarShowcaseModule,
  RichTextEditorShowcaseModule,
  SearchBoxShowcaseModule,
  SelectListShowcaseModule,
  StepperShowcaseModule,
  SwitchShowcaseModule,
  TabShowcaseModule,
  TableShowcaseModule,
  TextareaShowcaseModule,
  TooltipShowcaseModule,
  ValueDiffShowcaseModule,
  ProgressTrackerShowcaseModule,
  AccountNumberShowcaseModule,
];

@NgModule({
  imports: [CommonModule, ...showcaseModule],
  exports: [...showcaseModule],
})
export class BackbaseUiAngShowcaseModule {}
export const COMPONENTS = [
  AccountNumberPipeShowcaseComponent,
  AccountSelectorShowcaseComponent,
  ActionStatusShowcaseComponent,
  AlertShowcaseComponent,
  AmountShowcaseComponent,
  AmountInputShowcaseComponent,
  AvatarShowcaseComponent,
  BadgeCounterShowcaseComponent,
  BadgeShowcaseComponent,
  ButtonShowcaseComponent,
  CardVendorShowcaseComponent,
  CharCounterShowcaseComponent,
  CheckboxGroupShowcaseComponent,
  CollapsibleAccordionShowcaseComponent,
  CollapsibleCardShowcaseComponent,
  CollapsibleShowcaseComponent,
  ControlErrorHandlerShowcaseComponent,
  CommonErrorStateShowcaseComponent,
  CurrencyInputShowcaseComponent,
  DatePipeShowcaseComponent,
  DropdownMenuShowcaseComponent,
  DropdownMultiSelectShowcaseComponent,
  DropdownPanelShowcaseComponent,
  DropdownSingleSelectShowcaseComponent,
  EllipsisShowcaseComponent,
  EmptyStateShowcaseComponent,
  FieldsetShowcaseComponent,
  FileAttachmentShowcaseComponent,
  FocusShowcaseComponent,
  HighlightShowcaseComponent,
  IbanPipeShowcaseComponent,
  IconShowcaseComponent,
  IllustrationShowcaseComponent,
  InfiniteScrollShowcaseComponent,
  InputCheckboxShowcaseComponent,
  InputDatepickerShowcaseComponent,
  InputEmailShowcaseComponent,
  InputFileShowcaseComponent,
  InputInlineEditShowcaseComponent,
  InputNumberShowcaseComponent,
  InputPasswordShowcaseComponent,
  InputPhoneShowcaseComponent,
  InputRadioGroupShowcaseComponent,
  InputRangeShowcaseComponent,
  InputTextShowcaseComponent,
  InputTimepickerShowcaseComponent,
  InputValidationMessageShowcaseComponent,
  ItemLogShowcaseComponent,
  ListMultipleSelectShowcaseComponent,
  LoadButtonShowcaseComponent,
  LoadingIndicatorShowcaseComponent,
  LocaleSelectorShowcaseComponent,
  LogoShowcaseComponent,
  ModalShowcaseComponent,
  ModeHeaderShowcaseComponent,
  NotificationShowcaseComponent,
  PageHeaderShowcaseComponent,
  PaginationShowcaseComponent,
  PaymentCardNumberPipeShowcaseComponent,
  PaymentCardShowcaseComponent,
  PeriodSelectorShowcaseComponent,
  PhoneNumberFormatPipeShowcaseComponent,
  ProductItemBasicAccountShowcaseComponent,
  ProductItemCreditCardShowcaseComponent,
  ProductItemCurrentAccountShowcaseComponent,
  ProductItemDebitCardShowcaseComponent,
  ProductItemInvestmentAccountShowcaseComponent,
  ProductItemLoanShowcaseComponent,
  ProductItemSavingsAccountShowcaseComponent,
  ProductItemTermDepositShowcaseComponent,
  ProductSelectorShowcaseComponent,
  ProgressbarShowcaseComponent,
  ProgressTrackerShowcaseComponent,
  RichTextEditorShowcaseComponent,
  SearchBoxShowcaseComponent,
  SelectListShowcaseComponent,
  StepperShowcaseComponent,
  SwitchShowcaseComponent,
  TabShowcaseComponent,
  TableShowcaseComponent,
  TextareaShowcaseComponent,
  TooltipShowcaseComponent,
  ValueDiffShowcaseComponent,
  AccountNumberShowcaseComponent,
];
