import { ChangeDetectorRef, Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

export interface CheckContentTruncationBehaviour {
  /**
   * Set truncation status of notification message
   */
  setIsContentTruncated(isTruncated: boolean): void;
}

@Directive({
  selector: '[bbIsContentTruncated]',
  standalone: false,
})
export class IsContentTruncatedDirective implements AfterViewInit {
  constructor(
    private readonly elementRef: ElementRef,
    private readonly cd: ChangeDetectorRef,
  ) {}

  @Input() hostRef!: CheckContentTruncationBehaviour;

  ngAfterViewInit() {
    if (!this.hostRef.setIsContentTruncated) {
      throw new Error(
        '"hostRef" should implement "CheckContentTruncationBehaviour" interface to set truncation status',
      );
    }
    setTimeout(() => {
      this.setIsContentTruncatedInParentComponent();
    });
  }

  private setIsContentTruncatedInParentComponent(): void {
    const isContentTruncated = this.isContentTruncated(this.elementRef.nativeElement);
    this.hostRef.setIsContentTruncated(isContentTruncated);
    this.cd.markForCheck();
  }

  private isContentTruncated({ offsetHeight, scrollHeight, parentElement }: HTMLElement): boolean {
    if (navigator.userAgent.indexOf('Firefox') > -1) {
      // For firefox, the element always returns it's full height regardless of whether
      // the overflow is hidden or not.
      // We therefore use to check if the full height of element matches the parent which
      // would be smaller than the element if the element overflows.
      return Number(parentElement?.offsetHeight) < offsetHeight;
    }

    return offsetHeight < scrollHeight;
  }
}
