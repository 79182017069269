import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { DateRangeModel, DateSelectionModel, InputDatepickerComponent } from '@backbase/ui-ang/input-datepicker';

@Component({
  selector: 'bb-input-datepicker-ui-showcase',
  templateUrl: './input-datepicker-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class InputDatepickerShowcaseComponent {
  date: string;
  dateFirstDayOfWeekSet: string;
  dateDefault: string;
  dateMask: string;
  dateMask1: string;
  dateMaskRange1: string;
  dateMaskRange2: string;
  dateMaskRangeSplit: string;
  datePickerDisabled: string;
  datePickerReadonly: string;
  predefinedDate: string;
  footerTemplateDate: string;
  dateRange: DateRangeModel;
  dateRangeRequired: DateRangeModel;
  dateRangeSplit: DateRangeModel | null = null;
  dateRangeRequiredSplit: DateRangeModel | null = null;
  minDate: string;
  maxDate: string;
  isDisabled = true;
  formGroup: UntypedFormGroup;
  dateOverrideDateFormat: string;
  dateOverrideDateFormat2: string;
  focusedDate$$ = new BehaviorSubject<DateSelectionModel | undefined>(undefined);
  focusedRangeDate$$ = new BehaviorSubject<DateSelectionModel | undefined>(undefined);

  constructor(formBuilder: UntypedFormBuilder) {
    this.date = '';
    this.dateFirstDayOfWeekSet = '';
    this.dateDefault = '';
    this.dateMask = '';
    this.dateMask1 = '';
    this.dateMaskRange1 = '';
    this.dateMaskRange2 = '';
    this.dateMaskRangeSplit = '';
    this.datePickerDisabled = '';
    this.predefinedDate = new Date('2019-10-29T00:00').toISOString();
    this.dateOverrideDateFormat = '';
    this.dateOverrideDateFormat2 = new Date('2020-10-09T00:00').toISOString();
    this.datePickerReadonly = this.predefinedDate;
    this.footerTemplateDate = new Date('2019-10-29T00:00').toISOString();

    this.dateRange = {
      from: new Date('2020-11-05T00:00').toISOString(),
      to: new Date('2020-11-12T00:00').toISOString(),
    };
    this.dateRangeRequired = { from: '', to: '' };

    this.dateRangeSplit = {
      from: new Date('2020-11-05T00:00').toISOString(),
      to: new Date('2020-11-12T22:00').toISOString(),
    };
    this.dateRangeRequiredSplit = { from: '', to: '' };

    const today = new Date('2019-10-05T00:00');

    this.minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 0, 0).toISOString();
    this.maxDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0).toISOString();

    this.formGroup = formBuilder.group({
      reactiveDateFormControlName: formBuilder.control(new Date('2020-10-09T00:00').toISOString(), [
        Validators.required,
      ]),
      reactiveDateRangeFormControlName1: formBuilder.control({
        from: new Date('2020-11-05T00:00').toISOString(),
        to: new Date('2020-11-12T00:00').toISOString(),
      }),
      reactiveDateRangeFormControlName2: formBuilder.control({ from: '', to: '' }, [Validators.required]),
      reactiveDateRangeFormControlName3: formBuilder.control(
        { from: '', to: new Date('2020-11-12T00:00').toISOString() },
        [Validators.required],
      ),
      reactiveDateRangeFormControlName4: formBuilder.control({
        from: '',
        to: new Date('2020-11-12T00:00').toISOString(),
      }),
      reactiveDateRangeFormControlName5: formBuilder.control({
        from: 'date',
        to: new Date('2020-11-12T00:00').toISOString(),
      }),
      reactiveDateRangeFormControlName6: formBuilder.control({
        from: new Date('2020-11-12T00:00').toISOString(),
        to: '',
      }),
    });
  }

  focusedDate = (event: DateSelectionModel) => {
    const { date = null, isSelecting = false } = event;

    this.focusedDate$$.next({
      date,
      isSelecting,
    });
  };
  focusedRangeDate = (event: DateSelectionModel) => {
    const { date = null, isSelecting = false } = event;

    this.focusedRangeDate$$.next({
      date,
      isSelecting,
    });
  };
  resetFooterDate = (el: InputDatepickerComponent) => {
    this.footerTemplateDate = new Date('2019-10-29T00:00').toISOString();
    el.datePicker?.close();
  };
}
