import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
  Self,
  Optional,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { DomAttributesService } from '@backbase/ui-ang/services';
import { Subscription } from 'rxjs';

/**
 * @name InputPasswordComponent
 *
 * @description
 * Component that displays a password input
 *
 * ### Accessibility
 * Current component provide option to pass needed accessibility
 * attributes. You need to take care of properties that are required in your case :
 *  - role
 *  - aria-activedescendant
 *  - aria-describedby
 *  - aria-expanded
 *  - aria-invalid
 *  - aria-label
 *  - aria-labelledby
 *  - aria-owns
 *
 * visibilityControlLabel is discernible text for show/hide password button.
 * ariaLabel is discernible text for password input
 */
@Component({
  selector: 'bb-input-password-ui',
  templateUrl: './input-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InputPasswordComponent extends InputBaseComponent implements AfterViewInit, OnDestroy, OnInit {
  /**
   * The placeholder for the password input. Defaults to an empty string;
   */
  @Input() placeholder = '';
  /**
   * The maxLength for the password input.
   */
  @Input() maxLength?: number;
  /**
   * The minLength for the password input.
   */
  @Input() minLength?: number;
  /**
   * Whether the password input is readonly.
   */
  @Input() readonly = false;

  /**
   * The autocomplete type for text input.
   */

  @Input() autocomplete?: 'new-password' | 'current-password' | 'on' | 'off' | 'one-time-code';

  /**
   * Whether to show the visibility button icon after the text.
   */
  @Input() showVisibilityControl = false;
  /**
   * Whether to show password in plain text.
   */
  @Input() showPassword = false;
  /**
   * Discernible text for show/hide password button.
   */
  @Input() visibilityControlLabel = 'Show/Hide password';
  /**
   * Text used to describe the password input for screen readers.
   */
  @Input()
  ariaLabel = 'Password Input';

  /**
   * The inputMode for the input.
   */
  @Input() inputMode = 'text';

  /**
   * The event emitter called when the visibility button is clicked.
   */
  @Output() toggleVisibility = new EventEmitter<boolean>();

  @ViewChild('inputPassword') inputEl: ElementRef | undefined;

  private statusChangesSub: Subscription = new Subscription();

  constructor(
    private readonly domAttrService: DomAttributesService,
    private readonly elem: ElementRef,
    private readonly renderer2: Renderer2,
    protected readonly cd: ChangeDetectorRef,
    @Self() @Optional() public parentFormControl: NgControl,
  ) {
    super(cd);
    if (parentFormControl) {
      this.parentFormControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.statusChangesSub.add(
      this.parentFormControl?.statusChanges?.subscribe(() => {
        this.cd.markForCheck();
      }),
    );
  }

  ngAfterViewInit(): void {
    if (!this.ariaLabel) {
      this.domAttrService.moveAriaAttributes(
        this.elem.nativeElement,
        this.inputEl && this.inputEl.nativeElement,
        this.renderer2,
      );
    }
  }

  onVisibilityChange() {
    this.showPassword = !this.showPassword;
    this.toggleVisibility.emit(this.showPassword);
  }

  ngOnDestroy(): void {
    this.statusChangesSub.unsubscribe();
  }
}
