import { Injectable, TemplateRef } from '@angular/core';
import { NotificationComponent, NotificationType } from './notification.component';
import { AppendToDomService } from './append-to-dom.service';
import { Subject, Observable, of, NEVER } from 'rxjs';
import { delay, startWith, switchMap } from 'rxjs/operators';

export interface Notification {
  /**
   * The header for the notification.
   */
  header: string | TemplateRef<any>;
  /**
   * The message template for the notification. Defaults to an empty string
   */
  message: string | TemplateRef<any>;
  /**
   * The type modifier for the notification. Defaults to 'info'
   */
  modifier?: NotificationType;
  /**
   * Whether the notification is dismissible. Defaults to true
   */
  dismissible?: boolean;
  /**
   * The time the notification will appear for in ms.
   * 0 will result in the notifaction remaining open.
   */
  ttl?: number;
  /**
   * The text for the primary action button.
   * Will throw an error if unset and primary action set.
   */
  primaryActionText?: string;
  /**
   * A callback function for the primary action.
   * Required to show the button.
   */
  primaryAction?: Function;
  /**
   * The text for the secondary action button.
   * Will throw an error if unset and secondary action set.
   */
  secondaryActionText?: string;
  /**
   * A callback function for the secondary action.
   * Required to show the button.
   */
  secondaryAction?: Function;
  /**
   * A callback function for the manually close action.
   */
  closeAction?: Function;
  /**
   * A callback function for the host click action.
   */
  hostAction?: Function;
  /**
   * The context for notification header template.
   */
  headerContext?: any;
  /**
   * The context for notification message template.
   */
  messageContext?: any;
  /**
   * The primary action template for the notification.
   */
  primaryActionTemplate?: TemplateRef<any>;
  /**
   * The secondary action template for the notification.
   */
  secondaryActionTemplate?: TemplateRef<any>;
  /**
   * Autofocus will cause the component to be focused on the first rendering.
   */
  autofocus?: boolean;
}

/**
 * @name NotificationService
 *
 * @description
 * Service to handle the display of notifications within the application.
 * This service provides methods to show notifications with various options
 * such as header, message, actions, templates, and more.
 *
 * @usageNotes
 * Inject the NotificationService into your component or service to use it.
 *
 * Example:
 *
 * ```typescript
 * constructor(private notificationService: NotificationService) {}
 *
 * this.notificationService.showNotification({
 *   header: 'Notification Header',
 *   message: 'This is a notification message.',
 *   primaryActionText: 'Confirm',
 *   primaryAction: () => { console.log('Primary action executed'); },
 *   secondaryActionText: 'Cancel',
 *   secondaryAction: () => { console.log('Secondary action executed'); },
 *   ttl: 5000,
 *   modifier: 'success',
 * });
 * ```
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private readonly appendtoDomService: AppendToDomService) {}

  /**
   * Displays a notification with the provided options.
   * The notification is automatically added to the DOM.
   *
   * @param {Notification} options - The options for the notification.
   * @param {string} options.header - The header text of the notification.
   * @param {string} options.message - The message text of the notification.
   * @param {boolean} [options.dismissible=true] - Whether the notification can be dismissed.
   * @param {number} [options.ttl=3000] - Time to live for the notification in milliseconds.
   * @param {string} [options.modifier='info'] - The style modifier for the notification.
   * @param {object} [options.headerContext={}] - The context for the notification header template.
   * @param {object} [options.messageContext={}] - The context for the notification message template.
   * @param {string} [options.primaryActionText] - The text for the primary action button.
   * @param {Function} [options.primaryAction] - The callback function for the primary action.
   * @param {string} [options.secondaryActionText] - The text for the secondary action button.
   * @param {Function} [options.secondaryAction] - The callback function for the secondary action.
   * @param {Function} [options.closeAction] - The callback function for the close action.
   * @param {Function} [options.hostAction] - The callback function for the host click action.
   * @param {TemplateRef<any>} [options.primaryActionTemplate] - The template for the primary action.
   * @param {TemplateRef<any>} [options.secondaryActionTemplate] - The template for the secondary action.
   * @param {boolean} [options.autofocus=false] - Whether to autofocus the notification on render.
   *
   * @returns {Function} A function to hide the notification.
   */
  showNotification({
    header,
    message,
    dismissible = true,
    ttl = 3000,
    modifier = 'info',
    headerContext = {},
    messageContext = {},
    primaryActionText,
    primaryAction,
    secondaryActionText,
    secondaryAction,
    closeAction,
    hostAction,
    primaryActionTemplate,
    secondaryActionTemplate,
    autofocus = false,
  }: Notification): () => void {
    const res = this.appendtoDomService.appendComponentToRoot(NotificationComponent, autofocus);

    const componentInstance = res.componentInstance as NotificationComponent;
    componentInstance.message = message;
    componentInstance.modifier = modifier;
    componentInstance.dismissible = dismissible;
    componentInstance.header = header;
    componentInstance.primaryActionText = primaryActionText;
    componentInstance.primaryAction = primaryAction;
    componentInstance.secondaryActionText = secondaryActionText;
    componentInstance.secondaryAction = secondaryAction;
    componentInstance.closeAction = closeAction;
    componentInstance.hostAction = hostAction;
    componentInstance.headerContext = headerContext;
    componentInstance.messageContext = messageContext;
    componentInstance.primaryActionTemplate = primaryActionTemplate;
    componentInstance.secondaryActionTemplate = secondaryActionTemplate;

    return this.createHideNotificationFunction(
      res.unmountComponent,
      ttl,
      componentInstance.close,
      componentInstance.pauseNotification,
    );
  }

  private createHideNotificationFunction(
    hideFn: () => void,
    ttl: number = 0,
    closeStream: Observable<any>,
    pauseNotification: Observable<any>,
  ): () => void {
    const hideSubject = new Subject<void>();
    const hideNotification = () => {
      hideFn();
      hideSubscription.unsubscribe();
      closeSubscription.unsubscribe();
    };
    const closeSubscription = closeStream.subscribe({ next: hideNotification });

    const hideSubscription = hideSubject
      .pipe(
        switchMap(() => {
          return pauseNotification.pipe(startWith(false));
        }),
        switchMap((enter) => {
          return enter ? NEVER : of(null).pipe(delay(ttl));
        }),
      )
      .subscribe({ next: hideNotification });

    if (ttl > 0) {
      hideSubject.next();
    }

    return hideNotification;
  }
}
