import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DomAttributesService } from '@backbase/ui-ang/services';
import { getDynamicId, getKeyCode, KEY_CODES } from '@backbase/ui-ang/util';
import { NgbDropdown, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { Placement, PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';

@Directive({
  selector: 'ng-template[bbDropdownMenuItem]',
  standalone: false,
})
export class DropdownMenuItemDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: 'ng-template[bbDropdownLabel]',
  standalone: false,
})
export class DropdownLabelDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

export abstract class DropdownMenuToken {
  buttonEl: ElementRef | undefined;
}

/**
 * @name DropdownMenuComponent
 *
 * @description
 * Component that displays a button with a dropdown
 *
 * @a11y
 * - `dropDownButtonLabel` is discernible text for dropdown button which is used as the `aria-label`.
 * - `aria-owns` handles contextual relationship between a parent and its child elements,
 * in this case between dropdown menu and dropdown items, it's internally handled by the component with unique ids.
 * - `aria-activedescendant` identifies currently active element of dropdown item. It's internally handled but
 * when `bbDropdownMenuItem` directive and [role=menuitem] is used for listing dropdown elements
 * then use [id]="option". Example:
 * ```
  <ng-template bbDropdownMenuItem>
    <button role="menuitem" [id]="option" class="dropdown-item" *ngFor="let option of [1,2,3]">
      {{ option}}
   </button>
  </ng-template>
 ```
 *
 * ### Keyboard navigation guide
 *
 * This guide will help you understand the keyboard navigation on dropdown menu and its dropdown items.
 *
 * | Key | Function |
 * |-----|----------|
 * | Enter | **When the dropdown menu is open and an item within it is focused:**<br>1. **Pressing Enter** selects the item, closes the dropdown, and refocuses on the dropdown button. |
 * | ArrowDown | **When the dropdown menu is closed:**<br>1. **Pressing ArrowDown** opens the dropdown menu and focuses on the first item. <br><br>**When the dropdown menu is open and an item is focused:**<br>1. **Pressing ArrowDown** moves the focus to the next item. |
 * | ArrowUp | **When the dropdown menu is closed:**<br>1. Pressing ArrowUp opens the dropdown menu and focuses on the first item. <br><br>**When the dropdown menu is open and an item is focused:**<br>1. **Pressing ArrowUp** moves the focus to the previous item. |
 * | Tab | **When the dropdown menu is open:**<br>1. If the focus is on the dropdown button, pressing Tab shifts the focus to the first item in the dropdown.<br>2. If the focus is on the last item in the dropdown, **pressing Tab** toggles the focus back to the dropdown button and closes the dropdown.<br>3. If the focus is on any item other than the last, **pressing Tab** shifts the focus to the next item in the dropdown. |
 * | Tab + Shift | **When the dropdown menu is open:**<br>1. If the focus is on the dropdown button, **pressing Tab + Shift** closes the dropdown menu.<br>2. If the focus is on the first item in the dropdown, **pressing Tab + Shift** toggles the focus to the dropdown button.<br>3. If the focus is on any item other than the first, **pressing Tab + Shift** shifts the focus to the previous item in the dropdown. |
 *
 *
 */
@Component({
  selector: 'bb-dropdown-menu-ui',
  templateUrl: './dropdown-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DropdownMenuToken,
      useExisting: DropdownMenuComponent,
    },
  ],
  standalone: false,
})
export class DropdownMenuComponent extends DropdownMenuToken implements AfterViewInit, OnInit {
  @ContentChild(DropdownLabelDirective, { read: TemplateRef, static: true })
  customLabel: TemplateRef<any> | undefined;
  @ContentChild(DropdownMenuItemDirective, { read: TemplateRef, static: true })
  customDropdownMenuItem: TemplateRef<any> | undefined;
  @ViewChild(NgbDropdown, { static: true }) readonly dropdownDir: NgbDropdown | undefined;
  @ViewChild(NgbDropdownToggle, { read: ElementRef, static: true }) readonly dropDownToggle:
    | ElementRef<HTMLElement>
    | undefined;
  @ViewChild('dropdownListElement') readonly dropdownListElement: ElementRef<HTMLElement> | undefined;
  @ViewChild('dropdownMenuContainer') readonly dropdownMenuContainer: ElementRef<HTMLElement> | undefined;

  private focusedItemIndex: number | undefined;
  private readonly keyActionsConfig = [
    {
      predicate: (key: string) => key === KEY_CODES.ENTER,
      resolver: (event: KeyboardEvent, itemList: NodeList) => {
        if (this.isDropDownOpen() && this.focusedItemIndex !== undefined) {
          setTimeout(() => this.closeDropDown());
        }
      },
    },
    {
      predicate: (key: string) => key === KEY_CODES.DOWN,
      resolver: (event: KeyboardEvent, itemList: NodeList) => {
        event.preventDefault();
        if (this.focusedItemIndex === undefined) {
          this.focusedItemIndex = 0;
          this.focusListItem(itemList);

          return;
        }

        if (this.focusedItemIndex < itemList.length - 1) {
          this.focusedItemIndex = this.focusedItemIndex + 1;
        }

        this.focusListItem(itemList);
      },
    },
    {
      predicate: (key: string) => key === KEY_CODES.UP,
      resolver: (event: KeyboardEvent, itemList: NodeList) => {
        event.preventDefault();
        if (this.focusedItemIndex === undefined) {
          this.focusedItemIndex = 0;
          this.focusListItem(itemList);

          return;
        }

        if (this.focusedItemIndex > 0) {
          this.focusedItemIndex = this.focusedItemIndex - 1;
        }

        this.focusListItem(itemList);
      },
    },
    {
      predicate: (key: string) => key === KEY_CODES.TAB,
      resolver: (event: KeyboardEvent, itemList: NodeList) => {
        if (this.isDropDownOpen() && this.focusedItemIndex === undefined) {
          if (event.shiftKey) {
            this.closeDropDown();
          } else {
            event.preventDefault();
            this.focusedItemIndex = 0;
            this.focusListItem(itemList);
          }

          return;
        }

        if (this.isFirstListItemInFocus(itemList) && event.shiftKey) {
          event.preventDefault();
          this.focusToggle();
          this.focusedItemIndex = undefined;

          return;
        }

        if (this.isLastListItemInFocus(itemList) && !event.shiftKey) {
          this.focusToggle();
          this.closeDropDown();
          this.focusedItemIndex = undefined;

          return;
        }

        if (this.focusedItemIndex !== undefined) {
          this.focusedItemIndex = event.shiftKey ? this.focusedItemIndex - 1 : this.focusedItemIndex + 1;
        }
      },
    },
  ];

  dropdownContainerAdjustedMaxHeight = 0;

  ariaActivedescendant: string | null = null;

  dropdownId = getDynamicId() + '_dropdown_menu';

  /**
   * The label for the button dropdown. Defaults to an empty string.
   */
  @Input() label = '';

  /**
   * Whether the component is mutable or clickable. Defaults to false.
   */
  @Input() disabled = false;

  /**
   * If the value is an object, please provide a optionLabelKey.
   */
  @Input() options: Array<string | object> = [];

  /**
   * Event (Output) that emits the value of selected dropdown item.
   */
  @Output() select = new EventEmitter<string | object>();

  @Input() leftIcon?: string;

  /**
   * Icon that is displayed in the button.
   */
  @Input() icon?: string;

  /**
   * The size of the icon to be displayed. Defaults to md.
   */
  @Input() iconSize = 'md';

  /**
   * The color of the icon to be displayed.
   */
  @Input() iconColor?: string;

  /**
   * Color of the button. Defaults to primary.
   */
  @Input() btnColor = 'primary';

  /**
   * The flag to indicate whether the dropdown button should be in a circular shape. Defaults to 'false'.
   * This will only work when there's only an icon inside the button without a text.
   */
  @Input() btnCircle = false;

  /**
   * Key that contains the label of the option object.
   * Mandatory when the type of option is object
   */
  @Input() optionLabelKey?: string;

  /**
   * If true it will stretch the button inside to 100% width.
   */
  @Input() fullWidth = false;

  /**
   * Whether the dropdown should be closed when clicking one of dropdown items or pressing ESC. Defaults to true.
   */
  @Input() autoClose: boolean | 'inside' | 'outside' = true;

  /**
   * Specifies which element the dropdown should be appended to.
   */
  @Input() container: '' | 'body' = '';

  /**
   * The position of the dropdown, position will be picked in order of feasibility.
   */
  @Input() position: Placement | PlacementArray = ['bottom-end', 'bottom-start', 'top-end', 'top-start'];

  /**
   * The role of the dropdown menu defaults to menu
   */

  @Input() dropDownMenuRole?: string = 'menu';

  /**
   * ID for the dropdown menu
   */

  @Input() dropDownMenuId?: string;

  /**
   * Predefined button sizes
   */
  @Input() buttonSize: 'sm' | 'md' = 'md';

  /**
   * Defines whether or not the dropdown menu is opened initially.
   *
   * Defaults to `false`.
   */
  @Input() isOpen = false;

  /**
   * Dropdown menu (toggle) button aria label.
   *
   * Defaults to "Toggle dropdown".
   */
  @Input() dropDownButtonLabel = $localize`:@@bb-dropdown-menu-ui.dropdown-button.aria-label:Toggle dropdown`;

  /**
   * Customize the ARIA role for the HTML input/select/textarea element inside this component.
   *
   * This can be used to improve accessibility for components, for example by configuring `[role]="'combobox'"`
   * for a component that provides an autocomplete list.
   *
   * Values that are valid for the native HTML form elements are allowed.
   */
  @HostBinding('attr.role') @Input() role = 'group';

  @ViewChild('button') buttonEl: ElementRef | undefined;

  constructor(
    private readonly domAttrService: DomAttributesService,
    private readonly elem: ElementRef,
    private readonly renderer: Renderer2,
  ) {
    super();
  }

  ngOnInit() {
    // the buttons size will be set from the group size
    if (this.elem.nativeElement.parentElement.className.indexOf('btn-group-sm') > -1) {
      this.buttonSize = 'sm';
    }
  }

  ngAfterViewInit(): void {
    this.domAttrService.moveAriaAttributes(
      this.elem.nativeElement,
      this.buttonEl && this.buttonEl.nativeElement,
      this.renderer,
    );

    const itemList = this.getItemList();

    if (itemList) {
      this.setItemsTabIndex(itemList);
    }
  }

  /**
   * Internal handler for clicks on dropdown items
   *
   * @param event Event
   * @param item Item that has been clicked
   */
  onClick(event: Event, item: any) {
    event.preventDefault();
    this.closeDropdownAndReturnFocus();
    this.select.emit(item);
  }

  /**
   * Internal handler to close dropdown on keyboard clicks
   * and return focus to the dropdown button
   */
  closeDropdownAndReturnFocus() {
    this.dropdownDir?.close();
    this.dropDownToggle?.nativeElement.focus();
  }

  /**
   * Drop down change state event handler
   *
   * @param isOpen
   */
  onOpenChange(isOpen: boolean): void {
    if (isOpen) {
      setTimeout(() => {
        // this needs to be here to work with dropdown-menu-full-width.directive
      }, 0);
    } else {
      this.focusedItemIndex = undefined;
      if (this.buttonEl) {
        this.ariaActivedescendant = null;
      }
    }
  }

  /**
   * Function that returns the label of the dropdown item
   *
   * @param option Item that has been clicked
   * @param labelKey Key that contains the label of the option object
   */
  getOptionLabel(option: any): string {
    return this.optionLabelKey && typeof option === 'object' ? option[this.optionLabelKey] : option;
  }

  @HostListener('window:keydown', ['$event']) onKeyUp(event: KeyboardEvent) {
    if (!this.isDropDownOpen()) {
      return;
    }

    const itemList = this.getItemList();

    if (!itemList) {
      return;
    }

    this.setItemsTabIndex(itemList);

    const config = this.keyActionsConfig.find((cv) => cv.predicate(getKeyCode(event)));

    if (config) {
      config.resolver(event, itemList);
    }
    const selectedItem = this.getSelectedItem(itemList);
    if (this.buttonEl && this.isDropDownOpen() && selectedItem) {
      this.ariaActivedescendant = selectedItem.id;
    }
  }

  private isLastListItemInFocus(itemList: QueryList<ElementRef> | any): boolean {
    return document.activeElement === itemList.item(itemList.length - 1);
  }

  private isFirstListItemInFocus(itemList: NodeList): boolean {
    return document.activeElement === itemList.item(0);
  }

  private focusToggle(): void {
    if (this.dropDownToggle) {
      this.dropDownToggle.nativeElement.focus();
    }
  }

  private closeDropDown(): void {
    if (this.dropdownDir) {
      this.dropdownDir.close();
    }
  }

  private isDropDownOpen(): boolean {
    return Boolean(this.dropdownDir && this.dropdownDir.isOpen());
  }

  private getItemList(): NodeList | undefined {
    //TODO: don't access private API
    return this.dropdownDir?.['_menu']?.nativeElement?.querySelectorAll('[role=menuitem]');
  }

  private setItemsTabIndex(itemList: NodeList): void {
    Array.prototype.forEach.call(itemList, (cv: any) => {
      this.renderer.setAttribute(cv, 'tabindex', '0');
    });
  }

  private focusListItem(itemList: NodeList | undefined): void {
    const item = this.getSelectedItem(itemList);

    if (item) {
      item.focus();
    }
  }

  private getSelectedItem(itemList: NodeList | undefined): HTMLElement | undefined {
    let item: HTMLElement | undefined;

    if (itemList) {
      // @ts-ignore
      item = itemList.item(this.focusedItemIndex);
    }

    return item;
  }
}
