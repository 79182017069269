import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { isRtl } from '@backbase/ui-ang/util';

export type FormatFn = (date: Date) => string;
// @TODO: should be extended with week, year, etc. once we get requirement for it
export type PeriodSize = 'month';
export interface Period {
  start: Date;
  end: Date;
}
export interface PeriodFormatters {
  title?: FormatFn;
  start?: FormatFn;
  end?: FormatFn;
}

interface CombinedFormatters {
  title: FormatFn;
  start: FormatFn;
  end: FormatFn;
}

/**
 * @name PeriodSelectorComponent
 *
 * @description
 * Component that selects a period.
 */
@Component({
  selector: 'bb-period-selector-ui',
  templateUrl: './period-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PeriodSelectorComponent implements OnInit {
  private combinedFormatters: CombinedFormatters = {
    title: (date) => this.datePipe.transform(date, 'MMMM') || '',
    start: (date) => this.datePipe.transform(date, 'mediumDate') || '',
    end: (date) => this.datePipe.transform(date, 'mediumDate') || '',
  };

  /**
   * Start date of the period.
   */
  @Input() periodStart?: Date;
  /**
   * End date of the period.
   */
  @Input() periodEnd?: Date;
  /**
   * Custom date period formatter. Accepts formatters in the form of:
   *
   * ```typescript
   *  {
   *    title?: (date: Date) => string;
   *    start?: (date: Date) => string;
   *    end?: (date: Date) => string;
   *  }
   *```
   *
   */
  @Input()
  set periodFormatters(formatters: PeriodFormatters) {
    this.combinedFormatters = Object.assign(this.combinedFormatters, formatters);
  }
  /**
   * Period step. Defaults to 'month'.
   */
  @Input() period: PeriodSize = 'month';

  /**
   * Emits an event when the period is changed.
   */
  @Output() periodChange: EventEmitter<Period> = new EventEmitter<Period>();

  chevronPrev = isRtl() ? 'chevron-right' : 'chevron-left';
  chevronNext = isRtl() ? 'chevron-left' : 'chevron-right';

  constructor(private readonly datePipe: DatePipe) {}

  formattedTitle: string | undefined;
  formattedStart: string | undefined;
  formattedEnd: string | undefined;

  get isLastPeriod(): boolean {
    if (!this.periodStart) {
      return true;
    }

    const now = new Date();

    return now.getFullYear() === this.periodStart.getFullYear() && now.getMonth() === this.periodStart.getMonth();
  }

  get periodLeft() {
    return isRtl() ? `\u200f${this.formattedEnd}` : this.formattedStart;
  }

  get periodRight() {
    return isRtl() ? `\u200f${this.formattedStart}` : this.formattedEnd;
  }

  ngOnInit() {
    if (typeof this.periodStart === 'undefined' || typeof this.periodEnd === 'undefined') {
      throw new Error('`periodStart`, and `periodEnd` inputs are required in `bb-period-selector-ui` component');
    }

    this.setFormattedValues();
  }

  private setFormattedValues() {
    this.formattedTitle = this.combinedFormatters.title(this.periodStart as Date);
    this.formattedStart = this.combinedFormatters.start(this.periodStart as Date);
    this.formattedEnd = this.combinedFormatters.end(this.periodEnd as Date);
  }

  changePeriod(next: boolean) {
    if (!this.periodStart || (next && this.isLastPeriod)) {
      return;
    }

    this.periodStart.setMonth(this.periodStart.getMonth() + (next ? 1 : -1));
    this.periodEnd = this.isLastPeriod
      ? new Date(this.periodStart.getFullYear(), this.periodStart.getMonth(), new Date().getDate(), 23, 59, 59, 999)
      : new Date(this.periodStart.getFullYear(), this.periodStart.getMonth() + 1, 0, 23, 59, 59, 999);

    this.setFormattedValues();
    this.periodChange.emit({
      start: this.periodStart,
      end: this.periodEnd,
    });
  }
}
