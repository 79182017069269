import { Component } from '@angular/core';
// Please replace relative path imports e.g. '../*' with '@backbase/ui-ang'.
import { PeriodFormatters } from '@backbase/ui-ang/period-selector';

const dateTimeToDate = (isoDateTime: string) =>
  isoDateTime.replace(/T.+/, '');

@Component({
  selector: 'bb-period-selector-example-ui',
  templateUrl: './period-selector.example.component.html',
  standalone: false,
})
export class PeriodSelectorExampleComponent {
  startDate = new Date('2018-08-01');
  endDate = new Date('2018-08-31');

  periodFormatters: PeriodFormatters = {
    start: (date: Date) => dateTimeToDate(date.toISOString()),
    end: (date: Date) => `End: ${dateTimeToDate(date.toISOString())}`,
  };
}
