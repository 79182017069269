import { Component } from '@angular/core';

@Component({
  selector: 'bb-pagination-example-ui',
  templateUrl: './pagination.example.component.html',
  standalone: false,
})
export class PaginationExampleComponent {
  page = 0;
  totalItems = 100;

  onPageChange(page: number) {
    this.page = page;
  }
}
