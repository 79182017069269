import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'bb-rich-text-editor-page-ui',
    templateUrl: './bb-rich-text-editor-page-ui.component.html',
    styles: [],
    standalone: false,
})
export class BbRichTextEditorPageUiComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
