import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { DomAttributesService } from '@backbase/ui-ang/services';

export const BB_MULTI_SELECT_CHANGE_DETECTION_REF_TOKEN: InjectionToken<ChangeDetectorRef> = new InjectionToken(
  'BB_MULTI_SELECT_SINGLE_SELECT',
);

/**
 * @name MultiSelectSingleSelectOption
 *
 * @description
 * Component that represents a single option that is part of the multi select component.
 */
@Component({
  selector: 'bb-dropdown-multi-select-option-ui',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '',
  standalone: false,
})
export class DropdownMultiSelectOptionComponent implements OnChanges {
  private _id: string;

  /**
   * The id for the input. Defaults to unique string.
   */
  @Input()
  set id(value: string) {
    this._id = value;
  }

  get id(): string {
    return this._id;
  }

  /**
   * The value of the native option element
   */
  @Input() value = '';
  /**
   * The label of the option item
   */
  @Input() label = '';

  @Input() state: boolean | undefined = false;
  constructor(
    @Inject(BB_MULTI_SELECT_CHANGE_DETECTION_REF_TOKEN) private readonly dropdownCDRef: ChangeDetectorRef,
    private readonly domAttributesService: DomAttributesService,
  ) {
    this._id = this.domAttributesService.generateId();
  }

  // NOTES: trigger of detectChanges is needed
  // because DropdownSingleSelectComponent has changeDetection
  // strategy onPush, hence it reacts only to changes
  // of its own @Inputs, but not @Inputs() of options component
  ngOnChanges({ value = {} as SimpleChange, label = {} as SimpleChange }: SimpleChanges) {
    const { firstChange: firstChangeValue } = value;
    const { firstChange: firstChangeLabel } = label;

    if (firstChangeLabel && firstChangeValue) {
      return;
    }

    this.dropdownCDRef.detectChanges();
  }
}
