import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';
import { PlainObject } from '@backbase/ui-ang/control-error-handler';
import { CustomErrorShowcaseComponent } from './custom-error/custom-error-showcase.component';

@Component({
  selector: 'bb-control-error-handler-ui-showcase',
  templateUrl: './control-error-handler-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class ControlErrorHandlerShowcaseComponent implements OnInit {
  customError = CustomErrorShowcaseComponent;
  objectDropdownOptions = [{ title: 'Option One' }, { title: 'Option Two' }, { title: 'Option Three' }];
  public currencies: string[] = ['USD', 'EUR', 'BHD', 'OMR', 'MGA'];

  form: UntypedFormGroup | undefined;
  errorLabels1: PlainObject<string> = {
    required: () => 'Input is required',
  };

  errorLabels2: PlainObject<string> = {
    required: () => 'This textarea is required',
  };

  errorLabels3: PlainObject<string> = {
    required: () => 'The amount is required field',
  };

  constructor(private readonly fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.form = this.fb.group({
      inputField: [null, [Validators.required]],
      inputField2: [null, [Validators.required, Validators.minLength(3)]],
      textareaField: [null, Validators.minLength(8)],
      checkboxField: [null, Validators.requiredTrue],
      switchField: [null, Validators.requiredTrue],
      currencyField: [null, Validators.required],
      dropdownField: [null, Validators.required],
      amountField: [null, Validators.required],
    });
  }

  onSubmit() {}
}
