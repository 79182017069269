import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'bb-showcase-design',
  templateUrl: './showcase-design.component.html',
  standalone: false,
})
export class ShowcaseDesignComponent {
  path$: Observable<string | undefined> = this.route.paramMap.pipe(
    map((params: ParamMap) => {
      const componentId = params.get('id') || '';

      return `assets/docs/html/design-guidelines-${componentId}-page.component.html`;
    }),
  );
  constructor(private route: ActivatedRoute) {}
}
