import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-badge-ui-showcase',
  templateUrl: './badge-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class BadgeShowcaseComponent {
  public badgeShowCaseSetup = [
    {
      type: 'warning',
      text: {
        single: 'A',
        double: 'AA',
      },
      customParentBoxWidth: '80px',
    },
    {
      type: 'danger',
      text: {
        single: 'A',
        double: 'AA',
      },
      customParentBoxWidth: '80px',
    },
    {
      type: 'info',
      text: {
        single: 'A',
        double: 'AA',
      },
      customParentBoxWidth: '60px',
    },
    {
      type: 'success',
      text: {
        single: 'A',
        double: 'AA',
      },
      customParentBoxWidth: '80px',
    },
    {
      type: 'secondary',
      text: {
        single: 'A',
        double: 'AA',
      },
      customParentBoxWidth: '80px',
    },
  ];
}
