import { Component, Input, TemplateRef } from '@angular/core';

/**
 * @name InputRadioComponent
 *
 * ### Accessibility
 * Current component provide option to pass needed accessibility
 * attributes. You need to take care of properties that are required in your case :
 *  - role
 *  - aria-activedescendant
 *  - aria-describedby
 *  - aria-expanded
 *  - aria-invalid
 *  - aria-label
 *  - aria-labelledby
 *  - aria-owns
 *
 * @description
 * Component that represents a single radio that is part of the radio group component.
 */
@Component({
  selector: 'bb-input-radio-ui',
  template: '',
  standalone: false,
})
export class InputRadioComponent {
  /**
   * A unique identifier for the radio element.
   */
  @Input() id: string | undefined;
  /**
   * Custom label template.
   */
  @Input() labelTemplate: TemplateRef<any> | undefined;
  /**
   * Label template context.
   */
  @Input() labelTemplateContext: any | undefined;

  /**
   * The value of the native radio element.
   */
  @Input() value: string | undefined;
  /**
   * The label of the radio item
   */
  @Input() label: string | undefined;
  /**
   * Accessible label when control does not need to render label tag.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-label') ariaLabel: string | undefined;
  /**
   * The 'aria-labelledby' attribute for the radio button
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-labelledby') ariaLabelledby: string | undefined;
  /**
   * The 'aria-describedby' attribute for the radio button
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') ariaDescribedby: string | undefined;

  @Input() role: string | undefined;

  /**
   * The ID of the currently active descendant element when the radio button is part of a group.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-activedescendant') ariaActivedescendant: string | undefined;

  /**
   * Whether the radio button is expanded (true) or collapsed (false).
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-expanded') ariaExpanded: string | undefined;

  /**
   * Whether the radio button is invalid (true) or valid (false).
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-invalid') ariaInvalid: string | undefined;

  /**
   * The IDs of the elements that are controlled by the radio button.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-owns') ariaOwns: string | undefined;

  /**
   * Whether the radio button is disabled (true) or enabled (false).
   */
  @Input() disabled = false;
}
