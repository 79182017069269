import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-rich-text-editor-ui-showcase',
  templateUrl: './rich-text-editor-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class RichTextEditorShowcaseComponent {
  vForm: UntypedFormGroup | undefined;
  constructor(private readonly formBuilder: UntypedFormBuilder) {
    this.vForm = this.formBuilder.group({
      modelMessage: '',
      modelMessage2: '',
    });
  }
}
