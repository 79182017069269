import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'bb-date-pipe-page-ui',
    templateUrl: './bb-date-page-ui.component.html',
    styles: [],
    standalone: false,
})
export class DatePageUiComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
