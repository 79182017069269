import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'bb-heading-container',
  templateUrl: './heading-container.component.html',
  standalone: false,
})
export class HeadingContainerComponent implements OnInit {
  childRoute = '';
  breadcrumbs = '';
  constructor(private router: Router) {}

  private getBreadCrumbsAndChildRoute() {
    const exclude = ['web', 'design', 'ios', 'android'];
    const url = this.router.url;
    const fragment = this.router.parseUrl(url).fragment || '';
    const breadcrumbs = url
      .replace(`#${fragment}`, '')
      .split('/')
      .filter((segment) => !exclude.includes(segment));

    this.childRoute = breadcrumbs.pop() || '';
    this.breadcrumbs = breadcrumbs.pop() || '';
  }

  ngOnInit() {
    this.getBreadCrumbsAndChildRoute();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url !== '/') {
        this.getBreadCrumbsAndChildRoute();
      }
    });
  }

  get heading() {
    return this.childRoute.replace('api', 'API');
  }
}
