import { Component } from '@angular/core';
import { BackbaseDocs, isRtl } from '@backbase/ui-ang/util';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'bb-fieldset-ui-showcase',
  templateUrl: './fieldset-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class FieldsetShowcaseComponent {
  public group: UntypedFormGroup;
  public currencies: string[] = ['USD', 'EUR'];

  constructor() {
    this.group = new UntypedFormGroup({
      password: new UntypedFormControl(''),
      withDecimalControl: new UntypedFormControl({
        amount: undefined,
        currency: 'USD',
      }),
    });
  }

  get placeholderLeftText() {
    return isRtl() ? 'Right' : 'Left';
  }

  get placeholderRightText() {
    return isRtl() ? 'Left' : 'Right';
  }
}
