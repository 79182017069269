import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-product-item-savings-account-ui-showcase',
  templateUrl: './product-item-savings-account-showcase.component.html',
  standalone: false,
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class ProductItemSavingsAccountShowcaseComponent {}
