import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  Renderer2,
  OnDestroy,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, distinctUntilChanged, debounceTime, map, takeUntil } from 'rxjs/operators';
import { InputBaseComponent } from '@backbase/ui-ang/base-classes';

export interface SelectListItemBase {
  name: string;
}

@Component({
  selector: 'bb-select-list-ui',
  templateUrl: './select-list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectListComponent),
    },
  ],
  standalone: false,
})
// TODO: set a generic that extends to SelectListItemBase once Ivy check types step supports correctly types
export class SelectListComponent<T extends { name: string }>
  extends InputBaseComponent
  implements OnChanges, AfterViewInit, OnDestroy
{
  override value: InputBaseComponent['value'] = null;

  @ViewChild('select') ngSelect?: NgSelectComponent;

  /**
   * Flag to determine if the data in the list is loading
   */
  @Input() loading = false;

  /**
   * Placeholder text to display in the input
   * */
  @Input() placeholder = '';

  /**
   * Minimum number of characters required before searching for items
   * */
  @Input() minTermLength = 2;

  /**
   * Maximum number of selected items, 'none' means unlimited
   */
  @Input() maxSelectedItems = 'none';

  /**
   * Flag to determine if the list allows multiple selection.
   * */
  @Input() multiple = false;

  /**
   * A list of items to select from based on the input text.
   * */
  @Input() items: T[] = [];

  /**
   * Flag to determine if typeahead should be used to filter the items list.
   * If enabled the items list can not be updated asynchonously as the filtering
   * and the underlying data will get out of sync.
   */
  @Input() filterEnabled = true;

  @Input()
  set isOpen(value) {
    this._isOpen$.next(value);
  }

  get isOpen() {
    return this._isOpen$.getValue();
  }

  private readonly _isOpen$ = new BehaviorSubject<boolean | undefined>(undefined);

  /**
   * Output to capture value change event.
   */
  @Output() valueChange = this._valueChange;

  /**
   * Emitter when search is performed. Outputs search term
   */
  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Emitter when clear is performed.
   */
  @Output() clear = new EventEmitter<void>();

  /**
   * Delay in ms between autocomplete updates.
   * */
  private readonly DEBOUNCE_TIME_MS = 500;
  /**
   * Observable of the typeahead input
   */
  readonly typeaheadInput$ = new Subject<string>();
  /**
   * Observable of the filtered items
   */
  readonly filteredItems$: Observable<T[]> = this.typeaheadInput$.pipe(
    filter((term) => term !== null && term.length >= this.minTermLength),
    distinctUntilChanged(),
    debounceTime(this.DEBOUNCE_TIME_MS),
    map((term) => this.items.filter(({ name }: any) => name.toLowerCase().includes(term.toLowerCase()))),
  );

  private readonly _destroy$ = new Subject<void>();

  protected dropdownPosition: 'bottom' | 'top' | 'auto' = 'auto';

  writeValue(inputValue: Object | string | null): void {
    if (inputValue !== null) {
      super.writeValue(inputValue);
    } else {
      this.value = inputValue;
      this.cd.markForCheck();
    }
  }

  onSearch(
    event:
      | string
      | {
          term: string;
          items: any[];
        },
  ) {
    const searchTerm = typeof event === 'string' ? event : event.term;

    if (searchTerm.length >= this.minTermLength) {
      this.search.emit(searchTerm);
    }
    if (searchTerm?.length === 0 && this.ngSelect?.isOpen === true) {
      this.ngSelect.isOpen = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.ngSelect?.isOpen === false && this.ngSelect?.searchTerm) {
      this.ngSelect.isOpen = true;
    }
    const disableChanges = changes.disable;

    if (disableChanges) {
      this.updateNgSelectComboboxAttrs({ 'aria-disabled': disableChanges.currentValue });
    }
  }

  constructor(
    cd: ChangeDetectorRef,
    private readonly renderer: Renderer2,
  ) {
    super(cd);
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngAfterViewInit() {
    this._isOpen$.pipe(takeUntil(this._destroy$)).subscribe((isOpen) => {
      if (isOpen) {
        this.moveAriaAttributes();
        this.dropdownPosition = 'bottom';
      }

      if (isOpen === undefined) {
        this.addAriaExpanded();
      }
    });
    this.removeAriaPlaceholder();
  }

  /**
   * @deprecated Deprecated in ui-ang@12. To be marked as protected in ui-ang@14. No replacements.
   */
  onOpen() {
    // HACK: ng-select added a duplicate role=listbox as of v11 in https://github.com/ng-select/ng-select/pull/2199
    // We move role=list and aria-label on ng-dropdown-panel to the child div

    // Wait for dropdown from ng-select to show
    setTimeout(() => {
      this.moveAriaAttributes();
    });
  }

  // Append aria attributes to combobox (container of ng-select input) for a11y
  private updateNgSelectComboboxAttrs(changedAttrs: Record<string, any>) {
    if (!this.ngSelect) {
      return;
    }

    const combobox = this.ngSelect.element.querySelector('[role="combobox"]');

    Object.entries(changedAttrs).forEach(([attr, val]) => {
      if (val) {
        this.renderer.setAttribute(combobox, attr, val);
      } else {
        this.renderer.removeAttribute(combobox, attr);
      }
    });
  }

  private moveAriaAttributes() {
    const dropdownPanelElement = this.ngSelect?.element.querySelector('ng-dropdown-panel');
    const listboxElement = dropdownPanelElement?.querySelector('[role="listbox"]');

    if (!dropdownPanelElement || !listboxElement) {
      return;
    }

    this.renderer.setAttribute(listboxElement, 'aria-label', dropdownPanelElement.getAttribute('aria-label') ?? '');
    this.renderer.removeAttribute(dropdownPanelElement, 'role');
    this.renderer.removeAttribute(dropdownPanelElement, 'aria-label');
  }

  private addAriaExpanded() {
    const comboboxElement = this.ngSelect?.element?.querySelector('[role="combobox"]');

    if (!comboboxElement || comboboxElement.hasAttribute('aria-expanded')) {
      return;
    }

    this.renderer.setAttribute(comboboxElement, 'aria-expanded', 'false');
  }

  private removeAriaPlaceholder() {
    if (!this.ngSelect) {
      return;
    }

    const inputContainers = Array.from(this.ngSelect.element.getElementsByClassName('ng-input'));
    for (const inputContainer of inputContainers) {
      const inputElements = Array.from(inputContainer.getElementsByTagName('input'));
      for (const inputElement of inputElements) {
        inputElement.removeAttribute('aria-placeholder');
      }
    }
  }
}
